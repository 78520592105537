import { ModalInput } from './modal-input.model';

export class ModalContent {

  constructor(public body: string,
              public title?: string,
              public button1title?: string,
              public button2title?: string,
              public illustrationPath?: string,
              public input?: ModalInput,
              public displayNumber?: Number) {
  }


}
