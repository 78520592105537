import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurationService } from '../../shared/config/configuration.service';
import { TranslationService } from '../../shared/translation/translation.service';
import { EmailValidator } from '../../shared/app-forms/validators/email.validator';
import { AppStorageService } from '../../shared/storage/common/app-storage.service';
import { CookiesService } from '../../shared/cookies/cookies.service';
import { RequestAccessService } from '../../shared/authentication/sign-up.service';
import { RequestParameter } from '../../shared/common/request-parameter.model';
import { TranslationStorageService } from '../../shared/storage/translation/translation-storage.service';
import { NavigationDirectionService } from '../../shared/router-transitions/navigation-direction.service';
import { environment } from 'environments/environment';
import { GTM_EVENTS } from '../../shared/gtm/gtm-events.const';

@Component({
  selector: 'app-sign-up-prospect',
  templateUrl: './sign-up-prospect.component.html',
  styleUrls: ['./sign-up-prospect.component.scss']
})
export class SignUpProspectComponent implements OnInit {

  public form: FormGroup;
  public availableLanguages = [];
  public submitted = false;
  public isLoading: boolean;
  public grades: any[];
  public domainId: string;
  public readonly GTM_EVENTS = GTM_EVENTS;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private navigationDirection: NavigationDirectionService,
              private configurationService: ConfigurationService,
              private appStorageService: AppStorageService,
              private cookiesService: CookiesService,
              private translationService: TranslationService,
              private requestAccessService: RequestAccessService,
              private translationStorage: TranslationStorageService) {
  }

  ngOnInit() {
    this.domainId = (this.route.snapshot.params as any).id;
    this.prepareGrades();
    this.prepareLanguages();
    this.buildForm();

  }

  buildForm() {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, EmailValidator.simple]],
      grade: ['', Validators.required],
      legal: ['', Validators.required],
      recaptcha: ['', Validators.required]
    });
  }

  prepareLanguages() {
    this.configurationService.getAvailableLanguages()
      .subscribe(
        languages => {
          this.availableLanguages = languages;
        },
        error => {
          console.error('An error has occured: ', error);
        }
      );
  }


  prepareGrades() {
    this.grades = [];
    this.requestAccessService.getGrades(this.domainId)
      .subscribe(
        grades => {
          grades.forEach((grade) => {
            this.grades.push({id: grade, label: grade});
          });
        },
        error => {
          console.error('An error has occured: ', error);
        }
      );
  }

  changeLanguage(language: string) {
    this.translationService.language = language;
  }

  languageFlagName(codeLanguage) {
    const shortenedCodeLanguage = codeLanguage.substr(3, 2);
    return shortenedCodeLanguage === 'en' ? 'gb' : shortenedCodeLanguage;
  }

  linkToTermesOfUse() {
    return `${environment.publicDocumentsBaseUrl}/${this.appStorageService.countrySelected}/disclaimer-${this.translationService.language}.pdf`;
  }

  linkToCookiesPolicy() {
    return this.cookiesService.getCookiesPolicyDocumentUrl(this.translationService.language);
  }

  updateGradeValue(grade) {
    this.form.get('grade').setValue(grade.id);
  }

  onSubmit() {
    if (!this.form.get('legal').value) {
      this.form.controls['legal'].setValue(null);
    }
    if (this.form.valid) {
      this.isLoading = true;
      const params: RequestParameter[] = [];
      const selectedCountry = this.appStorageService.countrySelected;
      params.push(new RequestParameter('domainId', this.domainId));
      params.push(new RequestParameter('name', this.form.get('name').value));
      params.push(new RequestParameter('email', this.form.get('email').value));
      params.push(new RequestParameter('grade', this.form.get('grade').value));
      params.push(new RequestParameter('culture', this.translationStorage.lang.toString()));
      params.push(new RequestParameter('countryCode', selectedCountry));
      params.push(new RequestParameter('recaptcha', this.form.get('recaptcha').value));
      this.requestAccessService.prospectSignUp(params)
        .then((feedback: any) => {
          if (feedback) {
            this.router.navigate(['/start/sign-up-error']);
          } else {
            this.isLoading = false;
            this.navigationDirection.direction = 'forward';
            this.router.navigate(['/start/sign-up-confirmation']);
          }
        })
        .catch(e => {
          this.router.navigate(['/start/sign-up-error']);
        });
    }
  }
}
