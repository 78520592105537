import { Component, ElementRef, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { PopinService } from '../../shared/ui/popin.service';
import { HelpService } from '../shared/help.service';
import { MaintenanceInfo } from '../shared/help-maintenance-info.model';
import { MaxMileage, HelpConst } from '../shared/help.const';
import { DriverService } from '../../driver/shared/driver.service';
import { DateValidator } from '../../shared/app-forms/validators/date.validator';
import { UserProfile } from 'app/driver/shared/models/user-profile.model';
import { UserRole } from 'app/driver/shared/models/user-role.model';
import { UserRoles } from 'app/shared/user/userRoles.const';
import { SendMaintenanceRequest } from '../shared/models/maintenance-request.model';
import * as moment from 'moment';

@Component({
  selector: 'app-help-maintenance-request',
  templateUrl: './help-maintenance-request.component.html',
  styleUrls: ['./help-maintenance-request.component.scss']
})
export class HelpMaintenanceRequestComponent implements OnInit, AfterViewInit, OnDestroy {
  private isTimeSelected: boolean;
  private newUserProfile: UserProfile;
  private returnUrl: string = null;
  private lastMileage: number;

  public showToast = false;
  public submitStatus = 'initial';
  public error = null;
  public form: FormGroup;
  public maintenanceInformations: MaintenanceInfo;
  public toastMessageText: string;
  public indexServiceKm: number;
  public selectedMaintenanceType: any = null;
  public isScheduledMaintenance = false;
  public maintenanceTypeTitle: string;
  public serviceKmErrorMessage: string;


  constructor(
    private router: Router,
    private popinService: PopinService,
    private helpService: HelpService,
    private formBuilder: FormBuilder,
    private element: ElementRef,
    private driverService: DriverService,
    private elementRef: ElementRef) {
  }

  ngAfterViewInit() {
    const elements = this.elementRef.nativeElement.querySelectorAll('.string-input');
    for (let element = 0; element < elements.length; element++) {
      elements[element].classList.add('has-value');
    }
  }

  ngOnInit() {
    this.maintenanceInformations = {} as MaintenanceInfo;

    const serviceParameters = this.helpService.getMaintenanceRequestParameters();
    if (serviceParameters) {
      this.selectedMaintenanceType = serviceParameters.maintenanceType;
      this.returnUrl = serviceParameters.fromUrl;
    }

    this.isScheduledMaintenance = this.selectedMaintenanceType && (parseInt(this.selectedMaintenanceType.id, 0) === HelpConst.maintenanceType.ScheduledMaintenance);
    this.maintenanceTypeTitle = this.isScheduledMaintenance ? 'help-maintenance_request-scheduled_maintenance' : 'help-maintenance_request-unscheduled_maintenance';
    this.indexServiceKm = -1;


    this.driverService.newDriverStream
      .subscribe((newDriver: UserProfile) => {
        this.newUserProfile = newDriver;

        const driverContract = this.driverService.getNewUserRole(newDriver).contracts[0];
        if (driverContract != null && driverContract.vehicle != null) {
          this.lastMileage = driverContract.mileage.currentMileage;
        }

        this.generateFormGroup();
      });

    this.popinService.opened(true);
  }

  public onSubmit() {
    this.submitStatus = 'pending';
    this.error = null;

    if (this.form.valid) {
      this.submitStatus = 'pending';

      const newUserRole = this.driverService.getNewUserRole(this.newUserProfile)

      let preferredDate = this.form.get('date').value;
      let preferredTime = this.form.get('preferredTime').value;

      if (typeof (preferredDate) !== 'string') {
        preferredDate = moment(preferredDate).format('YYYY-MM-DD');
      }
      if (typeof (preferredTime) !== 'string') {
        preferredTime = moment(preferredTime).format('HH:mm');
      }
      const dateTime = moment(`${preferredDate} ${preferredTime}`).parseZone().toJSON();

      const maintenanceRequestParams: SendMaintenanceRequest = {
        email: this.newUserProfile.email,
        name: this.newUserProfile.name,
        customerId: newUserRole.company.reference,
        carPlateNumber: newUserRole.contracts.map(contract => contract.vehicle.plate),
        mobilePhone: this.form.get('mobile').value,
        contractNumber: newUserRole.contracts[0].reference,
        companyName: newUserRole.company.name,
        carMake: newUserRole.contracts[0].vehicle.brand,
        carModel: newUserRole.contracts[0].vehicle.model,
        lastRecordedMileage: newUserRole.contracts[0].vehicle.mileage.lastMileage,
        isScheduledMaintenance: this.isScheduledMaintenance,
        maintenanceDateTime: dateTime,
        serviceToBeBooked: this.form.get('serviceKm').value,
        reasonForMaintenance: this.form.get('reason').value,
        culture: '',
      }

      this.helpService.sendMaintenanceRequest(maintenanceRequestParams).subscribe(() => {
        this.error = null;
        this.submitStatus = 'initial';
        this.toastMessageText = 'help-maintenance_request-success';
        this.showToast = true;
        this.indexServiceKm = -1;
      },
        error => {
          if (error.status && error.status.toString().substring(0, 1) === '4') {
            this.error = 400;
          } else {
            this.error = true;
          }
          this.backToTop();
          this.submitStatus = 'initial';
          this.showToast = true;
          this.toastMessageText = 'global-generic_terms-generic_server_error';
        });
    } else {
      const serviceKmControl = this.form.get('serviceKm');
      if (serviceKmControl.invalid) {
        this.error = 600;
        if (serviceKmControl.errors.min) {
          this.serviceKmErrorMessage = 'help-maintenance_request-service_km_min_error';
        } else if (serviceKmControl.errors.max) {
          this.serviceKmErrorMessage = 'help-maintenance_request-service_km_max_error';
        }
      }
      this.submitStatus = 'initial';
      this.backToTop();
    }
  }

  public chooseMaintenanceType(event) {
    this.isScheduledMaintenance = (event === 'scheduled');
    this.maintenanceTypeTitle = this.isScheduledMaintenance ? 'help-maintenance_request-scheduled_maintenance' : 'help-maintenance_request-unscheduled_maintenance';

    this.form.get('reason').setValue('');

    if (this.isScheduledMaintenance) {
      this.form.get('reason').clearValidators();
      this.form.get('reason').updateValueAndValidity();
    } else {
      this.form.get('reason').setValidators([Validators.required, Validators.minLength(3)]);
      this.form.get('reason').updateValueAndValidity();
    }
  }

  public onflatpickrOpen(event: any) {
    event.instance._input.parentElement.parentElement.classList.add('has-value');
  }

  public onflatpickrClose(event: any) {
    if (!this.isTimeSelected) {
      event.instance._input.parentElement.parentElement.classList.remove('has-value');
      event.instance._input.value = '';
    }
  }

  public onflatpickrChange(event: any) {
    this.isTimeSelected = true;
  }

  public generateFormGroup() {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);

    this.form = this.formBuilder.group({
      mobile: [this.newUserProfile.phone, Validators.required],
      city: [this.newUserProfile.city, Validators.required],
      date: [currentDate, DateValidator.isFuture],
      preferredTime: [currentDate.getTime(), Validators.required],
      serviceKm: ['', Validators.compose([Validators.required, Validators.min(this.lastMileage), Validators.max(MaxMileage)])],
      reason: this.isScheduledMaintenance ? [''] : ['', Validators.required]
    });

    this.form.addControl('maintenanceType', this.formBuilder.control('unscheduled'));
  }

  public onBackClick() {
    this.popinService.opened(false);
    this.router.navigate([this.returnUrl]);
  }

  ngOnDestroy() {
    this.popinService.opened(false);
  }

  public backToTop() {
    const el = this.element.nativeElement.querySelector('.sticky-content');
    const scrollInterval = setInterval(function () {
      el.scrollTop = el.scrollTop - 15;
      if (el.scrollTop <= 0) {
        clearInterval(scrollInterval);
      }
    }, 1);
  }
}
