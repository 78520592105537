import { Component, HostListener, OnInit } from '@angular/core';
import { AppConfigService } from '../../config/app-config.service';
import { GtmService } from '../../gtm/gtm.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  public isDesktop = false;
  public isLoadedInsideIFrame: boolean = window.self !== window.top;

  constructor(
    private appConfig: AppConfigService,
    private router: Router,
    private gtmService: GtmService,
    ) {
  }

  @HostListener('window:resize', ['$event'])
  onResize($event = null) {
    this.isDesktop = (window.innerWidth > this.appConfig.config.breakpoints.m);
  }

  ngOnInit() {
    this.gtmService.sendPageView(this.router.url);
    this.onResize();
  }


}
