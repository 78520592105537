import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';

@Injectable()
export abstract class BaseService {
  constructor(private loggerService: LoggerService) {
  }

  throwError() {
    console.log('CustomErrorHandler should be trigger');

    throw new Error('THROW AN ERROR FROM ' + this.constructor.name);
  }

  addMessage(_message) {
    this.loggerService.error(_message);
  }

  handleError(error: any): Promise<any> {
    this.loggerService.error('An error occurred ' + error.message);

    return Promise.reject(error.message || error);
  }
}
