import { Directive, HostListener, Input } from '@angular/core';
import { GtmService } from './gtm.service';
import { GtmEventProperties } from './gtm-properties.model';

@Directive({
  selector: '[gtmClickEvent]',
})
export class GtmClickEventDirective {

  @Input() gtmClickEvent: GtmEventProperties;
  @Input() gtmDetail?: string;
  @Input() gtmLabel?: string;

  constructor(
    public gtmService: GtmService,
  ) {
  }

  @HostListener('click') onMouseEnter() {
    this.gtmService.sendEvent(this.gtmClickEvent, this.gtmLabel, this.gtmDetail);
  }
}
