import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'caractersLimit'
})
export class CaractersLimitPipe implements PipeTransform {

  transform(text1: string, maxlength = 200, limit = '.', text2?: string, particularity?: string): string {
    let content = text1;
    const GLUE = ' ';
    if (text2) {
      content += GLUE + text2;
    }

    if (particularity === 'names') {
      if (text1.length > (maxlength / 2)) {
        text1 = text1.substr(0, 1) + limit;
      }
      if (text2.length > (maxlength / 2)) {
        text2 = text2.substr(0, 1) + limit;
      }
      content = text1 + GLUE + text2;
    }

    if (content.length > maxlength) {
      if (particularity === 'names') {
        if (content.length > maxlength) {
          text2 = text2.substr(0, 1) + limit;
          content = text1 + GLUE + text2;
        }
        if (content.length > maxlength) {
          text1 = text1.substr(0, 1) + limit;
          content = text1 + GLUE + text2;
        }
      } else {
        content = content.substr(0, maxlength) + limit;
      }
    } else {
      return content;
    }
    return content;
  }

}
