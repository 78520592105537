import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, takeWhile, tap } from 'rxjs/operators';

@Component({
  selector: 'app-documents-search',
  templateUrl: './documents-search.component.html',
  styleUrls: ['./documents-search.component.scss']
})
export class DocumentsSearchComponent implements OnInit {
  @Output() search: EventEmitter<string> = new EventEmitter();

  private isComponentActive = true;
  public searchQuery: FormControl;

  constructor() { }

  ngOnInit() {
    this.searchQuery = new FormControl('');
    this.searchQuery.valueChanges.pipe(
      debounceTime(300),
      takeWhile(() => this.isComponentActive),
      tap((pattern) => {
        this.search.emit(pattern);
      }),
    ).subscribe();
  }

  public clearSearch(): void {
    this.searchQuery.setValue('');
  }

}
