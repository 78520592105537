import { Injectable } from '@angular/core';
import { AppStorageService } from '../storage/common/app-storage.service';
import { environment } from 'environments/environment';

@Injectable()
export class CookiesService {

  constructor(private appStorageService: AppStorageService) { }

  public getCookiesPolicyDocumentUrl(languageCode): string {
    return `${environment.publicDocumentsBaseUrl}/${this.appStorageService.countrySelected}/cookies-policy-${ languageCode }.pdf`;
  }
}
