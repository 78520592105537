export const GTM_EVENTS = {
  loginSuccess: {
    event: 'connection-loginSucceed',
    eventCategory: 'Connection',
    eventAction: 'Login succeed',
    eventLabel: '<user-role>',
    eventDetails: '<country>',
    plateforme: 'driver',
  },
  logout: {
    event: 'connection-logout',
    eventCategory: 'Connection',
    eventAction: 'Logout',
    eventLabel: '<user-role>',
    eventDetails: '<country>',
    plateforme: 'driver',
  },
  changePassword : {
    event: 'userAccountManagement-passwordChange',
    eventCategory: 'User account management',
    eventAction: 'Password change',
    plateforme: 'driver',
  },
  downloadPDF : {
    event: 'download-pdf',
    eventCategory: 'Download',
    eventAction: 'PDF',
    eventLabel: '<document-name>',
    plateforme: 'driver',
  },
  updateMileage : {
    event: 'mileage-updateMyMileage',
    eventCategory: 'Mileage',
    eventAction: 'Update my mileage',
    plateforme: 'driver',
  },
  makeAQuotationIntention : {
    event: 'quotation-makeAQuotationIntention',
    eventCategory: 'Quotation',
    eventAction: 'Make a quotation intention',
    eventLabel: '<click-url>',
    plateforme: 'driver',
  },
  uploadDocument: {
    event: 'document-uploadADriverDocument',
    eventCategory: 'Document',
    eventAction: 'Upload a driver document',
    plateforme: 'driver',
  },
  downloadDocument: {
    event: 'document-downloadADocument',
    eventCategory: 'Document',
    eventAction: 'Download a document',
    plateforme: 'driver',
  },
  assistanceCall : {
    event: 'assistance-call',
    eventCategory: 'Assistance',
    eventAction: 'Call',
    plateforme: 'driver',
  },
  assistanceCreateACase : {
    event: 'assistance-createACase',
    eventCategory: 'Assistance',
    eventAction: 'Create a case',
    plateforme: 'driver',
  }
};
