import { environment } from 'environments/environment';

export const OnRoadAssistanceConst = {
  api: {
    base: environment.apigatewayUrl + '/apigateway/Feed/api/Feed/',
    endpoints: {
      all: 'All?',
    }
  },
  others: {
    pollingInterval: 10000
  }
};

