import { OnRoadAssistanceConst } from './on-road-assistance.const';
import { FeedService } from '../feed/shared/feed.service';
import { ApiEndpoints } from '../shared/common/api.settings';
import { RequestParameter } from '../shared/common/request-parameter.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import 'rxjs/add/operator/switchMap';
import { Subscription } from 'rxjs';
import { UserService } from '../shared/user/user.service';
import * as bowser from 'bowser';
import { ApiService } from '../shared/common/api.service';
import { FeedItem } from '../feed/shared/feed-item.model';

@Injectable()
export class OnRoadAssistanceService {
  public assistanceIsRunning = false;
  constructor(private api: ApiService,
              private userService: UserService,
              private feedService: FeedService) {
  }

  /**
   * Submit user information to the server to initiate an assistance procedure.
   *
   * @param plateNumber
   * @param longitude
   * @param latitude
   * @param telephoneNumber
   * @returns {Observable<R>}
   */
  putUserInfo(plateNumber: string[], longitude: number, latitude: number, telephoneNumber: string): any {

    if (!bowser.firefox) { window.location.href = telephoneNumber; }

    const params: RequestParameter[] = [];
    params.push(new RequestParameter('longitude', longitude));
    params.push(new RequestParameter('latitude', latitude));

    for (let index = 0; index < plateNumber.length; index++) {
      params.push(new RequestParameter('plateNumber', plateNumber[index]));
    }

    this.assistanceIsRunning = true;

    const urlTail = this._getQueryStringUrl(params);

    return this.api.put(ApiEndpoints.API_ASSISTANCE_ENDPOINT_PUTUSERINFO + '?' + urlTail)
      .toPromise()
        .then(response => {

        if (bowser.firefox) { window.location.href = telephoneNumber; }
      })
      .catch(error => {
        return error; });
  }

  /**
   * Returns an observable that will emit map feed events to update the feed map
   *
   * @returns {Observable<R>}
   */
  requestRefresh(): Observable<FeedItem> {

    // Create an subject to supply FeedItems
    const notificationRefreshObservable: BehaviorSubject<FeedItem> = new BehaviorSubject(null);

    let updateRequestSubscription: Subscription;

    // Create a function that will be called to query th server on update status
    function pushNewNotificationData() {

      // cancel previous subscription
      if (updateRequestSubscription) { updateRequestSubscription.unsubscribe(); }

      // start request
      updateRequestSubscription = this.api.get()
        .map(response => {

          const notificationFeed: FeedItem = response.json()[0];
          notificationRefreshObservable.next(notificationFeed);

          // check if we need to continue polling
          if (notificationFeed.roadAssistance.stepNumber < 5) {
            this.assistanceIsRunning = true;
            setTimeout(pushNewNotificationData.bind(this), OnRoadAssistanceConst.others.pollingInterval);
          } else {
            this.assistanceIsRunning = false;
          }

        }).subscribe();

    }

    // start off polling loop
    pushNewNotificationData.bind(this)();

    // Retun consumable observable from subject
    return notificationRefreshObservable.asObservable();

  }

  private _getQueryStringUrl(params: any): string {
    return params
      .map(k => `${encodeURIComponent(k.name)}=${encodeURIComponent(k.value)}`)
      .join('&');
  }


}

