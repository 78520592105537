import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthService } from './shared/authentication/auth.service';
import { RedirectSafari } from './shared/config/redirect-safari';
import { PageNotFoundComponent } from './shared/errors/page-not-found/page-not-found.component';
import { AccessService } from './shared/authentication/access.service';
import { FeatureService } from './shared/feature/feature.service';
import { MaintenanceService } from './shared/config/maintenance.service';
import { MaintenanceComponent } from './shared/errors/maintenance/maintenance.component';
import { ContractStatusGuard } from './driver/shared/contract/guards/contract-status-guard.service';
import { QuotationComponent } from './quotation/quotation.component';
import { NoContractPageComponent } from './shared/errors/no-contract-page/no-contract-page.component';
import { UserErrorPageComponent } from './shared/errors/user-error/user-error-page.component';

/*

 Other routes declared in

 - StartModule
 - FeedModule

 */

const ROUTES = [
  {
    path: 'maintenance',
    component: MaintenanceComponent,
  },
  {
    path: '',
    canActivate: [RedirectSafari, MaintenanceService],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/feed'
      },
      {
        path: 'start',
        loadChildren: './start/start.module#StartModule',
        data: {
          userMenu: false
        }
      },
      {
        path: 'feed',
        canActivate: [AuthService, AccessService],
        loadChildren: './feed/feed.module#FeedModule',
        data: {
          userMenu: true,
          acceptProspect: true
        }
      },
      {
        path: 'cars',
        canActivate: [AuthService, AccessService],
        loadChildren: './cars/cars.module#CarsModule',
        data: {
          userMenu: true
        }
      },
      {
        path: 'quotation',
        canActivate: [AccessService],
        loadChildren: './quotation/quotation.module#QuotationModule',
        data: {
          userMenu: true,
          acceptProspect: true
        }
      },
      {
        path: 'documents',
        canActivate: [AuthService, AccessService],
        loadChildren: './documents/documents.module#DocumentsModule',
        data: {
          userMenu: true,
          acceptProspect: true
        }
      },
      {
        path: 'pos',
        canActivate: [AuthService, AccessService, FeatureService, ContractStatusGuard],
        loadChildren: './pos/pos.module#PosModule',
        data: {
          userMenu: true,
          status: 'active',
        }
      },
      {
        path: 'smart-care',
        canActivate: [AuthService, AccessService, FeatureService],
        loadChildren: './smart-care/smart-care.module#SmartCareModule',
        data: {
          userMenu: true
        }
      },
      {
        path: 'more',
        canActivate: [AuthService, AccessService],
        loadChildren: './more/more.module#MoreModule',
        data: {
          userMenu: true,
          acceptProspect: true
        }
      },
      {
        path: 'no-contract',
        component: NoContractPageComponent,
      },
      {
        path: 'not-found',
        component: PageNotFoundComponent,
      },
      {
        path: 'user-error',
        component: UserErrorPageComponent,
      },
      {
        path: '**',
        redirectTo: 'not-found',
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
