﻿import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GmapComponent } from './gmap/gmap.component';
import { PipesModule } from '../../shared/pipes/pipes.module';


@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    SvgIconModule
  ],
  declarations: [
    GmapComponent
  ],
  exports: [
    GmapComponent
  ]
})
export class GmapModule {
}
