import { Component, HostListener, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent {

  @HostListener('click', ['$event'])
  private onClick(event): void {
    event.stopPropagation();
    this.snackbarService.dismiss();
  }

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
              private snackbarService: MatSnackBar) {
  }
}
