
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Document } from './models/document.model';
import { DocumentsConst } from './documents.const';
import { HttpService } from 'app/shared/helper/http.service';
import { EditDocumentDto } from './models/edit-document-dto.models';

@Injectable()
export class DocumentsService {

  constructor(private httpService: HttpService) {}

  public getDocuments(): Observable<Document[]> {
    const documentsModule = DocumentsConst.api.modules.documents;
    const url = DocumentsConst.api.baseUrl + documentsModule.url + documentsModule.endpoints.GetDocuments;
    return this.httpService.getNoCache<Document[]>(url);
  }

  public getDocumentById(id: string): Observable<Document> {
    const documentsModule = DocumentsConst.api.modules.documents;
    const url = DocumentsConst.api.baseUrl + documentsModule.url + documentsModule.endpoints.DownloadDocuments + id;

    return this.httpService.get<Document>(url);
  }

  public uploadDocument(formData): Observable<any> {
    const documentsModule = DocumentsConst.api.modules.documents;
    const url = DocumentsConst.api.baseUrl + documentsModule.url + documentsModule.endpoints.CreateDocument;
    return this.httpService.postFile(url, formData);
  }

  public editDocument(document: EditDocumentDto): Observable<Document> {
    const documentsModule = DocumentsConst.api.modules.documents;
    const url = DocumentsConst.api.baseUrl + documentsModule.url + documentsModule.endpoints.CreateDocument;
    return this.httpService.put(url, document);
  }

  public deleteDocument(documentId: string | number): Observable<void> {
    const body = [documentId];
    const documentsModule = DocumentsConst.api.modules.documents;
    const url = DocumentsConst.api.baseUrl + documentsModule.url + documentsModule.endpoints.DeleteDocuments;
    return this.httpService.post(url, body);
  }
}
