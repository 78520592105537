import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { PopinService } from '../../ui/popin.service';
import { StickyHeaderFormService } from '../../ui/sticky-header-form.service';
import { UserManagementService } from 'app/shared/user/userManagement.service';
import { takeWhile } from 'rxjs/operators';
import { PreferencesModel } from './preferences.model';

@Component({
  selector: 'app-my-preferences',
  templateUrl: './my-preferences.component.html',
  styleUrls: ['./my-preferences.component.scss']
})
export class MyPreferencesComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public openSubmitBox: boolean;
  public submitted = false;
  public error = false;
  public nativeForm;
  public loading = true;
  private initialState: PreferencesModel;
  private componentActive = true;

  constructor(
    private formBuilder: FormBuilder,
    private popinService: PopinService,
    private userManagementService: UserManagementService,
    private stickyHeaderFormService: StickyHeaderFormService) {
  }

  ngOnInit() {
    this.userManagementService.getMyUserPreferences().pipe(takeWhile(() => this.componentActive))
      .subscribe((preferences: PreferencesModel) => {
        if (preferences) {
          this.initialState = preferences;
          this.form = this.formBuilder.group({
            notificationByEmail: new FormControl(preferences.notificationByEmail),
            notificationByPush: new FormControl(preferences.notificationByPush),
          });

          this.stickyHeaderFormService.hasCanceled.pipe(takeWhile(() => this.componentActive))
            .subscribe(status => {
              if (status) {
                this.cancelFormValuesChange();
              }
            });

          this.stickyHeaderFormService.hasSubmitted.pipe(takeWhile(() => this.componentActive))
            .subscribe(status => {
              if (status) {
                this.onSubmit();
              }
            });

          this.formValuesChangesListener();
          this.popinService.opened(true);
          this.loading = false;
        }
      });
  }

  private formValuesChangesListener() {
    this.form.valueChanges.pipe(takeWhile(() => this.componentActive))
      .subscribe(() => this.openSubmitBox = true);
  }

  public onSubmit() {
    this.userManagementService.updateMyUserPreferences(this.form.value).pipe(takeWhile(() => this.componentActive))
      .subscribe(() => {
        this.error = false;
        this.openSubmitBox = false;
        this.popinService.opened(false);
      }, () => {
        this.error = true;
      });
  }

  public cancelFormValuesChange() {
    this.form.get('notificationByEmail').setValue(this.initialState.notificationByEmail);
    this.form.get('notificationByPush').setValue(this.initialState.notificationByPush);
    this.error = false;
    this.openSubmitBox = false;
  }

  public onBackClick() {
    this.popinService.opened(false);
  }

  ngOnDestroy() {
    this.stickyHeaderFormService.hasSubmitted.next(false);
    this.stickyHeaderFormService.hasCanceled.next(false);
    this.submitted = false;
    this.popinService.opened(false);
    this.componentActive = false;
  }
}
