import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent {

  @Output()
  public complete: EventEmitter<any> = new EventEmitter();

  @Input()
  public duration = 0;
  public currentTime: number;
  @Input()
  public size = 53;
  public circleLength = this.size * Math.PI;
  public circleOffset = this.circleLength;
  private startTime: number;

  constructor() {
  }

  public render(loop = true) {

    // Calc elapsed time and absolute progress
    const elapsed = Date.now() - this.startTime;
    const progress = elapsed / this.duration;
    this.currentTime = Math.round((this.duration - elapsed) / 1000);

    // calc circle offset
    this.circleOffset = (1 - progress) * this.circleLength;

    if (elapsed > this.duration) {
      this.complete.emit('complete');
    } else if (loop) {
      requestAnimationFrame(() => this.render());
    }

  }

  public start() {
    this.startTime = Date.now();
    this.render(true);
  }

}
