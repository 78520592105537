import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { HostPageService } from './host-page.service';
import { environment } from '../../../environments/environment';

const DEFAULT_CONFIG: any = {
  theme: 'ald',
  assetsPath: environment.assetsPath,
  loginCaptcha: false,
  promptDeviceNotifications: true,
  roles: {
    driver: true,
    manager: true
  },
  breakpoints: {
    xss: 320,
    xs: 480,
    s: 768,
    m: 980
  }
};

@Injectable()
export class AppConfigService {

  public config: any = {};

  public retina: boolean = (window.devicePixelRatio > 1 || (window.matchMedia && window.matchMedia('(-webkit-min-device-pixel-ratio: 1.5),(-moz-min-device-pixel-ratio: 1.5),(min-device-pixel-ratio: 1.5)').matches));
  public isMobileTablet: boolean = !!navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
  public isMobile: boolean = !!navigator.userAgent.match(/(iPhone)|(iPod)|(android)|(webOS)/i);
  public isIos: boolean = !!navigator.userAgent.match(/(iPhone)|(iPad)|(iPod)/i);
  public isAndroid: boolean = !!navigator.userAgent.match(/(android)/i);
  public isTouch: boolean = 'createTouch'
  in
  document;

  constructor(hostPage: HostPageService) {

    /*

     Create config object by getting values from
     - the host (index.html) file
     - the DEFAULT_CONFIG object

     */
    this.config = _.defaultsDeep({}, hostPage.config, DEFAULT_CONFIG);

    if (!this.isTouch) {
      document.documentElement.classList.add('no-touch');
    }
  }

}
