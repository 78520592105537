import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { FeedConst } from './feed.const';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { FeedItem } from './feed-item.model';
import { HttpService } from 'app/shared/helper/http.service';
import { Subject } from 'rxjs/Subject';
import { delay, finalize, map } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';

@Injectable()
export class FeedService {
  public feeds$: Subject<FeedItem[]> = new Subject();
  public isFeedsLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(private httpService: HttpService) {
  }

  public loadFeeds(): void {
    this.isFeedsLoading$.next(true);
    this.httpService.getNoCache<FeedItem[]>(
      environment.apigatewayUrl + FeedConst.api.endpoints.feedGetAllV2
    ).pipe(
      delay(200),
      map((feeds) => {
        this.feeds$.next(feeds ? feeds as FeedItem[] : null);
        return of(feeds);
      }),
      finalize(() => this.isFeedsLoading$.next(false)),
    ).subscribe();
  }
}
