import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalService } from '../ui/modal.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PhoneValidator } from '../app-forms/validators/phone.validator';
import { EmailValidator } from '../app-forms/validators/email.validator';
import { ModalInput } from './modal-input.model';
import {MaskComponent} from '../mask/mask.component';

@Component({
  selector: 'app-modal-box',
  templateUrl: './modal-box.component.html',
  styleUrls: ['./modal-box.component.scss']
})
export class ModalBoxComponent implements OnInit {
  // Template elements
  public title: string = null;
  public body: string = null;
  public button1title: string = null;
  public button2title: string = null;
  public illustrationPath: string = null;
  public input: ModalInput = null;
  public displayNumber: Number = null;
  // Display modal box
  public hasToOpen = false;
  // Modal box contains an input
  public hasInput = false;
  // Store input current value (for 'select-menu' input component)
  public inputCurrentValue = null; // TODO
  // Form group
  public form: FormGroup;
  // One or two button(s) template
  public hideSecondaryButton = false;
  public isSubmitted = false;

  @ViewChild('nativeForm')
  public nativeForm;

  constructor(private modalService: ModalService,
              private formBuilder: FormBuilder) {
    // Form non-ng-content modal
    this.modalService.openModalObservable.subscribe(
      content => {
        if (content) {
          this.title = content.title;
          this.body = content.body;
          this.illustrationPath = content.illustrationPath;
          this.hasToOpen = true;
          this.displayNumber = content.displayNumber;
          this.button1title = content.button1title ? content.button1title : 'global-generic_terms-cancel';
          this.button2title = content.button2title ? content.button2title : 'global-generic_terms-confirm';
          this.input = content.input ? content.input : null; // 'help_assistancepopin_formtitle'

          if (content.input) {
            this.hasInput = true;
            this.generateFormGroup();
          } else {
            this.hasInput = false;
          }

          this.hideSecondaryButton = (this.body === 'help_assistancepopin_legend3') || (this.button1title === 'MODAL_HIDE_BUTTON');

        } else {
          this.hasToOpen = false;
        }
      }
    );
  }

  public generateFormGroup() {
    if (!this.input || !this.input.type) {
      return false;
    }
    switch (this.input.type) {
      case 'tel':
        this.form = this.formBuilder.group({
          'tel': ['', PhoneValidator.simple]
        });
        break;
      case 'email':
        this.form = this.formBuilder.group({
          'email': ['', EmailValidator.simple]
        });
        break;
      case 'driver-cars':
        this.form = this.formBuilder.group({});
        break;
      case 'countries':
        this.form = this.formBuilder.group({});
        break;
    }
  }

  onClick(value: boolean) {
    if (value === true) {
      // -- Special case for the Help section (modal w/ input)-->
      if (this.hasInput) {
        // Manually submitting form to display error message
        this.isSubmitted = true;
        if (this.nativeForm) {
          this.nativeForm.onSubmit(null);
        }
        // Invalid form : keep the modal box open
        if (!this.form.valid) {
          return false;
        }
        // Valid form : retrieve input value, pass it to modal service and close modal box
        // this.modalService.modalValue.push(this.form.get(this.input.formControlName).value);
        this.saveInputValue();

        this.nextStep();
      } else {
        // Regular case
        this.nextStep();
      }
    } else {
      this.hasToOpen = false;
      this.modalService.modalCode = null;
    }

  }

  updateInputValue(obj) {
    this.inputCurrentValue = obj;
  }

  saveInputValue() {
    let value;
    switch (this.input.type) {
      case 'driver-cars':
        value = this.inputCurrentValue;
        break;
      case 'countries':
        value = this.inputCurrentValue;
        break;
      default:
        value = this.form.get(this.input.formControlName).value;
    }
    this.modalService.modalValue.push(value);
  }

  nextStep() {
    const isComplete = this.modalService.goToNextStep();
    if (isComplete) {
      this.hasToOpen = false;
      this.modalService.modalCode = null;
      return true;
    }
  }

  reloadPage($event) {
    location.reload();
  }

  ngOnInit() {
  }
}
