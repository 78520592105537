
import { environment } from 'environments/environment';

const apiGatewayUrl = environment.apigatewayUrl + '/apigateway/';

export const UserManagementConst = {
  api: {
    endpoints: {
      forgotPassword: apiGatewayUrl + 'usermanagement/api/User/ForgotPassword',
      authenticatedForgotPassword: apiGatewayUrl + 'usermanagement/api/User/AuthenticatedForgotPassword',
      resetPassword: apiGatewayUrl + 'usermanagement/api/User/ResetPassword',
      changeCurrentUserPassword: apiGatewayUrl + 'usermanagement/api/User/ChangePassword',
      getDriverUserProfile: apiGatewayUrl + 'usermanagement/api/User',
      getNewDriverUserProfile: apiGatewayUrl + 'usermanagement/api/user/UserProfile',
      getPreferences: apiGatewayUrl + 'usermanagement/api/User/Preferences',
      updatePreferences: apiGatewayUrl + 'usermanagement/api/User/UpdatePreferences',
      updateLastLoginDate: apiGatewayUrl + 'usermanagement/api/User/LoginDate',
      getExternalJwtToken: apiGatewayUrl + 'usermanagement/api/User/ExternalAppJwt',
    }
  }
};
