import { ApplicationRef, Component, HostBinding, OnDestroy } from '@angular/core';
import { generateTransitions } from '../../shared/router-transitions/router-transitions.animations';
import { NavigationDirectionService } from '../../shared/router-transitions/navigation-direction.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-up-confirmation',
  templateUrl: './sign-up-confirmation.component.html',
  styleUrls: ['./sign-up-confirmation.component.scss'],
  animations: [
    generateTransitions()
  ]
})
export class SignUpConfirmationComponent implements OnDestroy {

  @HostBinding('@routeAnim')
  public routeAnimState: string;
  private navDirectionChangeSubscription: Subscription;

  constructor(public router: Router,
              public navigationDirection: NavigationDirectionService,
              public appRef: ApplicationRef) {
    this.routeAnimState = this.navigationDirection.direction;
    this.navDirectionChangeSubscription = navigationDirection.changeObservable.subscribe(direction => {
      this.routeAnimState = direction;
      this.appRef.tick();
    });

  }

  ngOnDestroy() {
    this.navDirectionChangeSubscription.unsubscribe();
  }

}
