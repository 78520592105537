import { Injectable } from '@angular/core';
import { LogMessage } from './log-message.model';
import { AppInsightsService, SeverityLevel } from 'ng2-appinsights';

@Injectable()
export class LoggerService {

  constructor(private appInsight: AppInsightsService) {
  }

  debug(message: string) {
    this.log({level: SeverityLevel.Verbose, content: message});
  }

  info(message: string) {
    this.log({level: SeverityLevel.Information, content: message});
  }

  warning(message: string) {
    this.log({level: SeverityLevel.Warning, content: message});
  }

  error(message: string) {
    this.log({level: SeverityLevel.Error, content: message});
  }

  critical(message: string) {
    this.log({level: SeverityLevel.Critical, content: message});
  }

  log(message: LogMessage): void {
    if (message.level > 2) {
      this.appInsight.trackException(new Error(message.content), undefined, undefined, undefined, message.level);
    }
  }

}
