import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { OnErrorService } from '../ui/on-error.service';
import { FeatureConst } from './feature.consts';
import { ApiService } from '../common/api.service';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { AuthStorageService } from '../storage/auth/auth-storage.service';
import { AppStorageService } from '../storage/common/app-storage.service';
import 'rxjs';
import { HttpClient } from '@angular/common/http';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class FeatureService implements CanActivate {

  public featureListStream: BehaviorSubject<Array<string>>;

  constructor(private errorService: OnErrorService,
              private api: ApiService,
              private authStorageService: AuthStorageService,
              private http: HttpClient,
              private appStorageService: AppStorageService) {
    this.featureListStream = new BehaviorSubject<Array<string>>(null);
  }

  public updateFeaturesList() {
    this.get().subscribe(featureList => {
      this.featureListStream.next(featureList);
    });
  }

  public isFeatureActivated(featureName: string): Observable<boolean> {
    return this.featureListStream
      .pipe(
        filter(features => features != null),
        map(features => featureName.startsWith('!') ? !features.includes(featureName.substring(1)) : features.includes(featureName)
        )
      )
  }

  public isFeatureExists(featureSpecName): boolean {
    let isFeatureExists = false;
    Object.keys(FeatureConst.features).forEach((featureName) => {
      if (FeatureConst.features[featureName] === featureSpecName) {
        isFeatureExists = true;
        return;
      }
    });
    return isFeatureExists;
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.isFeatureActivated(FeatureConst.routes[route.url[0].path]);
  }

  private get(): Observable<Array<string>> {
    if (this.authStorageService.accessToken) {/* Request when authenticated*/
      return this.api.get(FeatureConst.api.availableFeatures)
        .catch(error => {
          this.errorService.updateMaintenanceStatus(true);
          return throwError(error);
        });
    } else { /* anonymous request */
      return this.api.get(FeatureConst.api.anonymousAvailableFeatures + this.appStorageService.countrySelected, true)
        .catch(error => {
          this.errorService.updateMaintenanceStatus(true);
          return throwError(error);
        });
    }
  }
}
