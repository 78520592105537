import { ErrorHandler, NgModule } from '@angular/core';
import { LoggerService } from '../helper/logger.service';

@NgModule({
  providers: [
    LoggerService
  ]
})
export class CustomErrorHandler extends ErrorHandler {

  constructor(private loggerService: LoggerService) {
    super();
  }

  /* override for handleError */
  public handleError(error: any): void {
    // anyway fallback to the ng handler
    this.loggerService.error(error);
    super.handleError(error);
  }
}
