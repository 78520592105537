import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
/*
  Importing hammerjs here (or anywhere in our .ts source files) enables Angular's own handling of hammerjs.
  This might become a problem.
*/
import 'hammerjs';

@Directive({
  selector: '[hammer]'
})
export class HammerGesturesDirective implements AfterViewInit, OnDestroy {

  @Input()
  public hammer: Function | Object;

  @Output()
  public gestureEmitter = new EventEmitter();

  constructor(private element: ElementRef) {
  }

  ngAfterViewInit() {

    const hammer = new Hammer(this.element.nativeElement);

    // Get default or supplied config
    let instanceConfig;
    if (this.hammer) {
      instanceConfig = this.hammer;
    } else {
      instanceConfig = {direction: Hammer.DIRECTION_HORIZONTAL, threshold: 5};
    }

    hammer.get('swipe').set(instanceConfig);

    hammer.on('swipeleft', (ev) => {
      this.gestureEmitter.emit('swipeleft');
    });

    hammer.on('swiperight', (ev) => {
      this.gestureEmitter.emit('swiperight');
    });

    hammer.on('tap', (ev) => {
      this.gestureEmitter.emit('tap');
    });

    hammer.on('pan', (ev) => {
      this.gestureEmitter.emit('pan');
    });

  }

  ngOnDestroy() {

  }

}
