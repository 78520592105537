import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {MaskComponent} from '../mask/mask.component';

@Component({
  selector: 'app-progressbar-simple',
  templateUrl: './progressbar-simple.component.html',
  styleUrls: ['./progressbar-simple.component.scss']
})
export class ProgressbarSimpleComponent implements OnInit {

  @Input()
  public msg: string;

  @Input()
  public perc: number;

  @Input()
  public css: string;

  @ViewChild('progress')
  public progress: ElementRef;

  public complete = false;

  constructor() {
  }

  ngOnInit() {
    this.progress.nativeElement.style.width = this.perc + '%';

    if (this.perc >= 100) {
      this.complete = true;
    }
  }

}
