// OLD API
/*const BASE_URL = 'https://integ.dataservices.aldautomotive.fr/apiservices/';
const DRIVER_PATH = BASE_URL + 'api/driverapp/';*/
/*Login : uzik
Password : uzik@webapi23! */
// --

// NEW API
// const BASE_URL = 'https://integ.driverapp.myald.fr/api/';

import { environment } from '../../../environments/environment';

const BASE_URL = 'https://homol.driverapp.myald.fr/api/';
const DRIVER_PATH = BASE_URL + 'driverapp/';
// --

// ROAD ASSISTANCE
const BASE_URL_ASSISTANCE = environment.apigatewayUrl + '/apigateway/RoadAssistance/api/MyAldRoadAssistanceRequest/';
// --

const POS_PATH = DRIVER_PATH + 'posapi/';
const CONTRACT_PATH = DRIVER_PATH + 'contract/';
const FEED_PATH = DRIVER_PATH + 'feed/events/';
const DOCUMENTS_PATH = DRIVER_PATH + 'document/';
const DRIVER_PROFILE_PATH = DRIVER_PATH + 'profile/';
const HELP_PATH = DRIVER_PATH + 'help/';

export class ApiEndpoints {
  public static AUTH_ENDPOINT = BASE_URL + 'token';
  public static API_ENDPOINT_DRIVER = DRIVER_PATH + 'driver/';
  public static API_ENDPOINT_FORGOTPWD = DRIVER_PATH + 'driver/forgotpassword';

  // DRIVER
  public static API_ENDPOINT_DRIVER_UPDATE_PROFILE = DRIVER_PROFILE_PATH + 'update'; // POST
  public static API_ENDPOINT_DRIVER_UPDATE_PASSWORD = DRIVER_PROFILE_PATH + 'updatepassword'; // POST
  public static API_ENDPOINT_DRIVER_UPDATE_ENTERPRISEINFO = DRIVER_PROFILE_PATH + 'enterpriseinfo/update'; // POST

  // FEED
  public static API_ENDPOINT_FEED = FEED_PATH;
  public static API_ENDPOINT_FEED_UPDATE = FEED_PATH + 'update'; // POST
  public static API_ENDPOINT_FEED_RATEPOS = FEED_PATH + 'ratepos'; // POST
  public static API_ENDPOINT_FEED_GET_ALL_V2 = '/apigateway/feedmanagement/api/Feed/items';

  // CONTRACT
  public static API_ENDPOINT_CONTRACT = DRIVER_PATH + 'contract';
  public static API_ENDPOINT_CONTRACT_UPDATEMILEAGE = CONTRACT_PATH + 'updatemileage';
  public static API_ENDPOINT_DRIVER_CARPICTURE = DRIVER_PROFILE_PATH + 'carpicture/';
  public static API_ENDPOINT_DRIVER_UPDATE_CARPICTURE = DRIVER_PROFILE_PATH + 'carpicture/update'; // POST
  public static API_ENDPOINT_CONTRACT_BUYVEHICLE = CONTRACT_PATH + 'buyvehicle'; // POST

  public static API_ENDPOINT_DRIVER_FUELCARDPIN = DRIVER_PATH + 'driver/fuelcardpin'; // POST

  // DOCUMENTS
  public static API_ENDPOINT_DOCUMENTS = DOCUMENTS_PATH;
  public static API_ENDPOINT_DOCUMENTS_DOWNLOAD = DOCUMENTS_PATH + 'download/';
  public static API_ENDPOINT_DOCUMENTS_URL = DOCUMENTS_PATH + 'download/url/';
  public static API_ENDPOINT_DOCUMENTS_PREVIEWURL = DOCUMENTS_PATH + 'download/previewurl/';
  public static API_ENDPOINT_DOCUMENTS_UPLOAD = DOCUMENTS_PATH + 'upload'; // POST
  public static API_ENDPOINT_DOCUMENTS_DELETE = DOCUMENTS_PATH + 'delete'; // POST

  // POS
  public static API_ENDPOINT_POS_SERVICES = POS_PATH + 'services';
  public static API_ENDPOINT_POS_POSINFO = POS_PATH + 'posinfo';
  public static API_ENDPOINT_POS_INQUADRANTPOSINFO = POS_PATH + 'inquadrantposinfo';
  public static API_ENDPOINT_POS_INQUADRANTPOSINFOKEEPBOUNDS = POS_PATH + 'inquadrantposinfokeepbounds';
  public static API_ENDPOINT_POS_CARMAKES = POS_PATH + 'carmakes';
  public static API_ENDPOINT_POS_CARMODELS = POS_PATH + 'carmodels';
  public static API_ENDPOINT_FEED_CREATEAPPOINTMENT = DRIVER_PATH + 'feed/appointment/create';
  // public static API_ENDPOINT_FEED_MODIFYAPPOINTMENT = DRIVER_PATH + 'feed/appointment/modify';
  // public static API_ENDPOINT_FEED_CANCELAPPOINTMENT = DRIVER_PATH + 'feed/appointment/cancel';

  // HELP
  public static API_ENDPOINT_HELP_DECLAREDAMAGE = HELP_PATH + 'declaredamage'; // POST
  public static API_ENDPOINT_HELP_CONTACTALD = HELP_PATH + 'contactald'; // POST

  // ON ROAD ASSISTANCE
  public static API_ASSISTANCE_ENDPOINT_PUTUSERINFO = BASE_URL_ASSISTANCE + 'PutUserInfo';
  public static API_ASSISTANCE_ENDPOINT_REFESHREQUEST = BASE_URL_ASSISTANCE + 'RequestRefresh';

}

export class AuthConfig {

  public static GRANT_TYPE_AUTH = 'password';
  public static GRANT_TYPE_TOKEN = 'refresh_token';

  public static CLIENT_ID = 'DriverApp';
  public static AUTH_KEY_STORAGE = 'auth';
  public static REMEMBER_AUTH_KEY_STORAGE = 'remember_auth';
  public static ACCESS_TOKEN_KEY_STORAGE = 'access_token';
  public static REFRESH_TOKEN_KEY_STORAGE = 'refresh_token';
  public static TOKEN_EXPIRES_KEY_STORAGE = '.expires';

}

export class Session {
  public static DRIVER_ID: string = null;
}



