import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentage'
})
export class PercentagePipe implements PipeTransform {

  transform(value: number, max: number, prevValue?: number): any {
    let percentage = Math.round((value / max) * 100);

    if (prevValue) {
      const total = max - prevValue;
      percentage = Math.round((value / total) * 100);
    }

    return percentage;
  }

}
