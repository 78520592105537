import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sticky-header-pane',
  templateUrl: 'sticky-header-pane.component.html',
  styleUrls: ['sticky-header-pane.component.scss']
})
export class StickyHeaderPaneComponent {

  @Input()
  public label: string;

  @Input()
  public showBack = true;

  @Input()
  public showClose = false;

  @Input()
  public fullWidth = false;

  @Input()
  public backgroundColor = false;

  @Input()
  public isHeaderForm = false;

  @Output()
  public scroll: EventEmitter<Event> = new EventEmitter();


  /**
   * Emit a back event when the user clicks the back button
   * @type {EventEmitter}
   */
  @Output()
  public back: EventEmitter<null> = new EventEmitter();

  constructor() {
  }


}
