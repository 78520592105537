import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssetPathPipe } from './asset-path.pipe';
import { PhoneNumberPipe } from './phone-number.pipe';
import { AddressPipe } from './address.pipe';
import { RelativeDatePipe } from './relative-date.pipe';
import { CaractersLimitPipe } from './caracters-limit.pipe';
import { CapitalizePipe } from './capitalize.pipe';
import { FormatNumberPipe } from './format-number.pipe';
import { PercentagePipe } from './percentage.pipe';
import { PercentageDatePipe } from './percentage-date.pipe';
import { MaxPipe } from './max.pipe';
import { WarningMessageDatePipe } from './warning-message-date.pipe';
import { FilterPipe } from './filter.pipe';
import { SortPipe } from './sort.pipe';
import { ForeachFilterPipe } from './foreach-filter';
import { BasicNumberPipe } from './basic-number.pipe';
import { CultureNamePipe } from './culture-name.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    AssetPathPipe,
    PhoneNumberPipe,
    AddressPipe,
    RelativeDatePipe,
    CaractersLimitPipe,
    CapitalizePipe,
    FormatNumberPipe,
    PercentagePipe,
    PercentageDatePipe,
    MaxPipe,
    WarningMessageDatePipe,
    FilterPipe,
    SortPipe,
    ForeachFilterPipe,
    BasicNumberPipe,
    CultureNamePipe,
  ],
  exports: [
    AssetPathPipe,
    PhoneNumberPipe,
    AddressPipe,
    RelativeDatePipe,
    CaractersLimitPipe,
    CapitalizePipe,
    FormatNumberPipe,
    PercentagePipe,
    PercentageDatePipe,
    MaxPipe,
    WarningMessageDatePipe,
    FilterPipe,
    SortPipe,
    ForeachFilterPipe,
    BasicNumberPipe,
    CultureNamePipe,
  ]
})
export class PipesModule {
}
