import { Directive, HostListener, Input } from '@angular/core';
import { NavigationDirectionService } from './navigation-direction.service';

@Directive({
  selector: 'a[navDirection]'
})
export class NavigationDirectionDirective {

  @Input()
  public navDirection: string;

  constructor(private navigationDirectionService: NavigationDirectionService) {

  }

  @HostListener('click', ['$event'])
  onClick($event) {
    this.navigationDirectionService.direction = this.navDirection;
  }

}
