import { DocumentsEffects } from './shared/documents.effects';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MaskComponent } from '../shared/mask/mask.component';
import { Document } from './shared/models/document.model';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { PopinService } from '../shared/ui/popin.service';
import { filter, map, takeWhile, tap } from 'rxjs/operators';
import { toggleAnimation } from 'app/shared/animations/toggle.animations';
import { DocumentsCategory } from './shared/enums/documents-category';

@Component({
  selector: 'app-documents-menu',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
  animations: [
    toggleAnimation
  ]
})
export class DocumentsComponent implements OnInit, OnDestroy {

  private isComponentActive = true;

  public allDocuments$: Observable<Document[]>;
  public isDocumentsLoading$: BehaviorSubject<boolean>;
  public hasDocumentsError$: BehaviorSubject<boolean>;
  public personalDocuments: Document[];
  public receivedDocuments: Document[];
  public isSearchEmpty = true;

  @ViewChild(MaskComponent) mask: MaskComponent;
  public stateToggle: string;

  constructor(
    private router: Router,
    private documentsEffects: DocumentsEffects,
    private popinService: PopinService) {
  }

  ngOnInit() {
    this.documentsEffects.getDocumentsAction().subscribe();

    this.allDocuments$ = this.documentsEffects.documents$;
    this.hasDocumentsError$ = this.documentsEffects.hasDocumentsError$;
    this.isDocumentsLoading$ = this.documentsEffects.isDocumentsLoading$;

    // Personal documents with search filter
    combineLatest([
      this.allDocuments$.pipe(
        filter(documents => !!documents),
        map((documents) => documents.filter(document => document.category.name === DocumentsCategory.PERSONAL_DOCUMENT)),
      ),
      this.documentsEffects.searchQuery$
    ]).pipe(
      takeWhile(() => this.isComponentActive),
      map(this.filterDocument),
      tap((personalDocuments) => this.personalDocuments = personalDocuments),
    ).subscribe();

    // Received documents with search filter
    combineLatest([
      this.allDocuments$.pipe(
        filter(documents => !!documents),
        map((documents) => documents.filter(document => document.category.name !== DocumentsCategory.PERSONAL_DOCUMENT)),
      ),
      this.documentsEffects.searchQuery$
    ]).pipe(
      takeWhile(() => this.isComponentActive),
      map(this.filterDocument),
      tap((receivedDocuments) => this.receivedDocuments = receivedDocuments),
    ).subscribe();

    this.popinService.opened(null);
    this.popinService.getOpened().pipe(
      takeWhile(() => this.isComponentActive),
    )
      .subscribe(
        isPopinOpened => {
          this.stateToggle = isPopinOpened ? 'shown' : 'hidden';
          this.mask.show = !!isPopinOpened;
        });
  }

  public isSearchNoResults(): boolean {
    return this.personalDocuments && !this.personalDocuments.length
      && this.receivedDocuments && !this.receivedDocuments.length && !this.isSearchEmpty;
  }

  public searchDocument(searchTerm: string): void {
    this.isSearchEmpty = searchTerm === '';
    this.documentsEffects.searchQuery$.next(searchTerm);
  }

  public filterDocument(searchParams: [Document[], string]): Document[] {
    const [documents, searchTerm] = searchParams;
    if (searchTerm === '') {
      return documents;
    }
    return documents.filter((d: Document) => d.title.toLowerCase().includes(searchTerm.trim().toLowerCase()));
  }

  public animationDone($event) {
    if ($event.toState === 'hidden') {
      this.router.navigate(['/documents']);
    }
  }

  public onClosePopin() {
    this.popinService.opened(false);
  }

  ngOnDestroy() {
    this.isComponentActive = false;
  }
}
