import { Injectable } from '@angular/core';
import { RequestParameter } from '../common/request-parameter.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiHelper } from '../common/api.helper';
import { SignUpConst } from './sign-up.const';
import { Observable } from 'rxjs';

@Injectable()
export class RequestAccessService {

  headers = new HttpHeaders({'Accept': 'application/json', 'Content-type': 'application/json'});
  options = {headers: this.headers};

  constructor(private http: HttpClient,
              private apiHelper: ApiHelper) {
  }

  tryRequest(params: RequestParameter[], role: string, isManagerV2: boolean): Promise<any> {
    let url: string;
    if (isManagerV2) {
      url = SignUpConst.api.base + SignUpConst.api.endpoints.managerV2RequestAccessUrl;
    } else {
      url = (role === 'fleet') ?
      SignUpConst.api.base + SignUpConst.api.endpoints.managerRequestAccessUrl :
      SignUpConst.api.base + SignUpConst.api.endpoints.driverRequestAccessUrl;
    }
    return this.http.post(url, this.apiHelper._stringifyParams(params), this.options).toPromise();
  }

  prospectSignUp(params: RequestParameter[]): Promise<any> {
    const url = SignUpConst.api.base + SignUpConst.api.endpoints.managerV2prospectRequestAccessUrl;
    return this.http.post(url, this.apiHelper._stringifyParams(params), this.options).toPromise();
  }

  getGrades(domainId: string): Observable<any> {
    const url = SignUpConst.api.base + SignUpConst.api.endpoints.managerV2DomainManagementUrl + domainId;
    return this.http.get(url);
  }
}
