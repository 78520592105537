import { Component, OnDestroy, OnInit } from '@angular/core';
import { PopinService } from '../../shared/ui/popin.service';
import { ConfigurationService } from '../../shared/config/configuration.service';
import { AppStorageService } from '../../shared/storage/common/app-storage.service';
import { GTM_EVENTS } from '../../shared/gtm/gtm-events.const';

@Component({
  selector: 'app-help-generic',
  templateUrl: './help-generic.component.html',
  styleUrls: ['./help-generic.component.scss']
})
export class HelpGenericComponent implements OnInit, OnDestroy {

  public assistancePhoneSecondary: string;
  public readonly GTM_EVENTS = GTM_EVENTS;

  constructor(
    private popinService: PopinService,
    private appStorageService: AppStorageService,
    private configurationService: ConfigurationService) {
  }

  ngOnInit() {
    this.popinService.opened(true);

    this.configurationService.getConfItems(
      [this.appStorageService.countrySelected, 'AssistancePhoneSecondary']
    ).subscribe((configurationItem) => {
        this.assistancePhoneSecondary = configurationItem.value;
      }
    );
  }

  ngOnDestroy() {
    this.popinService.opened(false);
  }

  onBackClick() {
    this.popinService.opened(false);
  }

}
