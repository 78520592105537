const ContractStatus = {
  VehicleOnRoad: 'VehicleOnRoad',
  ContractOrder: 'ContractOnOrder',
  CommandSaved: 'CommandSaved',
  CommandConfirmed: 'CommandConfirmed',
  VehicleFactored: 'VehicleFactored',
  VehicleDelivered: 'VehicleDelivered',
  VehicleRegistered: 'VehicleRegistered',
  VehicleReturned: 'VehicleReturned',
  VehicleSold: 'VehicleSold',
  ContractTerminating: 'ContractTerminating',
  ContractTerminated: 'ContractTerminated',
};

export default ContractStatus;
