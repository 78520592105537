import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationDirectionService } from '../../shared/router-transitions/navigation-direction.service';

@Component({
  selector: 'app-account-disabled',
  templateUrl: './account-disabled.component.html',
  styleUrls: ['./account-disabled.component.scss']
})
export class AccountDisabledComponent {

  constructor(public router: Router,
              public navigationDirection: NavigationDirectionService) {
  }

  public callAssistance() {

  }
}
