import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentageDate'
})
export class PercentageDatePipe implements PipeTransform {

  transform(prevDeadline: string, nextDeadline: string): any {

    const prevDeadlineTime = new Date(prevDeadline).getTime();
    const nextDeadlineTime = new Date(nextDeadline).getTime();
    const currentTime = new Date().getTime();

    const timeBounderDiff = Math.abs(nextDeadlineTime - prevDeadlineTime);
    const total = Math.ceil(timeBounderDiff / (1000 * 3600 * 24));

    const timeDiff = Math.abs(currentTime - prevDeadlineTime);
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));


    return Math.round((diffDays / total) * 100);
  }

}
