﻿import { MaterialModule } from 'app/shared/ui/material/material.module';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalBoxComponent } from './modal-box.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppFormsModule } from '../app-forms/app-forms.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    AppFormsModule,
    MaterialModule,
  ],
  declarations: [
    ModalBoxComponent
  ],
  exports: [
    ModalBoxComponent
  ]
})
export class ModalBoxModule {
}
