import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { RequestParameter } from '../common/request-parameter.model';
import { JwtHelper } from 'angular2-jwt';
import { ConfigurationService } from '../config/configuration.service';
import { ConfigurationModel } from 'app/shared/config/configuration.model';
import { AuthConst } from './auth.const';
import { AuthStorageService } from '../storage/auth/auth-storage.service';
import { TranslationStorageService } from '../storage/translation/translation-storage.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable, throwError } from 'rxjs';
import { AppStorageService } from '../storage/common/app-storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiHelper } from '../common/api.helper';
import { WindowRef } from '../helper/window.ref';
import { ConfigurationItems } from '../config/configuration-items';

@Injectable()
export class AuthService implements CanActivate {

  public authStatus$: BehaviorSubject<string>;
  private _accessToken: any = null;
  private _refreshToken: any;
  private _tokenExpires: any;
  private jwtHelper: JwtHelper = new JwtHelper();
  private _authStatus: any;

  constructor(private router: Router,
    private authStorage: AuthStorageService,
    private appStorage: AppStorageService,
    private configurationService: ConfigurationService,
    private http: HttpClient,
    private apiHelper: ApiHelper,
    private activatedRoute: ActivatedRoute,
    private window: WindowRef,
    private translationStorage: TranslationStorageService) {

    this.authStatus$ = new BehaviorSubject<string>(AuthConst.status.LOGGED_OUT);

    if (this.authStorage.rememberAuth) {
      this.updateValues();
      this.refreshToken().subscribe();
    }
  }

  public get isAuthenticated(): boolean {
    return this._authStatus === AuthConst.status.LOGGED_IN;
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.isAuthenticated) {
      this.appStorage.set('returnVisit', true);
      let navigationParameters = {};
      const returnUrl = route.queryParams.returnUrl;
      if (returnUrl !== undefined && returnUrl !== null) {
        navigationParameters = { queryParams: { returnUrl: returnUrl } };
      }
      this.router.navigate(['/start/login'], navigationParameters);
    }
    return this.isAuthenticated;
  }

  public setAuthStorage(tokens) {
    this.clearValues();
    this.authStorage.rememberAuth = tokens;
    this.updateValues();
    this.updateAuthStatus(AuthConst.status.LOGGED_IN);
  }

  public clearAuthentication(hasToRedirect = false) {
    this.clearValues();
    this.updateAuthStatus(AuthConst.status.LOGGED_OUT);

    if (hasToRedirect) {
      this.router.navigate(['/start/login']);
    }
  }

  public refreshToken(): Observable<any> {
    const adfsUrl = new URL(environment.adfsUrl);

    const params: RequestParameter[] = [];
    params.push(new RequestParameter('grant_type', 'refresh_token'));
    params.push(new RequestParameter('refresh_token', this.authStorage.refreshToken));
    params.push(new RequestParameter('client_id', adfsUrl['searchParams'].get('client_id')));
    params.push(new RequestParameter('redirect_uri', adfsUrl['searchParams'].get('redirect_Uri')));

    const headers = new HttpHeaders({ 'Content-type': 'application/x-www-form-urlencoded' });
    const options = { headers: headers };

    return this.http.post<any>(
      adfsUrl.origin + '/adfs/oauth2/token',
      this.apiHelper._stringifyParams(params, 'application/x-www-form-urlencoded'),
      options
    ).map(response => {
      if (response && response.access_token) {
        const storageTokens = this.authStorage['rememberAuth'];

        storageTokens['access_token'] = response.access_token;

        this.authStorage['rememberAuth'] = storageTokens;

        this.updateValues();
        this.updateAuthStatus(AuthConst.status.LOGGED_IN);
        return true;
      } else {
        this.clearAuthentication(true);
      }
    }).catch(error => {
      this.clearAuthentication(true);
      return throwError(error);
    });
  }

  public redirectIfWrongCountry(tokens): boolean {
    const decodedToken = this.jwtHelper.decodeToken(tokens.access_token);
    const userCountry = decodedToken['http://www.aldautomotive.fr/claims/country'].toUpperCase();
    const domainCountry = this.appStorage.countrySelected.toUpperCase();
    const redirect = userCountry !== domainCountry;

    if (redirect) {
      this.configurationService.getConfItems([userCountry, ConfigurationItems.DriverWebsiteUrl])
        .subscribe((feedback: ConfigurationModel) => {
          const { value } = feedback;
          this.window.nativeWindow.location.href = `${value}/start/login?returnUrl=/feed`;
        }, e => {
          console.error('An error has occured: ', e);
        });
    }
    return redirect;
  }

  public endDriverSignOn(password: string): Observable<any> {
    const params: RequestParameter[] = [];
    const key = this.activatedRoute.snapshot.queryParams['key'];
    params.push(new RequestParameter('signOnKey', key));
    params.push(new RequestParameter('password', password));
    return this.sendChangePassword(params, AuthConst.api.base + AuthConst.api.endpoints.endDriverSignOn, 'End Ald Driver Sign On');
  }

  public endProspectCreatePassword(password: string): Observable<any> {
    const params: RequestParameter[] = [];
    const key = this.activatedRoute.snapshot.queryParams['key'];
    params.push(new RequestParameter('userId', key));
    params.push(new RequestParameter('password', password));
    return this.sendChangePassword(params, AuthConst.api.base + AuthConst.api.endpoints.endCreateProspectPassword, 'End Ald Create Prospect Password');
  }

  public endForgotPassword(password: string): Observable<any> {
    const params: RequestParameter[] = [];
    const key = this.activatedRoute.snapshot.queryParams['key'];
    params.push(new RequestParameter('forgotKey', key));
    params.push(new RequestParameter('password', password));
    return this.sendChangePassword(params, AuthConst.api.base + AuthConst.api.endpoints.endForgotPassword, 'End Forgot Password');
  }

  public forgotPassword(userid: string, recaptcha?: string): Observable<any> {
    const params: RequestParameter[] = [];
    params.push(new RequestParameter('culture', this.translationStorage.lang.toString()));
    params.push(new RequestParameter('email', userid));
    params.push(new RequestParameter('subsidiary', this.appStorage.countrySelected));
    params.push(new RequestParameter('recaptcha', recaptcha));

    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const options = { headers: headers };

    return this.http.post<any>(AuthConst.api.base + AuthConst.api.endpoints.forgotPwd,
      this.apiHelper._stringifyParams(params),
      options)
      .map(response => {
        return true;
      }).catch(error => throwError(error));
  }

  private clearValues() {
    this.authStorage.removeAuth();
    this._accessToken = null;
    this._refreshToken = null;
    this._tokenExpires = null;
  }

  private updateValues() {
    this._accessToken = this.authStorage.accessToken;
    this._refreshToken = this.authStorage.refreshToken;
    this._tokenExpires = this.authStorage.tokenExpires;
  }

  private updateAuthStatus(status: string) {
    this._authStatus = status;
    this.authStatus$.next(status);
  }

  private sendChangePassword(parameters: RequestParameter[], url: string, eventDescription: string): Observable<any> {
    const params = parameters;
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const options = { headers: headers };
    return this.http.post<any>(url, this.apiHelper._stringifyParams(params), options)
      .map(response => {
        return response;
      }).catch(error => {
        return throwError(error);
      });
  }

  public checkUserResetPasswordTokenValidity(token: string): Observable<any> {
    const url = AuthConst.api.base + AuthConst.api.endpoints.userCheckToken + token;
    return this.http.get(url);
  }

  public checkProspectPendingCreationTokenValidity(token: string): Observable<any> {
    const url = AuthConst.api.base + AuthConst.api.endpoints.prospectCheckToken + token;
    return this.http.get(url);
  }
}
