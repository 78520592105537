﻿import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentsComponent } from './documents.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddDocumentComponent } from './add-document/add-document.component';
import { DocumentsService } from './shared/documents.service';
import { DocumentsListComponent } from './documents-list/documents-list.component';
import { DocumentItemComponent } from './document-item/document-item.component';
import { HelpModule } from '../help/help.module';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material';
import { DocumentsRouting } from './documents.routing';
import { DocumentsSearchComponent } from './documents-search/documents-search.component'
import { EditDocumentComponent } from './edit-document/edit-document.component';
import { MyAldUiModule } from 'app/shared/ui/myald-ui/myald-ui.module';


@NgModule({
  imports: [
    CommonModule,
    DocumentsRouting,
    SharedModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatCardModule,
    MatGridListModule,
    MatButtonModule,
    HelpModule,
    MyAldUiModule,
  ],
  declarations: [
    DocumentsComponent,
    DocumentsListComponent,
    DocumentItemComponent,
    AddDocumentComponent,
    DocumentsSearchComponent,
    EditDocumentComponent,
  ],
  providers: [
    DocumentsService
  ],
})
export class DocumentsModule {
}
