﻿import { TranslationService } from '../../shared/translation/translation.service';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'warningMessageDate',
  pure: false, // to refresh the translation inside this pipe
})
export class WarningMessageDatePipe implements PipeTransform {
  private daysTranslationKey = 'cars-menu_page-days_count';
  private weeksTranslationKey = 'cars-menu_page-weeks_count';
  private monthsTranslationKey = 'cars-menu_page-months_count';
  private yearsTranslationKey = 'cars-menu_page-years_count';

  private messageTranslationKey = 'global-generic_terms-update_mileage_message';
  private messageNoDataTranslationKey = 'global-generic_terms-no_data';

  constructor(private translationService: TranslationService) {

  }

  transform(from: string, limit = 30): any {
    let warningMessage = null;
    let duration = null;
    if (from !== null) {
      from = this.removeZeroHourOffset(from);
    }

    // Date returned by API when there is no data
    const errorAPI = moment('0001-01-01', 'YYYY-MM-DD');

    const currentTime = moment(new Date().getTime());
    const fromTime = moment(new Date(from).getTime());
    let timeIndicator = this.daysTranslationKey;

    if (currentTime.diff(fromTime, 'days') > limit) {
      if (fromTime.isSame(errorAPI, 'year')) {
        // API Error: no data
        this.translationService.translate(this.messageNoDataTranslationKey).subscribe((message: string) => {
          warningMessage = message;
        });
      } else {
        // API ok
        if (currentTime.diff(fromTime, 'years') >= 1) {
          duration = currentTime.diff(fromTime, 'years');
          timeIndicator = this.yearsTranslationKey;
        } else if (currentTime.diff(fromTime, 'months') > 1) {
          duration = currentTime.diff(fromTime, 'months');
          timeIndicator = this.monthsTranslationKey;
        } else if (currentTime.diff(fromTime, 'weeks') > 1) {
          duration = currentTime.diff(fromTime, 'weeks');
          timeIndicator = this.weeksTranslationKey;
        } else {
          duration = currentTime.diff(fromTime, 'days');
        }

        this.translationService.translate(timeIndicator, duration).subscribe((durationWIndicator: string) => {
          this.translationService.translate(this.messageTranslationKey, durationWIndicator).subscribe((message: string) => {
            warningMessage = message;
          });
        });
      }

    }
    return warningMessage;
  }

  private removeZeroHourOffset(date) {
    return date.substr(
      0,
      date.endsWith('Z') ? date.length - 1 :
        date.length
    );
  }

}
