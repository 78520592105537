import { environment } from 'environments/environment';

export const ContractConst = {
  api: {
    baseUrl: `${environment.apigatewayUrl}/apigateway/contract/api/`,
    modules: {
      customer: {
        url: 'customer/',
        endpoints: {
          contract: 'contract',
          carpicture: 'carpicture',
          carpictureUpdate: 'carpicture/update',
          buyvehicle: 'buyvehicle',
          fuelcardpin: 'driver/fuelcardpin',
        },
      },
      contract: {
        url: 'contract/',
        endpoints: {
          maintenanceHistory: 'maintenanceHistory/',
          updateMileage: 'UpdateMileage',
          fuelHistory: 'FuelCardHistory/',
        },
      },
    },
  }
};

export enum UpdateMileageStatus {
  Success = 'Success',
  Invalid = 'InvalidData',
  Error = 'Error',
}
