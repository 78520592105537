import { DocumentsComponent } from './documents.component';
import { AddDocumentComponent } from './add-document/add-document.component';
import { MorePersonalInformationComponent } from '../shared/app-menu/more-personal-information/more-personal-information.component';
import { HelpComponent } from '../help/help.component';
import { ContractStatusGuard } from '../driver/shared/contract/guards/contract-status-guard.service';
import { HelpGenericComponent } from '../help/help-generic/help-generic.component';
import { ContractStatusExcludeGuard } from '../driver/shared/contract/guards/contract-status-exclude-guard.service';
import { MoreResetPasswordComponent } from '../shared/app-menu/more-reset-password/more-reset-password.component';
import { MyPreferencesComponent } from 'app/shared/app-menu/my-preferences/my-preferences.component';
import { RouterModule } from '@angular/router';
import { EditDocumentComponent } from './edit-document/edit-document.component';
import { FeatureService } from 'app/shared/feature/feature.service';


const ROUTES = [
    {
      path: '',
      component: DocumentsComponent,
      children: [
        {
          path: 'add-other',
          component: AddDocumentComponent,
        },
        {
          path: 'edit/:id',
          component: EditDocumentComponent,
          canActivate: [FeatureService],
        },
        {
          path: 'personal-informations',
          component: MorePersonalInformationComponent,
        },
        {
          path: 'preferences',
          component: MyPreferencesComponent,
        },
        {
          path: 'reset-password',
          component: MoreResetPasswordComponent,
        },
        {
          path: 'help',
          component: HelpComponent,
          canActivate: [ContractStatusGuard],
          data: {
            status: ['active']
          }
        },
        {
          path: 'assistance',
          component: HelpGenericComponent,
          canActivate: [ContractStatusExcludeGuard],
          data: {
            status: ['active'],
            acceptProspect: true
          }
        },
      ]
    }
  ];

  export const DocumentsRouting = RouterModule.forChild(ROUTES);
