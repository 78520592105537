import { Component, ContentChild, ElementRef, Host, Input, Optional } from '@angular/core';
import { FormGroupDirective, NgControl, NgForm, NgModel } from '@angular/forms';
import { AppConfigService } from '../../config/app-config.service';

@Component({
  selector: 'app-string-input',
  templateUrl: './string-input.component.html',
  styleUrls: ['./string-input.component.scss']
})
export class StringInputComponent {
  /**
   * Placeholder acts as a label and in most cases a placeholder. When the input receives focus it moves above the field.
   */
  @Input()
  public placeholder: string;

  @Input()
  public active: boolean;

  @Input()
  public requiredError: string;

  @Input()
  public invertedXs: boolean;

  /*

  Bind either content children below

   */

  @ContentChild(NgModel)
  public inputModel: NgModel;

  @ContentChild(NgControl)
  public inputControl: NgControl;

  constructor(public appConfig: AppConfigService,
              public elementRef: ElementRef,
              @Host() @Optional() private ngForm: NgForm, // get form from parent component
              @Host() @Optional() private formGroupDir: FormGroupDirective // get form from parent component
  ) {

  }

  /**
   * Get either the content as an input or a ng-model depending on the type of form we are using
   * @returns {NgControl|NgModel}
   */
  public get input() {
    return this.inputControl || this.inputModel;
  }

  get form() {
    return this.ngForm || this.formGroupDir;
  }

  onFocusIn() {

    // On Android, scroll not to hide the input behind the keyboard
    if (this.appConfig.isMobile && !this.appConfig.isIos) {
      const headerHeight = 60;
      // First scrollable parent
      const parent = this.getScrollableParent(this.elementRef.nativeElement);
      if (parent) {
        const that = this;
        // We have to name the function in order to remove the listener once it's done
        const scrollInputToTop = function () {
          setTimeout(() => {
            // Target
            let scrollTo = parent.scrollTop - parent.getBoundingClientRect().top +
              that.elementRef.nativeElement.getBoundingClientRect().top - headerHeight;
            // Max possible scroll
            const scrollMax = parent.scrollHeight - parent.clientHeight;
            scrollTo = scrollTo > scrollMax ? scrollMax : scrollTo;
            that.smoothScrollTo(parent, scrollTo, 250);
            this.removeEventListener('resize', scrollInputToTop);
          }, 10);
        };


        window.addEventListener('resize', scrollInputToTop);
      }
    }
  }

  private getScrollableParent(node) {
    if (node == null) {
      return null;
    }
    if (!node || !node.nodeType || node instanceof HTMLDocument) {
      return null;
    }
    const overflow = window.getComputedStyle(node).overflowY;
    const isScrollable = (overflow !== 'visible') && (overflow !== 'hidden');
    if (node.scrollHeight > node.clientHeight && isScrollable) {
      return node;
    } else {
      return this.getScrollableParent(node.parentNode);
    }
  }

  private smoothScrollTo(element, to, duration) {
    const start = element.scrollTop;
    const change = to - start;
    let currentTime = 0;
    const increment = 20;

    const animateScroll = (() => {
      currentTime += increment;
      const val = this.easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    });
    animateScroll();
  }

  private easeInOutQuad(t, b, c, d) {
    t /= d / 2;
    if (t < 1) {
      return c / 2 * t * t + b;
    }
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  }

}
