import { RequestParameter } from './request-parameter.model';

export class ApiHelper {

  _stringifyParams(parameters: RequestParameter[], contentType = 'application/json'): string {
    if (contentType === 'application/json' && parameters instanceof Array) {
      const jsonParams = {};
      parameters.forEach((item, index) => {
        jsonParams[item.name] = item.value;
      });
      return JSON.stringify(jsonParams);
    } else if (contentType === 'application/json') {
      return JSON.stringify(parameters);
    }

    let stringParams = '';
    parameters.forEach((item, index) => {
      stringParams += item.name + '=' + item.value;
      if (index !== (parameters.length - 1)) {
        stringParams += '&';
      }
    });
    return stringParams;

  }
}
