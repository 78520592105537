﻿import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MoreMenuComponent } from './more.component';
import { RouterModule } from '@angular/router';
import { MoreFaqComponent } from './more-faq/more-faq.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MoreResetPasswordComponent } from '../shared/app-menu/more-reset-password/more-reset-password.component';
import { MorePersonalInformationComponent } from '../shared/app-menu/more-personal-information/more-personal-information.component';
import { HelpComponent } from '../help/help.component';
import { ContractStatusGuard } from '../driver/shared/contract/guards/contract-status-guard.service';
import { HelpGenericComponent } from '../help/help-generic/help-generic.component';
import { ContractStatusExcludeGuard } from '../driver/shared/contract/guards/contract-status-exclude-guard.service';
import { HelpModule } from '../help/help.module';
import { MyPreferencesComponent } from 'app/shared/app-menu/my-preferences/my-preferences.component';
import { GtmModule } from 'app/shared/gtm/gtm.module';

const ROUTES = [
  {
    path: '',
    component: MoreMenuComponent,
    children: [
      {
        path: 'faq',
        component: MoreFaqComponent
      },
      {
        path: 'personal-informations',
        component: MorePersonalInformationComponent,
      },
      {
        path: 'preferences',
        component: MyPreferencesComponent,
      },
      {
        path: 'reset-password',
        component: MoreResetPasswordComponent
      },
      {
        path: 'help',
        component: HelpComponent,
        canActivate: [ContractStatusGuard],
        data: {
          status: ['active']
        }
      },
      {
        path: 'assistance',
        component: HelpGenericComponent,
        canActivate: [ContractStatusExcludeGuard],
        data: {
          status: ['active'],
          acceptProspect: true
        }
      },
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(ROUTES),
    SharedModule,
    TranslateModule,
    HelpModule,
    GtmModule,
  ],
  declarations: [
    MoreMenuComponent,
    MoreFaqComponent,
  ]
})
export class MoreModule {
}
