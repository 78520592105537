import { Injectable } from '@angular/core';
import { AppStorageService } from '../common/app-storage.service';
import { TranslationStorageConst } from './translation-storage.const';

@Injectable()
export class TranslationStorageService {

  constructor(private storage: AppStorageService) {
  }

  /**
   * Gets language value
   */
  get lang(): string {
    return this.storage.get(TranslationStorageConst.keys.lang);
  }

  /**
   * Stores language value
   */
  set lang(lang: string) {
    this.storage.set(TranslationStorageConst.keys.lang, lang);
  }

}
