import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { StickyHeaderPaneComponent } from './sticky-header-pane/sticky-header-pane.component';
import { StickyHeaderComponent } from './sticky-header/sticky-header.component';
import { HammerGesturesModule } from '../directives/hammer-gestures/hammer-gestures.module';
import { AppFormsModule } from '../app-forms/app-forms.module';


@NgModule({
  imports: [
    CommonModule,
    SvgIconModule,
    HammerGesturesModule,
    AppFormsModule
  ],
  declarations: [
    StickyHeaderPaneComponent,
    StickyHeaderComponent
  ],
  exports: [
    StickyHeaderPaneComponent,
    StickyHeaderComponent
  ]
})
export class StickyHeaderModule {
}
