import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PopinService } from 'app/shared/ui/popin.service';
import { DriverService } from 'app/driver/shared/driver.service';
import { UserProfile } from 'app/driver/shared/models/user-profile.model';
import { UserRoleContract } from 'app/driver/shared/models/user-contract.model';
import { contractIsStatusOfType } from 'app/driver/shared/contract/contract-status.helper';
import { takeWhile } from 'rxjs/operators';
import { ContractConsolidatedStatusType } from 'app/driver/shared/contract/contract-status.const';
import { SelectMenuComponent } from '../../../shared/app-forms/select-menu/select-menu.component';
import { OrderMaintenanceChanges } from 'app/help/shared/help.const';
import { SelectAutocompleteAddressComponent } from 'app/shared/app-forms/select-autocomplete-address/select-autocomplete-address.component';
import { ContractOrderMaintenance } from 'app/help/shared/models/help-driver-assistance.model';
import { HelpService } from 'app/help/shared/help.service';

@Component({
  selector: 'app-order-maintenance',
  templateUrl: './order-maintenance.component.html',
  styleUrls: ['./order-maintenance.component.scss']
})
export class HelpOrderMaintenanceComponent implements OnInit, OnDestroy {

  @ViewChild('serviceField') serviceField: SelectMenuComponent;
  @ViewChild('selectAutocompleteAddress')
  public selectAutocomplete: SelectAutocompleteAddressComponent;

  public addressSelected: string;
  public form: FormGroup;
  private isComponentActive = true;
  public newUserProfile: UserProfile;
  public vehicleList = [];
  public serviceList = [];
  public vehicleSelected = 0;
  public submitStatus = 'initial';
  public minDate = new Date();
  public minimumServiceBooked: number;
  public isFormLoading = true;
  public error = null;
  public readonly SERVICES_BOOKED_SLICE = 5000;
  public readonly SERVICES_BOOKED_MAX = 205000;
  public readonly CHANGES = Object.keys(OrderMaintenanceChanges);

  constructor(
    private router: Router,
    private popinService: PopinService,
    private helpService: HelpService,
    private driverService: DriverService) {
  }

  ngOnInit() {
    this.getDriver();
    this.popinService.opened(true);
  }

  private getDriver(): void {
    this.driverService.newDriverStream
      .subscribe((newDriver: UserProfile) => {
        this.newUserProfile = newDriver;
        this.generateVehicleList();
        this.buildForm();
      });
  }

  public updateSelectAddress(label) {
    this.form.get('carPickUpAddress').setValue(label);
  }

  public updateVehicleList(obj): void {
    this.form.get('servicingToBeBooked').setValue('');
    if (this.serviceField) {
      this.serviceField.resetSelect();
    }
    this.form.get('contractId').setValue(obj.id);
    this.minimumServiceBooked = obj.lastMileage ?
      Math.ceil(obj.lastMileage / this.SERVICES_BOOKED_SLICE) * this.SERVICES_BOOKED_SLICE :
      this.SERVICES_BOOKED_SLICE;
    this.getServicesToBeBookedValues();
  }

  public updateServiceList(obj): void {
    this.form.get('servicingToBeBooked').setValue(obj.id);
    this.getServicesToBeBookedValues();
  }

  public getServicesToBeBookedValues(): void {
    const iterationCount = 1 + (this.SERVICES_BOOKED_MAX - this.minimumServiceBooked) / this.SERVICES_BOOKED_SLICE;
    this.serviceList = Array(iterationCount).fill(this.minimumServiceBooked).map((x, i) => {
      return {
        label: x + (i * this.SERVICES_BOOKED_SLICE) + ' km',
        id: x + (i * this.SERVICES_BOOKED_SLICE),
      }
    });
  }

  private generateVehicleList(): void {
    this.vehicleList = this.driverService.getNewUserRole(this.newUserProfile).contracts.filter((contract: UserRoleContract) => {
      return contractIsStatusOfType(contract, ContractConsolidatedStatusType.Active);
    }).map((contract: UserRoleContract) => {
      return {
        id: contract.reference,
        label: `${contract.vehicle.brand} ${contract.vehicle.plate}`,
        lastMileage: contract.mileage.lastMileage
      };
    });

    if (this.vehicleList.length === 1) {
      this.vehicleSelected = 0;
    }
  }

  public toggleChangeField(change: string): void {
    const currentChanges: OrderMaintenanceChanges[] = this.form.get('change').value || [];
    let newChanges = [];
    if (Array.isArray(this.form.get('change').value) && this.form.get('change').value.includes(OrderMaintenanceChanges[change])) {
      newChanges = currentChanges.filter(c => c !== OrderMaintenanceChanges[change]);
    } else {
      newChanges = [...currentChanges, ...[OrderMaintenanceChanges[change]]];
    }
    if (newChanges.length === 0) {
      newChanges = null;
    }
    this.form.get('change').setValue(newChanges);
  }

  private buildForm(): void {
    // Build basic form
    this.form = new FormGroup({
      contractId: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      carPickUp: new FormControl(false, [Validators.required]),
      servicingToBeBooked: new FormControl('', [Validators.required]),
      change: new FormControl(null, [Validators.required]),
      pickUpServiceNote: new FormControl(''),
      pickUpDate: new FormControl(null),
      pickUpTime: new FormControl({ value: '', disabled: true }),
      isSameAddress: new FormControl(true),
      carPickUpAddress: new FormControl(null),
    });
    // Time can't be set before date
    this.form.get('pickUpDate').valueChanges.pipe(
      takeWhile(() => this.isComponentActive),
    ).subscribe((date) => {
      date && this.form.controls.pickUpDate.valid ?
        this.form.controls.pickUpTime.enable() :
        this.form.controls.pickUpTime.disable();
      this.form.get('pickUpTime').setValue('');
    });
    // Date field is required when car pickup is yes
    this.form.get('carPickUp').valueChanges.pipe(
      takeWhile(() => this.isComponentActive),
    ).subscribe((isCarPickup) => {
      this.form.get('pickUpDate').setValidators(!!isCarPickup ? [Validators.required] : null);
      this.form.get('pickUpDate').setValue('');
      this.form.get('pickUpServiceNote').setValue('');
      this.form.get('isSameAddress').setValue(true);
      this.form.get('pickUpDate').updateValueAndValidity();
    });
    // Address field is required when same address is no
    this.form.get('isSameAddress').valueChanges.pipe(
      takeWhile(() => this.isComponentActive),
    ).subscribe((isSameAddress) => {
      this.form.get('carPickUpAddress').setValidators(!!!isSameAddress ? [Validators.required] : null);
      this.form.get('carPickUpAddress').setValue('');
      this.form.get('carPickUpAddress').updateValueAndValidity();
    });
  }

  private calculatePickUpDate(date: Date, time: string): Date {
    const timeOffSet = Math.floor(new Date().getTimezoneOffset() / 60);
    const returnDate = new Date(date);
    if (time) {
      const timeParts = time.split(':');
      returnDate.setHours(returnDate.getHours() + +timeParts[0] + timeOffSet, returnDate.getMinutes() + +timeParts[1]);
      return returnDate;
    }
    return returnDate;
  }

  public onSubmit(): void {
    this.error = null;
    if (this.form.valid) {
      this.submitStatus = 'pending';
      const orderMaintenanceForm = Object.assign({}, this.form.value) as ContractOrderMaintenance;
      delete orderMaintenanceForm.pickUpTime;
      if (orderMaintenanceForm.pickUpDate) {
        const date = this.form.get('pickUpDate').value;
        const time = this.form.get('pickUpTime').value;
        orderMaintenanceForm.pickUpDate = this.calculatePickUpDate(date, time);
      }
      this.helpService.orderMaintenance(orderMaintenanceForm).subscribe(() => {
        this.submitStatus = 'success';
      }, () => {
        this.submitStatus = 'initial';
        this.error = 500;
      })
    }
  }

  public onBackClick() {
    this.popinService.opened(false);
    this.router.navigate(['feed/help']);
  }

  ngOnDestroy() {
    this.isComponentActive = false;
  }
}
