import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HammerGesturesDirective } from './hammer-gestures.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    HammerGesturesDirective
  ],
  exports: [
    HammerGesturesDirective
  ]
})
export class HammerGesturesModule {
}
