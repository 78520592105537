import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../shared/authentication/auth.service';
import { AppConfigService } from '../../shared/config/app-config.service';
import { AuthConst } from '../../shared/authentication/auth.const';
import { TranslationService } from '../../shared/translation/translation.service';
import { CustomValidators } from 'app/shared/app-forms/validators/custom.validator';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent implements OnInit {

  public isDesktop = false;
  public form: FormGroup;
  public submitStatus = 'initial';
  public submitted = false;
  public errorMessage: string;

  constructor(private formBuilder: FormBuilder,
    public router: Router,
    private authService: AuthService,
    private appConfig: AppConfigService,
    private route: ActivatedRoute,
    private translationService: TranslationService) {

  }

  @HostListener('window:resize', ['$event'])
  onResize($event = null) {
    this.isDesktop = (window.innerWidth > this.appConfig.config.breakpoints.m);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      password: ['', CustomValidators.securePassword],
      confirmpassword: ['']
    });
    this.onResize();
    this.changeLanguage();
  }

  onSubmit() {
    const password = this.form.get('password').value;
    const confirmPassword = this.form.get('confirmpassword').value;
    if (this.form.valid && this.submitStatus !== 'pending' && (password === confirmPassword)) {
      this.submitStatus = 'pending';
      this.authService.endDriverSignOn(password).subscribe(
        result => {
          this.submitStatus = 'success';
          this.router.navigate(['/start/success']);
        },
        error => {
          this.submitStatus = 'initial';
          this.errorMessage = error.status && error.status === 400 ? 'global-generic_terms-incorrect_input' : 'global-generic_terms-generic_error';
        });
    }
  }

  private changeLanguage() {
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams['culture']) {
        this.translationService.language = queryParams['culture'];
      }
    });
  }
}
