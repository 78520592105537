﻿import { Component, Input, OnInit } from '@angular/core';
import { TranslationService } from '../../shared/translation/translation.service';

@Component({
  selector: 'app-help-case-item',
  templateUrl: './help-case-item.component.html',
  styleUrls: ['./help-case-item.component.scss']
})
export class HelpCaseItemComponent implements OnInit {

  @Input()
  public case: any;

  constructor(private translationService: TranslationService) {}

  ngOnInit() {
    if (this.translationService.language.toLowerCase() === 'en-us') {
      this.case.category = this.case.categoryEnglish;
      this.case.subject = this.case.subjectEnglish;
      this.case.subSubject = this.case.subSubjectEnglish;
    } else {
      this.case.category = this.case.categoryLocal;
      this.case.subject = this.case.subjectLocal;
      this.case.subSubject = this.case.subSubjectLocal;
    }
  }
}
