import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(values: any, attr: string, filter: any): any {
    if (!values) {
      return [];
    }
    if (filter === 'all') {
      return values;
    }
    return values.filter(value => {
        return value[attr] === filter;
      }
    );
  }

}
