import { ModuleWithProviders, NgModule } from '@angular/core';
import { AccordionModule } from './accordion/accordion.module';
import { AppFormsModule } from './app-forms/app-forms.module';
import { AppMenuModule } from './app-menu/app-menu.module';
import { AvailableSoonModule } from './available-soon/available-soon.module';
import { CountdownModule } from './countdown/countdown.module';
import { GmapModule } from './gmap/gmap.module';
import { HeaderCarsCarousselModule } from './header-cars-caroussel/header-cars-caroussel.module';
import { LoadingModule } from './loading/loading.module';
import { MaskModule } from './mask/mask.module';
import { ModalBoxModule } from './modal-box/modal-box.module';
import { ProgressbarSimpleModule } from './progressbar-simple/progressbar-simple.module';
import { RatingModule } from './rating/rating.module';
import { StickyHeaderModule } from './sticky-header/sticky-header.module';
import { SvgIconModule } from './svg-icon/svg-icon.module';
import { TextCarouselModule } from './text-carousel/text-carousel.module';
import { VerticalCarouselModule } from './vertical-carousel/vertical-carousel.module';
import { PipesModule } from './pipes/pipes.module';
import { AuthStorageService } from './storage/auth/auth-storage.service';
import { AppStorageService } from './storage/common/app-storage.service';
import { TranslationStorageService } from './storage/translation/translation-storage.service';
import { AuthService } from './authentication/auth.service';
import { HammerGesturesModule } from './directives/hammer-gestures/hammer-gestures.module';
import { RouterTransitionsModule } from './router-transitions/router-transitions.module';
import { ApiService } from './common/api.service';
import { ConfigurationService } from './config/configuration.service';
import { StorageModule } from './storage/storage.module';
import { SvgSpriteService } from './ui/svg-sprite.service';
import { MapService } from './map/map.service';
import { AppConfigService } from './config/app-config.service';
import { UserService } from 'app/shared/user/user.service';
import { PopinService } from './ui/popin.service';
import { StickyHeaderFormService } from './ui/sticky-header-form.service';
import { ModalService } from './ui/modal.service';
import { JwtHelper } from 'angular2-jwt';
import { OnErrorService } from './ui/on-error.service';
import { HostPageService } from './config/host-page.service';
import { RequestAccessService } from './authentication/sign-up.service';
import { ApiHelper } from './common/api.helper';
import { FeatureService } from './feature/feature.service';
import { EqualValidatorModule } from './directives/equal-validator/equal-validator.module';
import { RedirectSafari } from './config/redirect-safari';
import { AccessService } from './authentication/access.service';
import { ProspectAccessService } from './authentication/prospect-access.service';
import { WindowRef } from './helper/window.ref';
import { DocumentRef } from './helper/document.ref';
import { DateSortingService } from './helper/date-sorting.service';
import { SvgInlineModule } from './svg-inline/svg-inline.module';
import { CookiesModule } from './cookies/cookies.module';
import { ToastMessageModule } from './toast-message/toast-message.module';
import { UserManagementService } from './user/userManagement.service';
import { HttpService } from './helper/http.service';
import { AreFeaturesActivatedModule } from './directives/are-features-activated/are-features-activated.module';
import { PasswordVisiblityToggleModule } from './directives/password-visibility-toggle/password-visibility-toggle.module';
import { SnackbarModule } from './snackbar/snackbar.module';
import { SnackbarService } from './snackbar/snackbar.service';

@NgModule({
  imports: [
    StorageModule,
    AccordionModule,
    AppFormsModule,
    AppMenuModule,
    AvailableSoonModule,
    CountdownModule,
    GmapModule,
    HeaderCarsCarousselModule,
    LoadingModule,
    MaskModule,
    ModalBoxModule,
    ProgressbarSimpleModule,
    RatingModule,
    StickyHeaderModule,
    SvgIconModule,
    SvgInlineModule,
    TextCarouselModule,
    VerticalCarouselModule,
    PipesModule,
    HammerGesturesModule,
    RouterTransitionsModule,
    EqualValidatorModule,
    CookiesModule,
    ToastMessageModule,
    AreFeaturesActivatedModule,
    PasswordVisiblityToggleModule,
    SnackbarModule,
  ],
  exports: [
    AccordionModule,
    AppFormsModule,
    AppMenuModule,
    AvailableSoonModule,
    CountdownModule,
    GmapModule,
    HeaderCarsCarousselModule,
    LoadingModule,
    MaskModule,
    ModalBoxModule,
    ProgressbarSimpleModule,
    RatingModule,
    StickyHeaderModule,
    SvgIconModule,
    SvgInlineModule,
    TextCarouselModule,
    VerticalCarouselModule,
    PipesModule,
    HammerGesturesModule,
    RouterTransitionsModule,
    EqualValidatorModule,
    CookiesModule,
    ToastMessageModule,
    AreFeaturesActivatedModule,
    PasswordVisiblityToggleModule,
    SnackbarModule,
  ],
  providers: [
    ApiHelper,
    AppStorageService,
    TranslationStorageService,
    ApiService,
    ConfigurationService,
    SvgSpriteService,
    HostPageService,
    MapService,
    AppConfigService,
    OnErrorService,
    PopinService,
    JwtHelper,
    StickyHeaderFormService,
    RequestAccessService,
    RedirectSafari,
    WindowRef,
    DocumentRef,
    DateSortingService,
    UserManagementService,
    HttpService,
    SnackbarService,
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        AuthService,
        AccessService,
        ProspectAccessService,
        AuthStorageService,
        ModalService,
        UserService,
        FeatureService,
      ]
    };
  }
}

