import { Vehicle } from '../../driver/shared/models/vehicle.model';
import { Injectable } from '@angular/core';
import { HelpConst } from './help.const';
import { UserService } from '../../shared/user/user.service';
import { DriverService } from '../../driver/shared/driver.service';
import { Driver } from '../../driver/shared/models/driver.model';
import { ApiService } from '../../shared/common/api.service';
import { TranslationService } from '../../shared/translation/translation.service';
import { Observable } from 'rxjs';
import { typeSourceSpan } from '@angular/compiler';
import { ContactRequest } from './models/create-case-request.model';
import { DeclareDamageRequest } from './models/declare-damage-request.model';
import { SendMaintenanceRequest } from './models/maintenance-request.model';
import { ContractAskPermission, ContractOrderDamageRepair, ContractOrderMaintenance } from './models/help-driver-assistance.model';

@Injectable()
export class HelpService {

  private driver: Driver;
  private maintenanceRequestParameters: any;

  constructor(private api: ApiService,
    private userService: UserService,
    private driverService: DriverService,
    private translationService: TranslationService) {
  }

  public declareDamage(declareDamageParams: DeclareDamageRequest) {
    return this.api.post(HelpConst.api.base + HelpConst.api.endpoints.declareDamage, declareDamageParams)
      .map(response => {
        return response;
      });
  }

  public contactAld(contactAldParams: ContactRequest) {
    contactAldParams.culture = this.translationService.language;

    return this.api.post(HelpConst.api.base + HelpConst.api.endpoints.sendRequest, contactAldParams)
      .map(response => {
        return response;
      });
  }

  public sendMaintenanceRequest(maintenanceRequestParams: SendMaintenanceRequest): Observable<any> {
    maintenanceRequestParams.culture = this.translationService.language;

    return this.api.post(HelpConst.api.base + HelpConst.api.endpoints.maintenanceRequest, maintenanceRequestParams)
      .map(response => {
        return response;
      });

  }

  public createCaseRequest(newCaseParams: ContactRequest): Observable<any> {
    newCaseParams.culture = this.translationService.language;
    return this.api.post(HelpConst.api.base + HelpConst.api.endpoints.sendRequest, newCaseParams)
      .map(response => {
        return response;
      });
  }

  public getCategories() {
    const params = this._getQueryStringUrl({ subsidiaryCode: this.userService.getCountry() });
    return this.api.get(HelpConst.api.base + HelpConst.api.endpoints.getContactCategories + '?' + params);
  }

  public getCaseHistory() {
    return this.api.get(HelpConst.api.base + HelpConst.api.endpoints.getCaseHistory);
  }

  public setMaintenanceRequestParameters(param): void {
    this.maintenanceRequestParameters = param;
  }

  public getMaintenanceRequestParameters(): any {
    return this.maintenanceRequestParameters;
  }

  public getDefaultVehicle(): Vehicle {
    let defaultVehicle: Vehicle = null;
    this.driverService.driverStream.subscribe(driver => {
      this.driver = driver;
      defaultVehicle = driver.contracts[0].vehicle;
    });
    return defaultVehicle;
  }

  private _getQueryStringUrl(params: any): string {
    return Object.keys(params)
      .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join('&');
  }

  public askPermissionToTakeAbroad(askPermissionForm: ContractAskPermission) {
    const url = HelpConst.api.base + HelpConst.api.endpoints.sendAskPermissionForm;
    return this.api.post(url, askPermissionForm);
  }

  public orderDamageRepair(damageRepairForm: ContractOrderDamageRepair) {
    const url = HelpConst.api.base + HelpConst.api.endpoints.sendOrderDamageRepairRequest;
    return this.api.post(url, damageRepairForm);
  }

  public orderMaintenance(orderMaintenanceForm: ContractOrderMaintenance) {
    const url = HelpConst.api.base + HelpConst.api.endpoints.sendOrderMaintenanceRequest;
    return this.api.post(url, orderMaintenanceForm);
  }
}
