import { Directive, ElementRef, Input, AfterViewInit, Renderer2, OnChanges } from '@angular/core';

@Directive({
  selector: '[msgToast]'
})
export class ToastDirective implements AfterViewInit, OnChanges {

  @Input() position: string;
  @Input() theme: string;

  constructor(private elementRenderer: Renderer2,
              private elementRef: ElementRef) {
  }

  ngAfterViewInit() {
    this.elementRenderer.setAttribute(this.elementRef.nativeElement, this.position, null);
    this.elementRenderer.setAttribute(this.elementRef.nativeElement, this.theme, null);
  }


  ngOnChanges(changes) {
    if (changes.theme) {
      if (!changes.theme.firstChange) {
        this.elementRenderer.removeAttribute(this.elementRef.nativeElement, changes.theme.previousValue, null);
      }
        this.elementRenderer.setAttribute(this.elementRef.nativeElement, this.theme, null);
    }
  }
}
