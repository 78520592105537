﻿import { DriverService } from '../../../driver/shared/driver.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PopinService } from '../../ui/popin.service';
import { AuthService } from '../../authentication/auth.service';
import { FeatureService } from 'app/shared/feature/feature.service';
import { FeatureConst } from 'app/shared/feature/feature.consts';
import { UserManagementService } from 'app/shared/user/userManagement.service';
import { GtmService } from '../../gtm/gtm.service';
import { GTM_EVENTS } from '../../gtm/gtm-events.const';
import { CustomValidators } from 'app/shared/app-forms/validators/custom.validator';


@Component({
  selector: 'app-more-reset-password',
  templateUrl: './more-reset-password.component.html',
  styleUrls: ['./more-reset-password.component.scss']
})
export class MoreResetPasswordComponent implements OnInit, OnDestroy {

  // Form values & state
  public form: FormGroup;
  public formSubmitted = false;
  public submitStatus = 'initial';
  public error = null;
  private isManagerV2 = false;

  constructor(private driverService: DriverService,
    private formBuilder: FormBuilder,
    private router: Router,
    private popinService: PopinService,
    private authService: AuthService,
    private featureService: FeatureService,
    private gtmService: GtmService,
    private userManagementService: UserManagementService) {

    this.form = this.formBuilder.group({
      newPassword: ['', Validators.required, CustomValidators.securePassword],
      confirmPassword: ['', Validators.required],
      oldPassword: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.popinService.opened(true);

    this.featureService.isFeatureActivated(FeatureConst.features.ContinueAsFleetManagerV2).subscribe((isManagerV2) => {
      this.isManagerV2 = isManagerV2;
    });
  }

  onSubmit() {
    this.formSubmitted = true;
    this.error = null;

    if (this.form.valid) {

      this.submitStatus = 'pending';

      const payload = {
        currentPassword: this.form.get('oldPassword').value,
        newPassword: this.form.get('newPassword').value
      };

      if (this.isManagerV2) {
        this.userManagementService.changeCurrentUserPassword(payload.currentPassword, payload.newPassword).subscribe(
          () => {
            this.onUpdatePasswordSuccess();
          },
          (httpError) => {
            this.onUpdatePasswordFail(httpError.error);
          });
      } else {
        this.driverService.aldiUpdatePassword(payload).subscribe(
          response => {
              this.onUpdatePasswordSuccess();
          },
          error => {
            this.onUpdatePasswordFail(error);
          }
        );
      }
    }
  }

  private onUpdatePasswordSuccess() {
    this.gtmService.sendEvent(GTM_EVENTS.changePassword);
    this.submitStatus = 'success';
  }

  private onUpdatePasswordFail(error) {
    this.submitStatus = 'initial';
    if (error.status && error.status.toString().substring(0, 1) === '4') {
      this.error = 400;
    } else {
      this.error = 'global-generic_terms-generic_server_error';
    }
  }

  ngOnDestroy() {
    // Back navigator
    this.popinService.opened(false);
  }

  onBackClick(path) {
    this.popinService.opened(false);
    this.router.navigate([this.router.url.replace('reset-password', path)]);
  }


}
