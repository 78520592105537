import { Injectable } from '@angular/core';
import { AuthStorageService } from '../storage/auth/auth-storage.service';
import { JwtHelper } from 'angular2-jwt';
import { UserManagementConst } from './userManagement.const';
import { Observable } from 'rxjs';
import { HttpService } from '../helper/http.service';

@Injectable()
export class UserService {
  public userId = null;
  public culture = null;
  public roles: string[] = [];
  private user: any;
  private keyPrefix = 'http://www.aldautomotive.fr/claims/';

  constructor(private storage: AuthStorageService,
              private httpClient: HttpService,
              private jwtHelper: JwtHelper) {
  }

  public getActivedirectoryid() {
    return this.getValueByKey('userid');
  }

  public getCountry(): string {
    return this.getValueByKey('country');
  }

  public getClientId() {
    return this.getValueByKey('client');
  }

  public getRoles() {
    const roles = this.roles;
    return roles.map(role => role.toLowerCase());
  }

  public getTokenRole(): string {
    return this.getValueByKey('role', false);
  }

  private getValueByKey(key: string, prefix = true) {
    if (this.storage.auth) {
      this.user = this.getUserInfoFromJwt();
    }
    return this.user[(prefix ? this.keyPrefix : '') + key];
  }

  private getUserInfoFromJwt(): any {
    return this.jwtHelper.decodeToken(this.storage.auth['access_token']);
  }

  public getExternalAppToken(carPlate: string): Observable<string> {
    const url = `${UserManagementConst.api.endpoints.getExternalJwtToken}?carPlate=${carPlate}`;
    return this.httpClient.getString(url);
  }
}
