import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ModalContent } from '../modal-box/modal-content.model';
import { ModalInput } from '../modal-box/modal-input.model';

@Injectable()
export class ModalService {
  /**
   * Observable for the maintenance status
   */
  public openModalObservable: BehaviorSubject<ModalContent>;
  public toogleModalObservable: BehaviorSubject<boolean>;
  public modalActionObservable: BehaviorSubject<boolean>;
  public modalStatus = false;
  public modalCode = null;

  /**
   * Variables for modal box with input
   */
  public modalValue = [];
  public modalSteps = [];
  public modalNbSteps = 0;
  public currentModalStep = -1;

  private _modalContent = null;
  private _modalAction = null;

  constructor() {
    this.openModalObservable = new BehaviorSubject<ModalContent>(this._modalContent);
    this.modalActionObservable = new BehaviorSubject<boolean>(this._modalAction);
  }


  openModal(body: string,
            title: string = null,
            button1title: string = null,
            button2title: string = null,
            illustrationPath: string = null,
            input = null,
            displayNumber: Number = null) {
    this.openModalObservable.next(new ModalContent(body, title, button1title, button2title, illustrationPath, input, displayNumber));
  }

  public createMultiStepModal(steps: Array<ModalInput>, nbSteps: number = steps.length) {
    if (steps.length > 0) {
      this.modalSteps = steps;
      this.modalNbSteps = nbSteps;
      this.goToStep(0);
    }
  }

  public goToStep(index) {
    this.currentModalStep = index;
    const {body, title = null, button1title = null, button2title = null, illustrationPath = null, input = null} = this.modalSteps[index];
    this.openModal(body, title, button1title, button2title, illustrationPath, input);
  }

  public hasStep() {
    return this.modalSteps.length > 0;
  }

  public goToNextStep() {
    // End of the road
    if (!this.hasStep() || this.currentModalStep >= this.modalSteps.length - 1) {
      this.modalActionObservable.next(true);
      return true;
    } else {
      this.goToStep(this.currentModalStep + 1);
      return false;
    }
  }

  public goToPreviousStep() {
    if (this.currentModalStep > 0) {
      this.goToStep(this.currentModalStep - 1);
    } else {
      // Already in first step
    }
  }

  reset() {
    this.openModalObservable.next(null);
    this.modalCode = null;
  }

}
