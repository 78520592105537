
import {
  Component,
  Input,
  OnInit,
  OnDestroy
} from '@angular/core';
import { Document } from '../shared/models/document.model';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { BehaviorSubject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-documents-list',
  templateUrl: './documents-list.component.html',
  styleUrls: ['./documents-list.component.scss']
})
export class DocumentsListComponent implements OnInit, OnDestroy {

  @Input() title: string;
  @Input() documents: Document[];
  @Input() isDriverDocument = false;

  public gridCols$: BehaviorSubject<number> = new BehaviorSubject<number>(2);

  private isComponentActive = true;

  constructor(
    private mediaObserver: MediaObserver
  ) {
  }

  ngOnInit() {
    this.mediaObserver.media$.pipe(
      takeWhile(() => this.isComponentActive),
    ).subscribe((change: MediaChange) => {
      this.gridCols$.next(change.mqAlias === 'xs' ? 1 : 2);
    });
  }

  ngOnDestroy() {
    this.isComponentActive = false;
  }
}
