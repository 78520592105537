import * as _ from 'lodash';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppConfigService } from '../../../shared/config/app-config.service';


@Component({
  selector: 'app-numeric-keypad',
  templateUrl: './numeric-keypad.component.html',
  styleUrls: ['./numeric-keypad.component.scss']
})

export class NumericKeypadComponent implements OnInit {

  @Input()
  public enable: boolean;

  @Output()
  public change: EventEmitter<string> = new EventEmitter();

  @Output()
  public submit: EventEmitter<any> = new EventEmitter();

  /**
   * The available values
   */
  public keys = [
    {id: 1, value: '1'},
    {id: 2, value: '2'},
    {id: 3, value: '3'},
    {id: 4, value: '4'},
    {id: 5, value: '5'},
    {id: 6, value: '6'},
    {id: 7, value: '7'},
    {id: 8, value: '8'},
    {id: 9, value: '9'},
    {id: 0, value: '0'}
  ];

  /**
   * the reordered keys that are displayed
   */
  public orderedKeys: Object[];

  /**
   * Array of entered keystrokes
   * @type {Array}
   */
  public inputKeys = [];

  constructor(private appConfig: AppConfigService) {

  }

  public get keyString(): string {
    return this.inputKeys.join('');
  }

  ngOnInit() {
    // randomize keys
    this.orderedKeys = _.shuffle(this.keys.concat());
  }

  onClick($event) {
    $event.stopPropagation();
  }

  onKeyClick(keyId) {
    this.inputKeys.push(keyId);
    this.change.emit(this.keyString);
  }

  backSpace() {
    this.inputKeys.pop();
    this.change.emit(this.keyString);
  }

  onSubmit() {
    this.submit.emit();
  }

}
