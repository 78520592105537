import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SvgInlineService } from './svg-inline.service';

@Component({
  selector: 'app-svg-inline',
  templateUrl: './svg-inline.component.html'
})
export class SvgInlineComponent implements OnInit {

  public fileContent: SafeHtml;

  @Input() public src: string;

  constructor(private svgInlineService: SvgInlineService,
              private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.svgInlineService.getSvgByUrl(this.src).subscribe(svgContent => {
      if (svgContent) {
        this.fileContent = this.sanitizer.bypassSecurityTrustHtml(svgContent);
      }
    });
  }

}
