import { environment } from 'environments/environment';

export const AuthConst = {
  api: {
    base: environment.apigatewayUrl + '/apigateway/',
    endpoints: {
      forgotPwd: 'user/api/Users/StartForgotPassword',
      endForgotPassword: 'user/api/Users/EndForgotPassword',
      endDriverSignOn: 'user/api/Users/EndAldDriverSignOn',
      endCreateProspectPassword: 'UserManagement/api/Prospect/CreateAccount',
      prospectCheckToken: 'UserManagement/api/Prospect/CheckToken/',
      userCheckToken: 'UserManagement/api/User/CheckToken/',
    }
  },
  status: {
    LOGGED_IN: 'in',
    LOGGED_OUT: 'out',
    PENDING: 'pending'
  },
};
