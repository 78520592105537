﻿import { MyAldUiModule } from 'app/shared/ui/myald-ui/myald-ui.module';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { HeaderCarsCarousselComponent } from './header-cars-caroussel.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    TranslateModule,
    MyAldUiModule,
  ],
  declarations: [HeaderCarsCarousselComponent],
  exports: [HeaderCarsCarousselComponent]
})
export class HeaderCarsCarousselModule {
}
