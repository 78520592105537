import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppInsightsModule } from 'ng2-appinsights';
import { AppComponent } from './app.component';
import { StartModule } from './start/start.module';
import { AppRoutingModule } from './app-routing.module';
import { TranslationService } from './shared/translation/translation.service';
import { AppConfigService } from './shared/config/app-config.service';
import { RouterTransitionsModule } from './shared/router-transitions/router-transitions.module';
import { NavigationDirectionService } from './shared/router-transitions/navigation-direction.service';
import { SharedModule } from './shared/shared.module';
import { environment } from '../environments/environment';
import { MoreModule } from './more/more.module';
import { DocumentsModule } from './documents/documents.module';
import { ErrorsModule } from './shared/errors/errors.module';
import { CustomErrorHandler } from './shared/internals/CustomError.handler';
import { LoggerService } from './shared/helper/logger.service';
import { ConsoleLogger } from './shared/config/console-logger.service';
import { DriverModule } from './driver/driver.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaintenanceService } from './shared/config/maintenance.service';
import { FeedService } from './feed/shared/feed.service';
import { GtmModule } from './shared/gtm/gtm.module';
import { ContractStatusGuard } from './driver/shared/contract/guards/contract-status-guard.service';
import { ContractStatusExcludeGuard } from './driver/shared/contract/guards/contract-status-exclude-guard.service';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    SharedModule.forRoot(),
    AppInsightsModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: Loader,
        deps: [HttpClient]
      }
    }),
    GtmModule.forRoot(),
    RouterTransitionsModule,
    ErrorsModule,
    StartModule,
    DocumentsModule,
    MoreModule,
    DriverModule,
    BrowserAnimationsModule,
  ],
  declarations: [AppComponent],
  providers: [
    FeedService,
    TranslationService,
    AppConfigService,
    ContractStatusGuard,
    ContractStatusExcludeGuard,
    NavigationDirectionService,
    {provide: ErrorHandler, useClass: CustomErrorHandler},
    LoggerService,
    ConsoleLogger,
    MaintenanceService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function Loader(http: HttpClient) {
  const countrySelected = window.sessionStorage.getItem('country_selected');

  return new TranslateHttpLoader(
    http,
    environment.apigatewayUrl + environment.translateLoaderPrefix + countrySelected + '/',
    ''
  );
}

