import { environment } from 'environments/environment';

export const MaxMileage = 250000;

export const HelpConst = {
  api: {
    base: environment.apigatewayUrl + '/apigateway/Help/api/',
    endpoints: {
      declareDamage: '/Contact/DeclareDamage',
      sendRequest: 'Contact/Send',
      getContactCategories: 'ContactALD/GetCategoriesSubject',
      getCaseHistory: 'Case/My',
      maintenanceRequest: 'Maintenance/SendMaintenanceRequest',
      sendAskPermissionForm: 'Assistance/PermissionToGoAbroad',
      sendOrderDamageRepairRequest: 'Assistance/OrderDamageRepair',
      sendOrderMaintenanceRequest: 'Assistance/OrderMaintenance',
    }
  },
  maintenanceType: {
    ScheduledMaintenance : 83,
    UnScheduledMaintenance : 84
  }
};

export enum OrderMaintenanceChanges {
  other_reason = 'OtherReason',
  windscreen_damage = 'WindscreenDamage',
  tyres = 'Tyres',
  repairs = 'Repairs',
  technical_checkup = 'TechnicalCheckup',
}
