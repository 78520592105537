import { Injectable } from '@angular/core';
import { ConfigurationModel } from './configuration.model';
import { HttpClient } from '@angular/common/http';
import { LoggerService } from '../helper/logger.service';
import { BaseService } from '../helper/base.service';
import { environment } from '../../../environments/environment';
import { AppStorageService } from '../storage/common/app-storage.service';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class ConfigurationService extends BaseService {
  private AVAILABLE_CULTURES = 'AvailableCultures';

  constructor(loggerService: LoggerService, private http: HttpClient, private appStorageService: AppStorageService) {
    super(loggerService);
  }

  getConfItems(params: any): Observable<ConfigurationModel> {
    return this.http.get(environment.apigatewayUrl + '/apigateway/configuration/api/configuration/' + params.join('/'))
      .map(confItems => confItems as ConfigurationModel)
      .catch(error => throwError(error));
  }

  getAvailableLanguages(): Observable<any> {
    return this.getConfItems([this.appStorageService.countrySelected, this.AVAILABLE_CULTURES])
      .map((feedback: ConfigurationModel) => {
        const { value } = feedback;
        const { data } = JSON.parse(value);
        let languages = [];
        for (const item of data) {
          const [key] = Object.keys(item);
          languages = [
            ...languages, {
              codeLanguage: key,
              name: item[key]
            }
          ];
        }
        return languages;
      })
      .catch(error => throwError(error));
  }
}
