import { AppConfigService } from '../../config/app-config.service';
import { Address } from '../../gmap/address.model';
import { MapService } from '../../map/map.service';
import { NgModel } from '@angular/forms';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import Timer = NodeJS.Timer;
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-select-autocomplete-address',
  templateUrl: './select-autocomplete-address.component.html',
  styleUrls: ['./select-autocomplete-address.component.scss']
})
export class SelectAutocompleteAddressComponent implements OnInit, OnDestroy, AfterViewInit {

  public showList: boolean;
  public addressesFiltered: Array<string[]>;
  public address: Address;
  public addressLabel: string;

  @Input()
  public placeholder: string;

  @Input()
  public parentCss: string;

  @Input()
  public inputValue: string;

  @Output()
  public update = new EventEmitter();

  @ViewChild('inputAddress')
  public inputAddress;

  @ViewChild('ngModelAddress')
  public ngModelAddress: NgModel;

  public currentLabel: string;
  public currentIndex = -1;
  public inputActive: boolean;

  private parent: any;
  private subscriptionAddress: Subscription;
  private timer: Timer = null;

  constructor(private mapService: MapService,
              private appConfig: AppConfigService,
              private elementRef: ElementRef) {
  }

  ngOnInit() {
    if (this.inputValue) {
      this.addressLabel = this.inputValue;
    }
    if (this.parentCss) {
      this.parent = document.querySelector('.' + this.parentCss);
    }
  }

  ngAfterViewInit() {

    this.subscriptionAddress = this.ngModelAddress.valueChanges
      .pipe(
        filter(val => val && this.showList)
      )
      .debounceTime(300)
      .distinctUntilChanged()
      .switchMap(search => this.mapService.getAutocompleteAddress(search).toPromise())
      .subscribe(results => {
        if (results.length) {
          this.addressesFiltered = results;
        }
      });
  }

  ngOnDestroy() {
    if (this.subscriptionAddress) {
      this.subscriptionAddress.unsubscribe();
    }
  }

  onKeyDown(event) {

    let nbItems;
    switch (event.keyCode) {
      /* arrow top */
      case 40 :
        nbItems = this.addressesFiltered.length;
        this.currentIndex = (this.currentIndex < nbItems - 1) ? this.currentIndex + 1 : 0;
        break;
      /* arrow bottom */
      case 38 :
        nbItems = this.addressesFiltered.length;
        this.currentIndex = (this.currentIndex > 0) ? this.currentIndex - 1 : nbItems - 1;
        break;
      /* enter */
      case 13 :
        event.preventDefault();
        const currentIndex = (this.currentIndex >= 0) ? this.currentIndex : 0;

        if (this.showList && this.addressesFiltered && this.addressesFiltered.length > currentIndex) {
          this.updateAddress(this.addressesFiltered[currentIndex]);
          this.inputAddress.nativeElement.value = this.addressLabel;
        }

        break;

      default:
        this.toggleList();
        this.addressChanged(this.inputAddress.nativeElement.value);
    }
  }

  addressChanged(v) {
    this.update.emit(v);
  }

  toggleList() {

    this.clearTimer();

    this.showList = this.inputAddress.nativeElement.value.length >= 2;

  }

  hideListTimeOut(time = 200) {

    this.clearTimer();

    this.timer = setTimeout(() => {
      this.showList = false;
      this.timer = null;
      this.inputActive = false;

    }, time);

  }

  onFocusIn() {

    if (this.parent && this.appConfig.isMobile) {
      // timeout 500, pour attendre que le clavier soit totalement affiché
      const headerHeight = 60;
      setTimeout(() => {
        this.parent.scrollTop = this.elementRef.nativeElement.offsetTop - headerHeight;
      }, 500);
    }
  }

  clearTimer() {

    if (this.timer !== null) {
      clearTimeout(this.timer);
      this.timer = null;
    }

  }

  startInput() {

    this.clearTimer();
    this.currentIndex = -1;
    this.showList = false;
    this.inputActive = true;
  }

  clearInput(event) {

    event.preventDefault();
    // event.stopPropagation();

    this.address = <Address>{};
    this.addressLabel = '';
    this.inputAddress.nativeElement.focus();

    this.startInput();

    this.addressChanged('');
  }

  resetInput() {
    this.address = <Address>{};
    this.addressLabel = '';
  }

  updateAddress(obj) {
    this.clearTimer();

    this.showList = false;
    this.address = obj;
    this.addressLabel = this.buildAddressLabel();
    this.update.emit(this.addressLabel);

    return false;
  }

  buildAddressLabel(): string {

    let str = '';

    if (this.address) {
      if (this.address.label) {
        str = this.address.label;
      }
      if (this.address.label_2) {
        str += ', ' + this.address.label_2;
      }
    }

    return str;
  }

}
