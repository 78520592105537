import { AbstractControl } from '@angular/forms';


export class EmailValidator {

  static simple = function (control: AbstractControl) {
    return /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+/i.test(control.value) ? null : {email: 'global-generic_terms-error_invalid_email'};
  };

}
