import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { DocumentRef } from '../../helper/document.ref';

@Directive({
  selector: '[appClickOutside]',
})
export class ClickOutsideDirective implements OnInit {
  @Input() exclude;
  @Output() clickOut = new EventEmitter();
  private excludedNodes: HTMLElement[];

  constructor(private elementRef: ElementRef, private document: DocumentRef) {}

  ngOnInit(): void {
    this.setExcludedNodes();
  }

  @HostListener('document:click', ['$event.target'])
  public onClickDocument(targetElement): void {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside && !this.clickOnExclude(targetElement)) {
      this.clickOut.emit();
    }
  }

  private setExcludedNodes(): void {
    if (this.exclude) {
      try {
        const nodes = Array.from(this.document.nativeDocument.querySelectorAll(this.exclude)) as HTMLElement[];
        if (nodes) {
          this.excludedNodes = nodes;
        }
      } catch (err) {
        throw new Error(err);
      }
    }
  }

  private clickOnExclude(target): boolean {
    if (!this.excludedNodes) {
      return false;
    }
    return this.excludedNodes.some((excludedNode) => {
      return excludedNode.contains(target);
    });
  }
}
