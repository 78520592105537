import { ApplicationRef, Component, HostBinding } from '@angular/core';
import { NavigationDirectionService } from '../../shared/router-transitions/navigation-direction.service';
import { Subscription } from 'rxjs';
import { generateTransitions } from '../../shared/router-transitions/router-transitions.animations';

@Component({
  selector: 'app-role-dispatch',
  templateUrl: './role-dispatch.component.html',
  styleUrls: ['./role-dispatch.component.scss'],
  animations: [
    generateTransitions()
  ]
})
export class RoleDispatchComponent {

  @HostBinding('@routeAnim')
  public routeAnimState: string;
  private navDirectionChangeSubscription: Subscription;

  constructor(private navigationDirection: NavigationDirectionService,
              private appRef: ApplicationRef) {

    this.routeAnimState = this.navigationDirection.direction;
    this.navDirectionChangeSubscription = navigationDirection.changeObservable.subscribe(direction => {
      this.routeAnimState = direction;
      this.appRef.tick();
    });

  }

}
