import { AfterViewInit, Component, ElementRef } from '@angular/core';

@Component({
  selector: 'app-text-carousel',
  templateUrl: './text-carousel.component.html',
  styleUrls: ['./text-carousel.component.scss']
})
export class TextCarouselComponent implements AfterViewInit {

  public currentIndex = 0;
  public arrayItems = [];
  private numItems = 0;
  private itemsContainer: any;
  private loop: any;
  private loopInterval = 3000;

  constructor(private element: ElementRef) {
  }

  ngAfterViewInit() {

    // Use the setTimeout to prevent ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {

      this.numItems = this.element.nativeElement.querySelectorAll('.item').length;

      // scroll smoothly to open element
      this.arrayItems = this.element.nativeElement.querySelectorAll('.item');

      this.itemsContainer = this.element.nativeElement.querySelector('.items-container');

    }, 0);

    this.runLoop();
  }

  public runLoop() {
    const that = this;

    this.loop = window.setInterval(function () {
      that.currentIndex = that.currentIndex + 1;

      if (that.currentIndex >= that.numItems) {
        that.currentIndex = 0;
      }

    }, this.loopInterval);

  }

  public resetLoop() {
    window.clearInterval(this.loop);
    this.runLoop();
  }

  public nextItem() {
    this.resetLoop();
    this.currentIndex = (this.currentIndex < this.numItems - 1) ? this.currentIndex + 1 : this.currentIndex;
  }

  public prevItem() {
    this.resetLoop();

    this.currentIndex = (this.currentIndex > 0) ? this.currentIndex - 1 : this.currentIndex;
  }

  public clickChip(event) {
    this.resetLoop();

    this.currentIndex = event;
  }


}
