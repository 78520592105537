﻿import { TranslationService } from '../translation/translation.service';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { LANGUAGES_SETTINGS } from '../translation/languages-settings';
import { AppStorageService } from '../storage/common/app-storage.service';

/**
 *
 * Pipe used to format relative days with more or less precision depending how long ago they are to now
 *
 */
@Pipe({
  name: 'relativeDate'
})
export class RelativeDatePipe implements PipeTransform {

  constructor(private translationService: TranslationService,
              private storageService: AppStorageService) {

  }

  transform(value: any, format?: string): any {
    let formatted;

    const input = moment.utc(value).local();
    const diffDays = input.diff(moment(), 'days');

    const userCulture = LANGUAGES_SETTINGS[this.storageService.countrySelected].cultures[this.translationService.language];

    if (format && userCulture[format]) {
      formatted = input.locale(this.translationService.language).format(userCulture[format]);
    } else if (format && format !== 'long' && format !== 'medium' && format !== 'short') {
      formatted = input.format(format);
    } else if (diffDays > 364 || diffDays < 0 || format === 'long') {
      // display full date
      formatted = input.locale(this.translationService.language).format(userCulture.dM + ' Y');
    } else if (diffDays > 7 || format === 'medium') {
      // display full date
      formatted = input.locale(this.translationService.language).format(userCulture.dM);
    } else if (diffDays > 0 || format === 'short') {
      // diplay day of the week
      formatted = input.locale(this.translationService.language).format('ddd');
    } else if (diffDays === 0) {
      // display time
      formatted = input.format('HH:mm');
    }
    return formatted;
  }
}
