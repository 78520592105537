import { Component, OnInit, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import 'rxjs/add/observable/timer';

@Component({
   selector: 'app-toast-message',
   templateUrl: './toast-message.component.html',
   styleUrls: [ './toast-message.component.scss' ]
})
export class ToastMessageComponent implements OnInit, OnChanges {
  @Input() position: string;
  @Input() message: string;
  @Input() theme: string;
  @Input() show = false;
  @Input() duration?: number;

  private subscription: Subscription;
  private timer: Observable<any>;

  @Output() onHide = new EventEmitter();

  constructor() {}

  ngOnInit() {
    if (!this.duration) {
      this.duration = 8000;
    }
  }

  ngOnChanges(changes) {
    if (changes.show) {
      this.setTimer();
    }
  }

  public setTimer() {
    if (this.show) {
      setTimeout(() => {
        this.show = false;
        this.onHide.emit();
      }, this.duration);
    } else {
      this.onHide.emit();
    }
  }

  closeToast() {
    this.show = false;
    this.onHide.emit();
  }
}
