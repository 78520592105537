﻿import { AuthStorageService } from '../../shared/storage/auth/auth-storage.service';
import { ApplicationRef, Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { NavigationDirectionService } from '../../shared/router-transitions/navigation-direction.service';
import { generateTransitions } from '../../shared/router-transitions/router-transitions.animations';
import { AuthService } from '../../shared/authentication/auth.service';
import { AppConfigService } from '../../shared/config/app-config.service';
import { environment } from '../../../environments/environment';
import { LoggerService } from '../../shared/helper/logger.service';
import { Subscription } from 'rxjs';
import { WindowRef } from '../../shared/helper/window.ref';
import { filter } from 'rxjs/operators';
import { GtmService } from 'app/shared/gtm/gtm.service';
import { GTM_EVENTS } from '../../shared/gtm/gtm-events.const';
import { UserService } from '../../shared/user/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    generateTransitions()
  ]
})
export class LoginComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  @ViewChild(FormGroupDirective)
  public formDirective: FormGroupDirective;
  public formSubmitted = false;
  public isLoading: boolean;
  @HostBinding('@routeAnim')
  public routeAnimState: string;

  public adfsIframe;
  public loginErrorMessage: string;
  public expiredPasswordError = false;
  public useNumericKeypad = false;
  public readonly loginErrors = {
    invalidCreds: 'Wrong login or password',
    getJwtFailed: 'Unable to get jwt token',
    getUserInfoFailed: 'Could not retrieve user\'s infos'
  };
  private navDirectionChangeSubscription: Subscription;
  private returnUrl = '';

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public appConfig: AppConfigService,
    private authService: AuthService,
    private userService: UserService,
    private navigationDirection: NavigationDirectionService,
    private appRef: ApplicationRef,
    private storage: AuthStorageService,
    private loggerService: LoggerService,
    private activatedRoute: ActivatedRoute,
    private windowRef: WindowRef,
    private gtmService: GtmService) {

    this.routeAnimState = this.navigationDirection.direction;
    this.navDirectionChangeSubscription = navigationDirection.changeObservable.subscribe(direction => {
      this.routeAnimState = direction;
      this.appRef.tick();
    });

  }

  ngOnInit() {
    let formReceived = false;

    this.windowRef.nativeWindow.addEventListener('message', (e: any) => {
      const { data: eventData } = e;

      if (eventData.passwordExpired) {
        this.expiredPasswordError = true;
        this.adfsIframe.src = environment.adfsUrl;
        formReceived = false;
        this.isLoading = false;
      } else if (eventData.waitingForLogin) {
        if (formReceived) {
          this.loginFail(this.loginErrors.invalidCreds);
        }
        this.isLoading = false;
        formReceived = true;
      } else if (eventData.isLogged) {
        this.loginSuccess(eventData.isLogged);
      }
    }, false);

    const userName = this.storage.userName ? this.storage.userName : '';

    this.form = this.formBuilder.group({
      userid: [userName, Validators.required],
      password: ['', Validators.required]
    });

    this.adfsIframe = document.getElementById('adfs_ifm') as HTMLIFrameElement;
    this.adfsIframe.src = environment.adfsUrl;

    this.activatedRoute.queryParams
      .pipe(
        filter(params => params.returnUrl)
      )
      .subscribe(params => {
        this.returnUrl = params.returnUrl;
      });
  }

  ngOnDestroy() {
    this.navDirectionChangeSubscription.unsubscribe();
  }

  public onControlFocus() {
    this.loginErrorMessage = null;
  }

  public onSubmit() {
    this.expiredPasswordError = false;
    this.formSubmitted = true;

    if (this.form.valid) {
      const login = this.form.get('userid').value.trim();
      const password = this.form.get('password').value;

      if (!this.isEmail(login)) {
        this.loginErrorMessage = 'global-generic_terms-error_email';
        this.isLoading = false;
        return;
      } else {
        this.loginErrorMessage = '';
      }
      this.isLoading = true;
      this.adfsIframe.contentWindow.postMessage({ login, password, rememberme: true }, '*');
    }
  }

  public onBackClick() {
    this.navigationDirection.direction = 'backward';
    this.router.navigate(['/start/user-dispatch']);
  }

  public loginSuccess(tokens) {
    const tokensObj = JSON.parse(tokens);
    this.navigationDirection.direction = 'forward';

    if (!this.authService.redirectIfWrongCountry(tokensObj)) {
      this.authService.setAuthStorage(tokensObj);
      this.onLoginSuccessNavigation();
    }
  }

  private onLoginSuccessNavigation() {
    if (this.returnUrl !== '') {
      const currentLocation = this.windowRef.nativeWindow.location;
      this.windowRef.nativeWindow.location = currentLocation.protocol + '//' + currentLocation.host + this.returnUrl;
    } else {
      const redirectPath = this.appConfig.isMobile ? '/start/allow-notification' : '/feed';
      this.router.navigate([redirectPath]).then(() => {
        this.gtmService.sendEvent(GTM_EVENTS.loginSuccess, this.userService.getTokenRole(), this.userService.getCountry().toUpperCase());
      })
    }
  }

  private loginFail(explicitError: string, loginErrorMessage = 'start-login_page-login_error') {
    this.loginErrorMessage = loginErrorMessage;
    this.isLoading = false;

    if (explicitError === this.loginErrors.invalidCreds) {
    } else {
      this.loggerService.critical(explicitError);
    }
  }

  private isEmail(login: string): boolean {
    // allow * in the dev environments because of anonymization
    const regexpProd = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
    const regexpDev = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9\*](?:[a-zA-Z0-9\-\*](?!\.)){0,61}[a-zA-Z0-9\*]?\.)+[a-zA-Z0-9\*](?:[a-zA-Z0-9\-\*](?!$)){0,61}[a-zA-Z0-9\*]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
    const regex = environment.envName === 'PRD' ? regexpProd : regexpDev;
    return regex.test(login);
  }
}
