import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material';
import { SnackbarComponent } from './snackbar.component';

@Injectable()
export class SnackbarService {
  public readonly infoStyleClass = 'app-snackbar-info';
  public readonly successStyleClass = 'app-snackbar-success';
  public readonly warningStyleClass = 'app-snackbar-warning';
  public readonly errorStyleClass = 'app-snackbar-error';

  constructor(private matSnackBar: MatSnackBar) {
  }

  public info(message: string, content: string[] = null, duration = null): MatSnackBarRef<SnackbarComponent> {
    return this.snackbarOpen(this.infoStyleClass, message, content, duration, false);
  }

  public loading(message: string, content: string[] = null, duration = null): MatSnackBarRef<SnackbarComponent> {
    const snackbarDuration = duration !== null ? duration : 360000;
    return this.snackbarOpen(this.infoStyleClass, message, content, snackbarDuration, true);
  }

  public success(message: string, content: string[] = null, duration = null): MatSnackBarRef<SnackbarComponent> {
    return this.snackbarOpen(this.successStyleClass, message, content, duration, false);
  }

  public warning(message: string, content: string[] = null, duration = null): MatSnackBarRef<SnackbarComponent> {
    return this.snackbarOpen(this.warningStyleClass, message, content, duration, false);
  }

  public error(message: string, content: string[] = null, duration = null): MatSnackBarRef<SnackbarComponent> {
    const snackbarDuration = duration !== null ? duration : 360000;
    return this.snackbarOpen(this.errorStyleClass, message, content, snackbarDuration, false);
  }

  public clear(): void {
    this.matSnackBar.dismiss();
  }

  private snackbarOpen(style: string, message: string, content: string[], duration: number, loading: boolean) {
    const configuration: MatSnackBarConfig = {
      duration: duration === null ? 5000 : duration,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['app-snackbar', style],
      data: {
        message,
        content,
        loading,
      },
    };
    return this.matSnackBar.openFromComponent(SnackbarComponent, configuration);
  }
}
