import { ApplicationRef, Component, HostBinding, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationDirectionService } from '../../shared/router-transitions/navigation-direction.service';
import { generateTransitions } from '../../shared/router-transitions/router-transitions.animations';
import { ConfigurationService } from '../../shared/config/configuration.service';
import { ConfigurationModel } from '../../shared/config/configuration.model';
import { AppStorageService } from '../../shared/storage/common/app-storage.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-assistance',
  templateUrl: './assistance.component.html',
  styleUrls: ['./assistance.component.scss'],
  animations: [
    generateTransitions()
  ]
})
export class AssistanceComponent implements OnDestroy {

  @HostBinding('@routeAnim')
  public routeAnimState: string;
  public phoneNumber: string;
  private navDirectionChangeSubscription: Subscription;

  constructor(private router: Router,
              private navigationDirection: NavigationDirectionService,
              private appRef: ApplicationRef,
              private configurationService: ConfigurationService,
              private appStorageService: AppStorageService) {
    this.configurationService.getConfItems([this.appStorageService.countrySelected, 'AssistancePhoneNumber'])
      .subscribe((feedback: ConfigurationModel) => {
        this.phoneNumber = feedback.value;
      }, e => {
        this.phoneNumber = 'error occured';
      });

    // set up observer for navigation
    this.routeAnimState = this.navigationDirection.direction;
    this.navDirectionChangeSubscription = navigationDirection.changeObservable.subscribe(direction => {
      this.routeAnimState = direction;
      this.appRef.tick();
    });
  }

  ngOnDestroy() {
    this.navDirectionChangeSubscription.unsubscribe();
  }

  onBack() {
    this.navigationDirection.direction = 'backward';
    this.router.navigate(['/start/user-dispatch']);
  }

}
