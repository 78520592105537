import { ApplicationRef, Component, HostBinding, OnDestroy } from '@angular/core';
import { NavigationDirectionService } from '../../shared/router-transitions/navigation-direction.service';
import { generateTransitions } from '../../shared/router-transitions/router-transitions.animations';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-dispatch',
  templateUrl: './user-dispatch.component.html',
  styleUrls: ['./user-dispatch.component.scss'],
  animations: [
    generateTransitions()
  ]
})
export class UserDispatchComponent implements OnDestroy {

  @HostBinding('@routeAnim')
  public routeAnimState: string;
  private navDirectionChangeSubscription: Subscription;

  constructor(
    private navigationDirection: NavigationDirectionService,
    private appRef: ApplicationRef) {
    this.routeAnimState = this.navigationDirection.direction;
    this.navDirectionChangeSubscription = navigationDirection.changeObservable.subscribe(direction => {
      this.routeAnimState = direction;
      this.appRef.tick();
    });
  }

  ngOnDestroy() {
    this.navDirectionChangeSubscription.unsubscribe();
  }

}
