import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

  transform(datas, condensed = false): any {
    let address = datas.addressLine1 || datas.address;
    if (!address) {
      return 'missing data';
    }
    if (!condensed) {
      if (datas.addressLine2 && datas.addressLine2 !== '') {
        address += '<br />' + datas.addressLine2;
      }
      if (datas.addressLine3 && datas.addressLine3 !== '') {
        address += '<br />' + datas.addressLine3;
      }
    }
    if (datas.postalCode && datas.postalCode !== '') {
      address += '<br />' + datas.postalCode;
    }
    if (datas.city && datas.city !== '') {
      address += ' ' + datas.city;
    }

    return address;
  }

}
