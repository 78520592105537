import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class SvgSpriteService {

  constructor(private http: HttpClient) {
  }

  public load(spriteUrl: string, name = 'svg-sprite') {

    // TODO cache sprite in sessionStorage
    return this.http.get(spriteUrl, { responseType: 'text' }).map((responseText) => {

      // Create shell div to inject SVG text into, and recover it as a a proper element.
      const shellDiv = document.createElement('div');
      shellDiv.innerHTML = responseText;
      const svgEl = shellDiv.firstElementChild;

      // inset element at the start of body (alway the start of body, otherwise iOS won't use it)
      document.body.insertBefore(svgEl, document.body.firstChild);

      // Return native SVG element
      return svgEl;

    }).subscribe();


  }

}
