import { InputChoice } from 'app/shared/models/input-choice.model';

export class FleetManagerLight {
    id: string;
    name: string;
    email: string;

  static toInputChoice(fleetManagers: FleetManagerLight[]): InputChoice[] {
    if (!fleetManagers) {
      return [];
    }
    return fleetManagers.map((fleet) => {
      return {
        label: fleet.name,
        value: fleet.id,
      };
    });
  }
}
