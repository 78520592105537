import { Component, forwardRef, Host, Input, Optional } from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NG_VALUE_ACCESSOR, NgForm, NgModel } from '@angular/forms';

@Component({
  selector: 'app-license-input',
  templateUrl: './license-input.component.html',
  styleUrls: ['./license-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LicenseInputComponent),
      multi: true
    }
  ]
})
export class LicenseInputComponent implements ControlValueAccessor {

  @Input()
  public placehodler = '';

  @Input()
  public label: string;

  public model: NgModel;

  private onChangeCallback = (_: any) => {
  }

  private onTouchedCallback = (_?: any) => {
  }

  constructor(@Host() @Optional() private ngForm: NgForm, // get form from parent component
              @Host() @Optional() private formGroupDir: FormGroupDirective // get form from parent component
  ) {

  }

  get form() {
    return this.ngForm || this.formGroupDir;
  }

  updateValue(value: any) {
    this.model = value;
    this.onChangeCallback(this.model);
  }

  onBlur($event) {
    this.onTouchedCallback();
  }

  writeValue(obj: any): void {
    this.model = obj.toString();
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }


}
