import { environment } from 'environments/environment';

export const FeatureConst = {
  features: {
    GloveBox: 'spec_isGloveBoxActivated',
    DeclareDamage: 'spec_isDeclareDamageActivated',
    OnRoadAssistance: 'spec_isOnRoadAssistanceActivated',
    Euromaster: 'spec_isEuroMasterLinkActivated',
    TrafficFinesV1: 'spec_isTrafficFinesActivated',
    TrafficFinesV2: 'spec_isTrafficFinesV2Activated',
    AldQuote: 'spec_isQuoterActivated',
    CarSelector: 'spec_isCarSelectorActivated',
    SalesForce: 'spec_salesForceActivated',
    SmartCare: 'spec_isSmartCareActivated',
    UserNameReadOnly: 'spec_isUserNameReadOnly',
    MultiReturnDocument: 'spec_isMultiReturnDocumentActivated',
    MaintenanceRequest: 'spec_isHelpMaintenanceRequestActivated',
    AssistanceThirdNumber: 'spec_isAssistanceThirdNumberActivated',
    ContinueAsFleetManagerV2: 'spec_isManagerActivated',
    MaintenanceHistory: 'spec_isMaintenanceHistoryActivated',
    CanEditDriverProfile: 'spec_canEditDriverProfile',
    ShowCarReturnFirstStep: 'spec_showCarReturnFirstStep',
    sharedDocuments: 'spec_isSharedDocumentsActivated',
    AssistanceForDriver: 'spec_isDriverAssistanceOnDriverAppActivated',
    FuelCard: 'spec_isFuelCardActivated',
    aldNetReportsFromIframe: 'spec_isALDNetFromIframe',
  },
  api: {
    availableFeatures: environment.apigatewayUrl + '/apigateway/Configuration/api/Feature/',
    anonymousAvailableFeatures: environment.apigatewayUrl + '/apigateway/Configuration/api/Feature/'
  },
  routes: {
    'help-damage-declaration': 'spec_isDeclareDamageActivated',
    'help-case-request': 'spec_salesForceActivated',
    'help-follow-requests': 'spec_salesForceActivated',
    'help-contact-us': '!spec_salesForceActivated',
    'help-maintenance-request': 'spec_isHelpMaintenanceRequestActivated',
    'smart-care': 'spec_isSmartCareActivated',
    'pos': '!spec_isSmartCareActivated',
    'maintenance-history': 'spec_isMaintenanceHistoryActivated',
    'edit': 'spec_isSharedDocumentsActivated',
    'help-order-damage-repair': 'spec_isDriverAssistanceOnDriverAppActivated',
    'help-order-maintenance': 'spec_isDriverAssistanceOnDriverAppActivated',
    'help-take-abroad': 'spec_isDriverAssistanceOnDriverAppActivated',
    'fuel-history': 'spec_isFuelCardActivated',
    'quotation': 'spec_isQuoterActivated',
  }
};
