import { animate, style, transition, trigger, state } from '@angular/animations';

export let toggleAnimation = trigger('toggle', [
    state('shown', style({ transform: 'translate3d(0,0,0)' })),
    transition('hidden => *', [
      animate('350ms cubic-bezier(0.5, 0.130, 0.5, 0.950)', style({ transform: 'translate3d(0,0,0)' }))
    ]),
    transition('* => shown', [
      animate('350ms cubic-bezier(0.5, 0.130, 0.5, 0.950)', style({ transform: 'translate3d(0,0,0)' }))
    ]),
    transition('shown => hidden', [
      animate('350ms cubic-bezier(0.5, 0.130, 0.5, 0.950)', style({ transform: 'translateX(100%)' }))
    ]),
])
