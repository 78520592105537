import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class OnErrorService {
  /**
   * Observable for the maintenance status
   */
  public maintenanceStatusObservable: BehaviorSubject<boolean>;
  private _maintenanceStatus = false;

  constructor() {
    this.maintenanceStatusObservable = new BehaviorSubject<boolean>(this._maintenanceStatus);
  }

  updateMaintenanceStatus(status: boolean) {
    this._maintenanceStatus = status;
    this.maintenanceStatusObservable.next(this._maintenanceStatus);
  }

}
