import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PopinService } from '../../shared/ui/popin.service';
import { DriverService } from 'app/driver/shared/driver.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { map, takeWhile, filter, tap, skip } from 'rxjs/operators';
import { Document } from '../shared/models/document.model';
import { InputChoice } from 'app/shared/models/input-choice.model';
import { FleetManagerLight } from '../shared/models/fleet-manager-light.model';
import { DocumentSharedWithUsersModelLight } from '../shared/models/document-shared-with-users-light.model';
import { DocumentsEffects } from '../shared/documents.effects';
import { EditDocumentDto } from '../shared/models/edit-document-dto.models';
import { TranslationService } from 'app/shared/translation/translation.service';
import { Driver } from 'app/driver/shared/models/driver.model';
import { DocumentsCategory } from '../shared/enums/documents-category';
import { APP_ROUTES } from 'app/app.routes';

@Component({
  selector: 'app-edit-document',
  templateUrl: './edit-document.component.html',
  styleUrls: ['./edit-document.component.scss']
})
export class EditDocumentComponent implements OnInit, OnDestroy {
    public isLoading: boolean;
    public form: FormGroup;
    public document: Document;
    public availableFleetManagers$: Observable<InputChoice[]> = new Observable();
    private driver: Driver;
    private isComponentActive = true;
    public isDocumentLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);

    constructor(private formBuilder: FormBuilder,
      public popinService: PopinService,
      private driverService: DriverService,
      private documentEffects: DocumentsEffects,
      private translationService: TranslationService,
      private router: Router,
      private activatedRoute: ActivatedRoute, ) {
    }

    ngOnInit() {
      this.form = this.formBuilder.group({
        title: ['', Validators.required],
        comment: new FormControl('', []),
        fleetManagers: new FormControl([], []),
        legal: new FormControl(false, []),
      });

      this.form.get('fleetManagers').valueChanges.pipe(
        skip(1),
        takeWhile(() => this.isComponentActive),
      ).subscribe((data) => {
        data.length ? this.form.get('legal').setValidators([Validators.requiredTrue])
          : this.form.get('legal').setValidators([]);
          this.form.get('legal').updateValueAndValidity();

          this.form.get('fleetManagers').markAsDirty();
      });

      combineLatest([
        this.documentEffects.documents$.pipe(
          takeWhile(() => this.isComponentActive),
          filter(d => !!d),
          tap((documents: Document[]) => {
            this.document = documents.find(d => d.id === this.activatedRoute.snapshot.paramMap.get('id'));
            if (this.document.category.name !== DocumentsCategory.PERSONAL_DOCUMENT) {
              this.router.navigate([APP_ROUTES.DOCUMENTS]);
            } else {
              const documentUserIDs = this.document.users.map(u => u.userId);
              this.updateView();
              this.availableFleetManagers$ = this.driverService.getFleetManagers().pipe(
                map(fleetsManagers => FleetManagerLight.toInputChoice(fleetsManagers.filter((fleet: FleetManagerLight) => !documentUserIDs.includes(fleet.id)))
                )
              );
              this.isDocumentLoading$.next(false);
            }
          }
          ),
        ),
        this.driverService.driverStream.pipe(
          tap((driver: Driver) => this.driver = driver)
        ),
      ]).subscribe();
      this.popinService.opened(true);
    }

    private updateView(): void {
      this.form.get('title').setValue(this.document.title);
      this.form.get('comment').setValue(this.document.comment);
      this.form.get('fleetManagers').setValue(FleetManagerLight.toInputChoice(DocumentSharedWithUsersModelLight.toFleetManager(this.document.users)));
    }

    public onSubmit() {
      this.isLoading = true;
      const editDocumentRequest: EditDocumentDto = this.constructDataToSend();
      const documentSharedModelWith = DocumentSharedWithUsersModelLight.toDocumentSharedWithUsersModel(this.form.get('fleetManagers').value);
      this.documentEffects.editDocumentAction(this.document.id, editDocumentRequest, documentSharedModelWith)
      .subscribe(() => {
        this.isLoading = false;
        this.popinService.opened(false);
      },
        () => {
          this.isLoading = false;
        });
    }

    ngOnDestroy() {
      this.popinService.opened(false);
      this.isComponentActive = false;
    }

    public onBackClick() {
      this.popinService.opened(false);
    }

    private constructDataToSend(): EditDocumentDto {
      const fleetManagers = this.form.get('fleetManagers').value;
      return {
        documentId: this.document.id,
        categoryId: this.document.category.id,
        title: this.form.get('title').value,
        comment: this.form.get('comment').value,
        culture : this.translationService.language,
        roles: fleetManagers.length ?  ['FleetManager'] :  [],
        companyIds: [this.driver.client.id],
        userIds: fleetManagers.length ? fleetManagers.map(fleetManager => fleetManager.value) : []
      } as EditDocumentDto;
    }
}
