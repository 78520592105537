import { APP_ROUTES } from '../../app.routes';

export enum PageSection {
  Homepage = 'Homepage',
  Maintenance = 'Maintenance',
  Car = 'Car',
  Infos = 'Car infos',
  Contract = 'Car contract details',
  Restitution = 'Car vehicle restitution',
  Mileage = 'Car mileage',
  GloveBox = 'Glove box',
  Services = 'Services',
  Pos = 'Point of services',
  More = 'More',
  Assistance = 'Assistance',
  PersonalInformations = 'Personal Informations',
  ErrorPage = 'Error Page',
  Others = 'Others',
}

const routeMapper = {
  [`${APP_ROUTES.FEED}$`] : PageSection.Homepage,
  [`${APP_ROUTES.CARS}/[^/]*$`] : PageSection.Car,
  [`${APP_ROUTES.CARS}/[^/]*/${APP_ROUTES.CARS_INFOS}$`] : PageSection.Infos,
  [`${APP_ROUTES.CARS}/[^/]*/${APP_ROUTES.CARS_CONTRACT}$`] : PageSection.Contract,
  [`${APP_ROUTES.CARS}/[^/]*/${APP_ROUTES.CARS_RESTITUTION}$`] : PageSection.Restitution,
  [`${APP_ROUTES.CARS}/[^/]*/${APP_ROUTES.CARS_MILEAGE}$`] : PageSection.Mileage,
  [`${APP_ROUTES.DOCUMENTS}$`] : PageSection.GloveBox,
  [`${APP_ROUTES.DOCUMENTS}/${APP_ROUTES.DOCUMENTS_ADD}$`] : PageSection.GloveBox,
  [`${APP_ROUTES.SERVICES}$`] : PageSection.Services,
  [`${APP_ROUTES.POS}$`] : PageSection.Pos,
  [`${APP_ROUTES.MORE}$`] : PageSection.More,
  [`${APP_ROUTES.NOT_FOUND}$`] : PageSection.ErrorPage,
  [`.*/${APP_ROUTES.ASSISTANCE}$`] : PageSection.Assistance,
  [`.*/${APP_ROUTES.PERSONAL_INFOS}$`] : PageSection.PersonalInformations,
};

export const getPageSectionByUrl = (url: string): PageSection  => {
  for (const route in routeMapper) {
    if (route && Object.prototype.hasOwnProperty.call(routeMapper, route)) {
      if (new RegExp(route).test(url)) {
        return routeMapper[route];
      }
    }
  }
  return PageSection.Others;
};
