import { Injectable } from '@angular/core';

@Injectable()
export class DateSortingService {

  public dateSortDesc(a, b) {
    const date1 = new Date(a.creationDate);
    const date2 = new Date(b.creationDate);
    if (date1 > date2) {
      return -1;
    }
    if (date1 < date2) {
      return 1;
    }
    return 0;
  }
}
