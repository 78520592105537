import { NavigationEnd, Router } from '@angular/router';
import { ChangeDetectorRef, Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[customRouterLinkActive]'
})
export class CustomRouterLinkActiveDirective implements OnInit, OnDestroy {
  @Input() customRouterLinkActive: string;
  @Input() customRouterLink: string;
  private subscription: Subscription;

  constructor(public el: ElementRef,
              public renderer: Renderer2,
              public route: Router,
              public cdr: ChangeDetectorRef) {
    this.subscription = this.route.events.subscribe(
      val => {
        if (val instanceof NavigationEnd) {
          this._updateLinks();
          this.cdr.detectChanges();

        }
      }
    );
  }

  ngOnInit() {
    this._updateLinks();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private _updateLinks() {
    if (this.route.url.indexOf(this.customRouterLink) === 0) {
      this.renderer.addClass(this.el.nativeElement.parentNode, this.customRouterLinkActive);
    } else {
      this.renderer.addClass(this.el.nativeElement.parentNode, this.customRouterLinkActive);
    }
  }

}
