import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationDirectionService } from '../../shared/router-transitions/navigation-direction.service';

@Component({
  selector: 'app-sign-up-denied',
  templateUrl: './sign-up-denied.component.html',
  styleUrls: ['./sign-up-denied.component.scss']
})
export class SignUpDeniedComponent {

  constructor(public router: Router,
              public navigationDirection: NavigationDirectionService) {
  }

  public callAssistance() {

  }
}
