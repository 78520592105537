import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(values, attr?: any): any[] {
    values.sort((a: any, b: any) => {
      let aValue = a;
      let bValue = b;

      if (attr) {
        aValue = a[attr];
        bValue = b[attr];
      }

      if (aValue < bValue) {
        return -1;
      } else if (aValue > bValue) {
        return 1;
      } else {
        return 0;
      }
    });
    return values;
  }

}
