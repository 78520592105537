import { NgModule } from '@angular/core';
import { SnackbarComponent } from './snackbar.component';
import { SnackbarService } from './snackbar.service';
import { MatSnackBarModule } from '@angular/material';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingModule } from '../loading/loading.module';
import { MyAldUiModule } from '../ui/myald-ui/myald-ui.module';

@NgModule({
  declarations: [
    SnackbarComponent,
  ],
  entryComponents: [
    SnackbarComponent,
  ],
  imports: [
    CommonModule,
    MatSnackBarModule,
    TranslateModule,
    LoadingModule,
    MyAldUiModule,
  ],
  exports: [
    SnackbarComponent,
  ],
  providers: [
    SnackbarService,
  ],
})
export class SnackbarModule {
}
