﻿import { ApplicationRef, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NavigationDirectionService } from '../../shared/router-transitions/navigation-direction.service';
import { generateTransitions } from '../../shared/router-transitions/router-transitions.animations';
import { AuthService } from '../../shared/authentication/auth.service';
import { Subscription } from 'rxjs';
import { FeatureService } from 'app/shared/feature/feature.service';
import { FeatureConst } from 'app/shared/feature/feature.consts';
import { UserManagementService } from 'app/shared/user/userManagement.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  animations: [
    generateTransitions()
  ]
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public isLoading: boolean;
  public submitted = false;
  public resetErrorMessage: string;

  @HostBinding('@routeAnim')
  public routeAnimState: string;
  private navDirectionChangeSubscription: Subscription;
  private isManagerV2 = false;

  constructor(private formBuilder: FormBuilder,
    public router: Router,
    private authService: AuthService,
    public navigationDirection: NavigationDirectionService,
    private appRef: ApplicationRef,
    private featureService: FeatureService,
    private userManagementService: UserManagementService) {
    this.routeAnimState = this.navigationDirection.direction;
    this.navDirectionChangeSubscription = navigationDirection.changeObservable.subscribe(direction => {
      this.routeAnimState = direction;
      this.appRef.tick();
    });

  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      userid: [''],
      recaptcha: ['', Validators.required]
    });

    this.featureService.isFeatureActivated(FeatureConst.features.ContinueAsFleetManagerV2).subscribe((isManagerV2) => {
      this.isManagerV2 = isManagerV2;
    });
  }

  ngOnDestroy() {
    this.navDirectionChangeSubscription.unsubscribe();
  }

  onSubmit() {
    if (this.form.valid) {

      this.isLoading = true;
      const email = this.form.get('userid').value;
      const recaptcha = this.form.get('recaptcha').value;

      if (this.isManagerV2) {
        this.userManagementService.forgotPassword(email, recaptcha).subscribe(result => {
          this.onSubmitSuccess();
        },
          (error) => {
            this.onSubmitError(error.error.title);
          });
      } else {
        this.authService.forgotPassword(email, recaptcha).subscribe(result => {
          if (result === true) {
            this.onSubmitSuccess();
          } else {
            this.onSubmitError(result.error_message);
          }
        });
      }
    }
  }

  private onSubmitSuccess() {
    this.isLoading = false;
    this.router.navigate(['/start/forgot-password-confirmation']);
  }

  private onSubmitError(errorMessage) {
    this.isLoading = false;
    this.resetErrorMessage = errorMessage;
  }
}
