import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

const rulesMapping = {
  minOneDigitCharacter : /\d/,
  minOneSpecialCharacter : /(?=.*?[!#$%&*,-.?:@;^_])/,
  minEightCharacters: /.{8,}/,
};

export function validateSecurePassword(control: AbstractControl): ValidationErrors | null {
  const validationErrors: ValidationErrors = {
    minOneDigitCharacter: false,
    minOneSpecialCharacter: false,
    minEightCharacters: false,
  };
  for (const ruleName in rulesMapping) {
    if (!rulesMapping[ruleName].test(control.value)) {
      validationErrors[ruleName] = true;
    }
  }
  if (Object.values(validationErrors).some(v => v === true)) {
    return validationErrors;
  }
  return null;
}

@Directive({
  selector: '[securePassword]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PasswordValidatorDirective, multi: true }],
})
export class PasswordValidatorDirective implements Validator {
  validate: ValidatorFn = validateSecurePassword;
}
