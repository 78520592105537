import { Pipe, PipeTransform } from '@angular/core';
import { AppConfigService } from '../config/app-config.service';

@Pipe({
  name: 'assetPath'
})
export class AssetPathPipe implements PipeTransform {

  constructor(private appConfig: AppConfigService) {

  }

  transform(value: any, args?: any): any {
    return this.appConfig.config.assetsPath + value;
  }

}
