import { ApiEndpoints } from '../../shared/common/api.settings';

export const FeedConst = {
  api: {
    base: ApiEndpoints.API_ENDPOINT_FEED,
    endpoints: {
      feedGetAllV2: ApiEndpoints.API_ENDPOINT_FEED_GET_ALL_V2,
      feedUpdate: ApiEndpoints.API_ENDPOINT_FEED_UPDATE,
      feedRatePos: ApiEndpoints.API_ENDPOINT_FEED_RATEPOS
    }
  },
  STATIC_NUMBER_TEN: 10,
};
