export const PosStorageConst = {
  storageElements: {
    addresses: {
      key: 'addresses',
      conf: {
        historyLength: 3
      }
    }
  }
};
