import { MaskComponent } from './mask.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    MaskComponent
  ],
  exports: [
    MaskComponent
  ]
})
export class MaskModule {
}
