import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sticky-header',
  templateUrl: './sticky-header.component.html',
  styleUrls: ['./sticky-header.component.scss']
})
export class StickyHeaderComponent {

  @Input()
  public label: string;

  @Input()
  public showBack = true;

  constructor() {
  }

}
