﻿import { Component, OnDestroy, OnInit } from '@angular/core';
import { PopinService } from '../../shared/ui/popin.service';

@Component({
  selector: 'app-more-faq',
  templateUrl: './more-faq.component.html',
  styleUrls: ['./more-faq.component.scss']
})
export class MoreFaqComponent implements OnInit, OnDestroy {


  constructor(private popinService: PopinService) {


  }

  ngOnInit() {
    this.popinService.opened(true);
  }

  ngOnDestroy() {
    // Back navigator
    this.popinService.opened(false);
  }

  onBackClick() {
    this.popinService.opened(false);
  }

}
