import { Injectable } from '@angular/core';
import { HttpService } from '../helper/http.service';
import { Observable } from 'rxjs';
import { UserManagementConst } from './userManagement.const';
import { of } from 'rxjs/observable/of';
import { PreferencesModel } from '../app-menu/my-preferences/preferences.model';

@Injectable()
export class UserManagementService {

    constructor(private httpService: HttpService) {
    }

    public forgotPassword(email: string, recaptcha?: string): Observable<boolean> {
        const body = { email, recaptcha }
        return this.httpService.post<any>(UserManagementConst.api.endpoints.forgotPassword, body, true);
    }

    public authenticatedForgotPassword(email: string): Observable<boolean> {
        const body = { email }
        return this.httpService.post<any>(UserManagementConst.api.endpoints.authenticatedForgotPassword, body, false);
    }

    public resetPassword(token: string, password: string): Observable<boolean> {
        const body = { token, password };
        return this.httpService.post<any>(UserManagementConst.api.endpoints.resetPassword, body, true);
    }

    public changeCurrentUserPassword(currentPassword: string, newPassword: string) {
        const body = { currentPassword, newPassword };
        return this.httpService.post<any>(UserManagementConst.api.endpoints.changeCurrentUserPassword, body, false);
    }

    public getNewUserProfile(): Observable<any> {
        return this.httpService.get(UserManagementConst.api.endpoints.getNewDriverUserProfile, false, false);
    }

    public getUserProfile(): Observable<any> {
        return this.httpService.get(UserManagementConst.api.endpoints.getDriverUserProfile, false, false);
    }

    public updateUserProfile(): Observable<any> {
        return this.httpService.post(UserManagementConst.api.endpoints.getDriverUserProfile, false);
    }

    public getMyUserPreferences(): Observable<any> {
        return this.httpService.get(UserManagementConst.api.endpoints.getPreferences, false, false);
    }

    public updateMyUserPreferences(preferences: PreferencesModel): Observable<any> {
        return this.httpService.post(UserManagementConst.api.endpoints.updatePreferences, preferences, false);
    }

    public updateLastLoginDate(): Observable<void> {
      return this.httpService.put(UserManagementConst.api.endpoints.updateLastLoginDate, null);
    }
}
