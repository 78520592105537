import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { InputChoice } from 'app/shared/models/input-choice.model';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
})
export class MultiselectComponent implements OnInit {

  @Input() control: FormControl;
  @Input() label: string;
  @Input() clearOption = false;

  @Input()
  set options(options: InputChoice[]) {
    this._options = options;
    if (options) {
      this.filterOptions('');
    }
  }

  @Output() valueChange: EventEmitter<void> = new EventEmitter();

  private _options: InputChoice[];
  public searchOption: FormControl = new FormControl();
  public filteredOptions: InputChoice[];

  constructor() { }

  ngOnInit(): void {
    if (this.control.value && this.control.value.length > 0) {
      this.filteredOptions = [...this.control.value, ...this._options.filter(o => !this.control.value.includes(o)).slice(0, 50)];
    } else {
      this.filteredOptions = this._options.slice(0, 50);
    }
    this.searchOption.valueChanges
      .subscribe((search: string) => {
        this.filterOptions(search);
      });
  }

  public removeOption(optionToRemove): void {
    const index = this.control.value.findIndex(o => o === optionToRemove);
    if (index !== -1) {
      this.control.value.splice(index, 1);
      this.control.setValue(this.control.value);
    }
  }

  public filterOptions(search: string) {
    if (!search || search === '') {
      this.filteredOptions = this._options.slice(0, 50);
    } else {
      this.filteredOptions = this._options
        .filter(option => option.label.toLowerCase().includes(search.toLowerCase()))
        .slice(0, 50);
    }
    if (this.control.value && this.control.value.length) {
      this.filteredOptions = [...this.control.value, ...this.filteredOptions.filter(o => !this.control.value.includes(o))];
    }
  }

  public isOptionSelected(option: InputChoice): boolean {
    return this.control.value && this.control.value.find(o => o.value === option.value);
  }

  public isGrandChild(option: InputChoice) {
    const father = this._options.find(o => o.value === option.parentId);
    return option.parentId && father && father.parentId;
  }

  public hasValidator(validator: string): boolean {
    if (!(this.control.validator instanceof Function)) {
      return false;
    }
    if (!this.control.validator('' as unknown as AbstractControl)) {
      return false;
    }

    return !!this.control.validator('' as unknown as AbstractControl).hasOwnProperty(validator);
  }

  public clearSelection(): void {
    this.control.setValue([]);
  }
}
