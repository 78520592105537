import { Session } from '../../../shared/common/api.settings';
import { Injectable } from '@angular/core';
import { RequestParameter } from '../../../shared/common/request-parameter.model';
import { ContractConst } from './contract.const';
import { AppStorageService } from '../../../shared/storage/common/app-storage.service';
import { ApiService } from '../../../shared/common/api.service';
import { Observable } from 'rxjs';
import { MaintenanceItem } from '../../../cars/shared/maintenance-item.model';
import { FuelHistory } from '../../../cars/shared/fuel-history.model';

@Injectable()
export class ContractService {

  private _carsPictures = [];
  private _currentContractId = null;

  constructor(
    private api: ApiService,
    private appStorageService: AppStorageService
  ) {
  }

  get currentContractId() {
    return this._currentContractId;
  }

  set currentContractId(currentContractId) {
    this._currentContractId = currentContractId;
  }

  updateMileage(contractId: string, mileage: number): Promise<any> {
    const params = {
      'ContractId': contractId,
      'Mileage': mileage
    };

    const url = ContractConst.api.baseUrl + ContractConst.api.modules.contract.url + ContractConst.api.modules.contract.endpoints.updateMileage;

    return this.api.post(url, params).toPromise()
      .then(response => {
        return response;
      });
  }

  buyVehicle(contractId: string, dateOfPurchase: string, details?: string): Promise<any> {

    const params: RequestParameter[] = [];
    params.push(new RequestParameter('driverId', Session.DRIVER_ID));
    params.push(new RequestParameter('contractId', contractId));
    params.push(new RequestParameter('dateOfPurchase', dateOfPurchase));
    if (details) {
      params.push(new RequestParameter('details', details));
    }
    const url = ContractConst.api.baseUrl + ContractConst.api.modules.customer.url + ContractConst.api.modules.customer.endpoints.buyvehicle;

    return this.api.post(url, params).toPromise()
      .then(response => {
        return response.status === 200 && response.json();
      });

  }

  getFuelCardPin(contractId: string, password: string, number: string): Promise<any> {

    const params: RequestParameter[] = [];
    params.push(new RequestParameter('driverId', Session.DRIVER_ID));
    params.push(new RequestParameter('contractId', contractId));
    params.push(new RequestParameter('password', password));
    params.push(new RequestParameter('number', number));

    const url = ContractConst.api.baseUrl + ContractConst.api.modules.customer.url + ContractConst.api.modules.customer.endpoints.fuelcardpin;

    return this.api.post(url, params).toPromise()
      .then(response => {
        return response.json().value;
      });

  }

  public getMaintenanceHistory(contractID: string): Observable<MaintenanceItem[]> {
    const url = ContractConst.api.baseUrl + ContractConst.api.modules.contract.url + ContractConst.api.modules.contract.endpoints.maintenanceHistory + contractID;
    return this.api.get(url);
  }

  public getFuelHistory(contractID: string): Observable<FuelHistory[]> {
    const url = ContractConst.api.baseUrl + ContractConst.api.modules.contract.url + ContractConst.api.modules.contract.endpoints.fuelHistory + contractID;
    return this.api.get(url);
  }
}
