import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class ConsoleLogger {
  private ENVS: Array<any> = [
    {env: 'DEV', level: 1},
    {env: 'INT', level: 2},
    {env: 'TST', level: 3},
    {env: 'UAT', level: 4},
    {env: 'PRD', level: 5}
  ];

  constructor() {
  }

  debug(...args: Array<any>) {
    this.trace('debug', args);
  }

  info(...args: Array<any>) {
    this.trace('info', args);
  }

  error(...args: Array<any>) {
    this.trace('error', args);
  }

  log(...args: Array<any>) {
    this.trace('log', args);
  }

  private trace(type: string, args) {
    const [untilEnv, ...rest] = args;
    if (this.isAllowed(untilEnv)) {
      console[type](...rest);
    }
  }

  private isAllowed(untilEnv = 1): boolean {
    const envLevel: number = this.ENVS.filter(({env, level}) => env === environment.envName)[0].level;
    const untilLevel: number = this.ENVS.filter(({env, level}) => env === untilEnv)[0].level;

    return (envLevel <= untilLevel);
  }
}
