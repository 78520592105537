﻿import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvailableSoonComponent } from './available-soon.component';
import { PipesModule } from '../../shared/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    TranslateModule
  ],
  declarations: [AvailableSoonComponent],
  exports: [AvailableSoonComponent]
})
export class AvailableSoonModule {
}
