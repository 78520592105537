import { trigger, transition, style, animate } from '@angular/animations';

export let enterAnimation = trigger(
  'enterAnimation', [
    transition(':enter', [
      style({opacity: '0', maxHeight: '0'}),
      animate('300ms ease-in-out', style({opacity: '1', maxHeight: '30px'}))
    ]),
    transition(':leave', [
      style({opacity: '1', maxHeight: '30px'}),
      animate('200ms ease-in-out', style({opacity: '0', maxHeight: '0'}))
    ])
  ]
);
