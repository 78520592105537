import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgInlineComponent } from './svg-inline.component';
import { SvgInlineService } from './svg-inline.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    SvgInlineComponent
  ],
  providers: [SvgInlineService],
  exports: [
    SvgInlineComponent
  ]
})
export class SvgInlineModule {
}
