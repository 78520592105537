import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class SvgInlineService {

  private svgList = new Map<string, any>();

  constructor(private http: HttpClient) {}

  public getSvgByUrl(url: string): Observable<any> {
    if (this.isSvgExists(url)) {
      return Observable.of(this.svgList.get(url));
    }

    return this.http.get(url, { responseType: 'text' }).map(
      response => {
        if (response) {
          this.svgList.set(url, response);
          return response;
        }
      });
  }

  public isSvgExists(url: string): boolean {
    return this.svgList.has(url);
  }
}
