import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationDirectionDirective } from './navigation-direction.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    NavigationDirectionDirective
  ],
  exports: [
    NavigationDirectionDirective
  ]
})
export class RouterTransitionsModule {
}
