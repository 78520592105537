import { Component, Input } from '@angular/core';
import { AccordionComponent } from '../accordion/accordion.component';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss'],
  animations: [
    trigger('tabContent', [
      state('hidden', style({
        height: '0',
        marginTop: '0',
        opacity: '0'
      })),
      state('visible', style({
        height: '*',
        marginTop: '40px',
        opacity: '1'
      })),
      transition('visible <=> hidden', animate('300ms ease-in-out'))
    ])
  ]
})
export class AccordionItemComponent {

  @Input()
  public label: string;

  @Input()
  public selected: boolean;

  public clearAnimationPausedState: boolean;

  constructor(public accordion: AccordionComponent) {
    this.accordion.addTab(this);
  }

  toggle(event) {
    event.preventDefault();
    this.clearAnimationPausedState = true;

    if (this.selected) {
      this.selected = false;
    } else {
      if (!this.accordion.multiple) {
        for (let i = 0; i < this.accordion.tabs.length; i++) {
          this.accordion.tabs[i].selected = false;
        }
      }

      this.selected = true;
    }
  }

}
