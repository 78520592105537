import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';

// ## Google Tag Manager setup
const gtmScript = (gtmID: string): string  =>
  `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${gtmID}');`;

const gtmNoScript = (gtmID: string): string  =>
  `<iframe src='https://www.googletagmanager.com/ns.html?id=${gtmID}'
          height='0'
          width=0'
          style='display:none;visibility:hidden'>
  </iframe>`;

const script = document.createElement('script');
const noscript = document.createElement('noscript');
script.innerHTML = gtmScript(environment.gtmKey);
noscript.innerHTML = gtmNoScript(environment.gtmKey);
document.head.appendChild(script);
document.body.appendChild(noscript);
// ## end gtm setup

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);


