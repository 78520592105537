import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'app-vertical-carousel',
  templateUrl: './vertical-carousel.component.html',
  styleUrls: ['./vertical-carousel.component.scss']
})
export class VerticalCarouselComponent implements OnInit, AfterViewInit {

  public currentIndex = 0;
  public arrayItems = [];
  private numItems = 0;
  private itemsContainer: any;
  private loop: any;
  private loopInterval = 3000;

  constructor(private element: ElementRef,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.numItems = this.element.nativeElement.querySelectorAll('.item').length;
    this.arrayItems = this.element.nativeElement.querySelectorAll('.item');
    this.itemsContainer = this.element.nativeElement.querySelectorAll('.items-container');

    this.cdr.detectChanges();
    this.runLoop();
  }

  public runLoop() {
    this.loop = window.setInterval(() => {
      this.currentIndex = this.currentIndex + 1;

      if (this.currentIndex >= this.numItems) {
        this.currentIndex = 0;
      }
    }, this.loopInterval);
  }

  public resetLoop() {
    window.clearInterval(this.loop);
    this.runLoop();
  }

  public nextItem() {
    this.resetLoop();
    this.currentIndex = (this.currentIndex < this.numItems - 1) ? this.currentIndex : 0;
  }

  public prevItem() {
    this.resetLoop();
    this.currentIndex = (this.currentIndex > 0) ? this.currentIndex - 1 : this.numItems - 1;
  }

  public clickChip(index) {
    this.resetLoop();
    this.currentIndex = index;
  }
}
