import { Component, HostListener, OnInit } from '@angular/core';
import { AppConfigService } from '../../shared/config/app-config.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']

})
export class SuccessComponent implements OnInit {

  public isDesktop = false;

  constructor(private appConfig: AppConfigService) {
  }

  @HostListener('window:resize', ['$event'])
  onResize($event = null) {
    this.isDesktop = (window.innerWidth > this.appConfig.config.breakpoints.m);
  }

  ngOnInit() {
    this.onResize();
  }

}
