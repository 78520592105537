import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { DriverService } from '../../driver.service';
import {
  contractConsolidatedStatusFromString,
  contractsContainsStatusOfType
} from '../contract-status.helper';
import { Injectable } from '@angular/core';
import { ContractService } from '../contract.service';
import { ContractStatusGuard } from './contract-status-guard.service';
import { ProspectAccessService } from '../../../../shared/authentication/prospect-access.service';
import { Observable } from 'rxjs';
import { of } from 'rxjs/observable/of';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class ContractStatusExcludeGuard extends ContractStatusGuard implements CanActivate {

  constructor(
    protected driverService: DriverService,
    protected contractService: ContractService,
    protected prospectAccessService: ProspectAccessService) {
    super(driverService, contractService, prospectAccessService);
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (!route.data) {
      return of(false);
    }
    return this.driverService.driver.pipe(
      mergeMap((driver) => {
        return this.prospectAccessService.canActivate(route, state)
          .map((isProspectAllowed) => {
            if (isProspectAllowed !== null) {
              return isProspectAllowed;
            }
            const authorizedStatus = route.data['status'];
            if (!authorizedStatus) {
              return false;
            }

            const contracts = this.selectContract(route.data, driver);

            if (Array.isArray(authorizedStatus)) {
              let authorized = true;
              let oneStatusExists = false;
              authorizedStatus.forEach(status => {
                const expectedStatus = contractConsolidatedStatusFromString(status);
                if (expectedStatus !== undefined) {
                  oneStatusExists = true;
                  authorized = authorized && !contractsContainsStatusOfType(contracts, expectedStatus);
                }
              });
              return oneStatusExists ? authorized : false;
            } else {
              const expectedStatus = contractConsolidatedStatusFromString(authorizedStatus);
              return expectedStatus !== undefined ? !contractsContainsStatusOfType(contracts, expectedStatus) : false;
            }
          });
      }),
    )
  }
}
