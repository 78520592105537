import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'foreachFilter',
  pure: false
})
export class ForeachFilterPipe<T> implements PipeTransform {
  transform<T>(items: T[], filterCallback: (item: any, params: any) => boolean, filterParameters: any): T[] {
    if (!items || !filterCallback) {
      return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items.filter( item => filterCallback(item, filterParameters));
  }
}
