﻿import { MaterialModule } from '../shared/ui/material/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StartComponent } from './start/start.component';
import { LoginComponent } from './login/login.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AllowNotificationComponent } from './allow-notification/allow-notification.component';
import { AssistanceComponent } from './assistance/assistance.component';
import { TranslateModule } from '@ngx-translate/core';
import { SignUpComponent } from './sign-up/sign-up.component';
import { UserDispatchComponent } from './user-dispatch/user-dispatch.component';
import { SignUpConfirmationComponent } from './sign-up-confirmation/sign-up-confirmation.component';
import { ForgotPasswordConfirmationComponent } from './forgot-password-confirmation/forgot-password-confirmation.component';
import { AccountDisabledComponent } from './account-disabled/account-disabled.component';
import { SignUpDeniedComponent } from './sign-up-denied/sign-up-denied.component';
import { SignUpErrorComponent } from './sign-up-error/sign-up-error.component';
import { RoleDispatchComponent } from './role-dispatch/role-dispatch.component';
import { AuthService } from '../shared/authentication/auth.service';
import { EndForgotPasswordComponent } from './end-forgot-password/end-forgot-password.component';
import { SuccessComponent } from './success/success.component';
import { CreatePasswordComponent } from './create-password/create-password.component';
import { AccessService } from '../shared/authentication/access.service';
import { SignUpProspectComponent } from './sign-up-prospect/sign-up-prospect.component';
import { ProspectCreatePasswordComponent } from './prospect-create-password/prospect-create-password.component';
import { environment } from '../../environments/environment';
import { PasswordVisiblityToggleModule } from '../shared/directives/password-visibility-toggle/password-visibility-toggle.module';
import { GtmModule } from '../shared/gtm/gtm.module';
import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { MyAldUiModule } from 'app/shared/ui/myald-ui/myald-ui.module';

const ROUTES = [

  {
    path: '',
    component: StartComponent
  },
  {
    path: 'user-dispatch',
    component: UserDispatchComponent
  },

  {
    path: 'sign-up',
    component: SignUpComponent
  },

  {
    path: 'sign-up-prospect/:id',
    component: SignUpProspectComponent
  },

  {
    path: 'sign-up-confirmation',
    component: SignUpConfirmationComponent
  },

  {
    path: 'login',
    component: LoginComponent
  },

  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },

  {
    path: 'success',
    component: SuccessComponent
  },

  {
    path: 'end-forgot-password',
    component: EndForgotPasswordComponent
  },

  {
    path: 'create-password',
    component: CreatePasswordComponent
  },

  {
    path: 'forgot-password-confirmation',
    component: ForgotPasswordConfirmationComponent
  },

  {
    path: 'allow-notification',
    component: AllowNotificationComponent,
    canActivate: [AuthService, AccessService]
  },

  {
    path: 'assistance',
    component: AssistanceComponent
  },

  {
    path: 'account-disabled',
    component: AccountDisabledComponent
  },

  {
    path: 'sign-up-denied',
    component: SignUpDeniedComponent
  },

  {
    path: 'sign-up-error',
    component: SignUpErrorComponent
  },

  {
    path: 'role-dispatch',
    component: RoleDispatchComponent
  },

  {
    path: 'create-password-prospect',
    component: ProspectCreatePasswordComponent
  },

];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(ROUTES),
    TranslateModule,
    SharedModule,
    PasswordVisiblityToggleModule,
    GtmModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MaterialModule,
    MyAldUiModule,
  ],
  declarations: [
    StartComponent,
    LoginComponent,
    ForgotPasswordComponent,
    EndForgotPasswordComponent,
    SuccessComponent,
    CreatePasswordComponent,
    AllowNotificationComponent,
    AssistanceComponent,
    SignUpComponent,
    UserDispatchComponent,
    SignUpConfirmationComponent,
    ForgotPasswordConfirmationComponent,
    AccountDisabledComponent,
    SignUpDeniedComponent,
    SignUpErrorComponent,
    RoleDispatchComponent,
    ProspectCreatePasswordComponent,
    SignUpProspectComponent
  ],
  exports: [
    StartComponent,
    RouterModule
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.siteKey } as RecaptchaSettings,
    },
  ]
})
export class StartModule {
}
