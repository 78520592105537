import { NgModule } from '@angular/core';
import { DriverService } from './shared/driver.service';
import { ContractService } from './shared/contract/contract.service';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    SharedModule
  ],
  providers: [
    DriverService,
    ContractService
  ]
})
export class DriverModule {
}
