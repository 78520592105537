import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs';

@Injectable()
export class PopinService {

  public bSubject: BehaviorSubject<boolean>;


  constructor() {
    this.bSubject = new BehaviorSubject(null);
  }

  public getOpened(): Observable<any> {
    return this.bSubject.asObservable();
  }

  public opened(b: boolean) {
    this.bSubject.next(b);
  }

}
