import { environment } from 'environments/environment';

export const DocumentsConst = {
  name: 'documents',
  api: {
    baseUrl: `${environment.apigatewayUrl}/apigateway/`,
    modules: {
      documents: {
        url: 'documentmanagement/api/',
        endpoints: {
          GetDocuments: 'Documents/',
          GetDocument: 'Information/',
          CreateDocument: 'Documents/',
          DeleteDocuments: 'Documents/Delete/',
          DownloadDocuments: 'Documents/',
          GetCategories: 'Category/',
        },
      },
    },
  },
  maximumFileSize: 15728640,
  fileFormats: '.pdf,.doc,.docx,.odt,.xls,.xlsx,.ods,.ppt,.pptx,.png,.jpeg,.png,.jpg,.gif'
};

