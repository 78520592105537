import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../pipes/pipes.module';
import { CookiesConsentPopinComponent } from './cookies-consent-popin/cookies-consent-popin.component';
import { CookiesService } from './cookies.service';
import { MaterialModule } from '../ui/material/material.module';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    MaterialModule,
    TranslateModule,
  ],
  providers: [CookiesService],
  declarations: [CookiesConsentPopinComponent],
  exports: [CookiesConsentPopinComponent]
})
export class CookiesModule {
}
