import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observer } from 'rxjs/Observer';
import { environment } from '../../../environments/environment';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';

const URL_API_GEOLOCATE: string = 'https://www.googleapis.com/geolocation/v1/geolocate?key=' + environment.gmapApiKey;
const URL_API_STATIC: string = 'https://maps.googleapis.com/maps/api/staticmap?key=' + environment.gmapApiKey;

/**
 *
 * Map data service. This service allows us to query various APIs for mapping related data
 *
 * Not to be confused with UI map-google service which is used to control the instance of a Google Map
 *
 */
@Injectable()
export class MapService {

  public autoCompleteService: any;
  public geocoder: any;

  private _latitude: number;
  private _longitude: number;

  constructor(
    private http: HttpClient,
  ) {
  }

  /**
   * Check if user has been geolocated before
   */
  isGeolocated(): boolean {
    return !!(this._latitude && this._longitude);

  }

  /*
  * Retrieve previous position
  */
  getStoredPosition() {
    return {
      lat: this._latitude,
      lng: this._longitude
    };
  }

  /**
   * geolocate user api google
   */
  geolocateApiGoogle(): Observable<any> {

    return this.http.post(URL_API_GEOLOCATE, null)
      .map(res => res);

  }

  /**
   * get autocomplete address
   */
  getAutocompleteAddress(address: string): Observable<any> {

    if (!this.autoCompleteService) {
      this.autoCompleteService = new google.maps.places.AutocompleteService();
    }

    if (address) {

      return Observable.create(observer => {

        this.autoCompleteService.getPlacePredictions({input: address}, function (predictions, status) {

          const result = [];

          if (status !== google.maps.places.PlacesServiceStatus.OK) {

            observer.next({});
            observer.complete();

          } else {

            predictions.forEach(prediction => {
              const obj = {
                label: prediction.structured_formatting.main_text,
                label_2: (prediction.structured_formatting.secondary_text) ? prediction.structured_formatting.secondary_text : '',
                //     label_3: (prediction.terms[2]) ? prediction.terms[2].value :'',
                place_id: prediction.place_id
              };
              result.push(obj);
            });

            observer.next(result);
            observer.complete();
          }

        });

      }).catch(error => Observable.of([]));
    }

    return Observable.of([]);

  }

  /**
   * get get LatLng By Placeid
   */
  getLatLngByPlaceId(placeId): Observable<any> {

    if (!this.geocoder) {
      this.geocoder = new google.maps.Geocoder();
    }

    return Observable.create(observer => {

      this.geocoder.geocode({'placeId': placeId}, function (results, status) {

        if (status !== google.maps.GeocoderStatus.OK) {

          observer.error('service geocoder indisponible');
          observer.complete();

        } else {

          observer.next(results[0].geometry.location);
          observer.complete();

        }

      });

    });
  }

  /**
   * get get LatLng By Placeid
   */
  getAddressByLatLng(lat: number, lng: number): Observable<any> {

    if (!this.geocoder) {
      this.geocoder = new google.maps.Geocoder();
    }

    return Observable.create(observer => {

      this.geocoder.geocode({'location': {lat: lat, lng: lng}}, function (results, status) {

        if (status !== google.maps.GeocoderStatus.OK) {

          observer.error('service geocoder indisponible');
          observer.complete();

        } else {
          observer.next(results[0].formatted_address);
          observer.complete();

        }

      });

    });
  }

  /**
   * get Current Position geolocation API HTML5 Navigator
   */
  getCurrentPosition(): Observable<Position> {

    return Observable.create((observer: Observer<Position>) => {

      if (navigator.geolocation) {

        navigator.geolocation.getCurrentPosition(
          // Success callback.
          (position: Position) => {
            this._latitude = position.coords.latitude;
            this._longitude = position.coords.longitude;
            observer.next(position);
            observer.complete();
          },

          // Error callback.
          (error: PositionError) => {

            if (error.code > 0) {

              switch (error.code) {
                case error.PERMISSION_DENIED:
                  observer.error('permission denied');
                  break;
                case error.POSITION_UNAVAILABLE:
                  observer.error('position unavailable');
                  break;
                case error.TIMEOUT:
                  observer.error('position timeout');
                  break;

              }
            }

          },

          {enableHighAccuracy: true, timeout: 8000, maximumAge: 50000}
        );

      } else {

        observer.error('browser doesn\'t support geolocation');

      }

    });

  }

  /**
   * get image static
   */
  getMapStatic(size: string, lat: number, lng: number, zoom: number, scale = 1) {

    return URL_API_STATIC + '&size=' + size + '&' + 'center=' + lat + ',' + lng + '&zoom=' + zoom + '&scale=' + scale;

  }

}
