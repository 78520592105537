import { AbstractControl } from '@angular/forms';


export class DateValidator {

  static simple = function (control: AbstractControl) {
    try {
      const d = new Date(control.value);
      // let now = new Date();
      // return null; //true ? null : {email : 'global-generic_terms-error_invalid_email' };
    } catch (e) {
      return {date: 'global-generic_terms-error_invalid_date'};
    }
  };

  static isFuture = function (control: AbstractControl) {
    try {
      const d = new Date(control.value);
      const now = new Date();
      return d >= now ? null : {date: 'errormessage_date_anterior'};
    } catch (e) {
      return {date: 'global-generic_terms-error_invalid_date'};
    }
  };

  static isPast = function (control: AbstractControl) {
    try {
      const d = new Date(control.value);
      const now = new Date();
      return d <= now ? null : {date: 'errormessage_date_posterior'};
    } catch (e) {
      return {date: 'global-generic_terms-error_invalid_date'};
    }
  };

}
