import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNumber'
})
export class FormatNumberPipe implements PipeTransform {

  transform(value: number, unity = '', separator = ' '): any {
    if (value) {
      const strValue = value.toString();
      let formattedValue = '';
      let marker = 0;

      for (let index = strValue.length; index > 0; index--) {
        const i = index - 1;
        let letter = strValue[i];

        marker++;

        if (marker === 3) {
          letter = separator + strValue[i];
          marker = 0;
        }
        formattedValue = letter + formattedValue;
      }

      return formattedValue + unity;
    } else {
      return 0;
    }
  }

}
