import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GtmService } from './gtm.service';
import { GtmRef } from './gtm.ref';
import { GtmClickEventDirective } from './gtm-event.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    GtmClickEventDirective,
  ],
  exports: [
    GtmClickEventDirective,
  ],
  providers: [
    GtmRef,
    GtmService,
  ],
})
export class GtmModule {
  static forRoot(): ModuleWithProviders {
    return { ngModule: GtmModule };
  }
}
