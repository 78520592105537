﻿import { Router } from '@angular/router';
import { ChangeDetectorRef, Component } from '@angular/core';
import { OnErrorService } from '../../ui/on-error.service';
import { ModalService } from '../../ui/modal.service';
import { MaintenanceService } from '../../config/maintenance.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent {

  public isInMaintenance = undefined;
  private previousMaintenanceStatus: boolean = undefined;

  constructor(private router: Router,
              private onErrorService: OnErrorService,
              private cdr: ChangeDetectorRef,
              private modalService: ModalService,
              private maintenanceService: MaintenanceService) {

    this.onErrorService.maintenanceStatusObservable.subscribe(
      status => {
        if (status) {
          this.modalService.openModal('maintenance');
        }
      }
    );

    this.maintenanceService.maintenanceSubject.subscribe(isInMaintenance => {
      this.setMaintenanceStatus(isInMaintenance);
    });
  }

  private setMaintenanceStatus(maintenanceStatus: boolean) {
    this.previousMaintenanceStatus = this.isInMaintenance;
    this.isInMaintenance = maintenanceStatus;

    if (this.isInMaintenance === false && this.previousMaintenanceStatus !== false) {
      this.redirect();
    }
  }

  private redirect() {
      this.router.navigate(['/start/user-dispatch']);
  }
}
