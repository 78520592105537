import { Address } from '../../gmap/address.model';
import { Injectable } from '@angular/core';
import { AppStorageService } from 'app/shared/storage/common/app-storage.service';
import { PosStorageConst } from './pos-storage.const';

@Injectable()
export class PosStorageService {

  constructor(private storage: AppStorageService) {
  }

  /**
   * gets addresses
   */
  get addresses(): Address[] {
    return this.storage.get(PosStorageConst.storageElements.addresses.key, AppStorageService.STORES.LOCAL, []);
  }

  /**
   * sets addresses
   */
  set addresses(addresses: Address[]) {
    this.storage.set(PosStorageConst.storageElements.addresses.key, addresses);
  }

  /**
   * add an adress in the storage (keeping only x(= _historyLength) adresses)
   * @param address
   */
  addAddress(address: Address): Address[] {
    if (address.place_id) {
      let addresses = this.addresses;
      const existingAddress = addresses.filter(
        storesAddress => (storesAddress.place_id === address.place_id)
      );
      if (existingAddress.length === 0) {
        addresses.push(address);
        if (addresses.length > PosStorageConst.storageElements.addresses.conf.historyLength) {
          addresses = addresses.slice(-PosStorageConst.storageElements.addresses.conf.historyLength);
        }
        this.addresses = addresses;
      }
    }
    return this.addresses;
  }
}
