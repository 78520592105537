import { environment } from 'environments/environment';

export const SignUpConst = {
  api: {
    base: environment.apigatewayUrl + '/apigateway/',
    endpoints: {
      driverRequestAccessUrl: 'user/api/Users/StartAldDriverSignOn',
      managerRequestAccessUrl: 'user/api/Users/fm',
      managerV2prospectRequestAccessUrl: 'usermanagement/api/prospect/signup',
      managerV2DomainManagementUrl: 'usermanagement/api/prospect/grades/',
      managerV2RequestAccessUrl: 'usermanagement/api/Request/RequestAccess',
    }
  }
};
