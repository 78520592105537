import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[passwordVisibilityToggle]'
})

export class PasswordVisibilityToggleDirective {
    private _isPasswordVisible = false;

    constructor(private el: ElementRef) {
        this.setup();
    }

    private togglePasswordVisibility(button: HTMLElement): void {
        this._isPasswordVisible = !this._isPasswordVisible;
        if (this._isPasswordVisible) {
            button.classList.add('hide-password');
            this.el.nativeElement.setAttribute('type', 'text');
        } else {
            button.classList.remove('hide-password');
            this.el.nativeElement.setAttribute('type', 'password');
        }
    }

    private setup(): void {
        const button = document.createElement('div');
        button.classList.add('button');
        button.classList.add('small');
        button.classList.add('toggle-password-visibility');
        button.addEventListener('click', (event) => {
            this.togglePasswordVisibility(button);
        });
        const element = this.el.nativeElement as HTMLElement;
        element.style.paddingRight = '35px';
        element.parentNode.appendChild(button);
    }
}
