﻿import { Component } from '@angular/core';
import { StickyHeaderFormService } from '../../../shared/ui/sticky-header-form.service';

@Component({
  selector: 'app-sticky-header-form',
  templateUrl: './sticky-header-form.component.html',
  styleUrls: ['./sticky-header-form.component.scss']
})
export class StickyHeaderFormComponent {

  constructor(public stickyHeaderFormService: StickyHeaderFormService) {
  }

}
