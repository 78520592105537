import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpComponent } from './help.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HelpContactUsComponent } from './help-contact-us/help-contact-us.component';
import { HelpService } from './shared/help.service';
import { FlatpickrModule } from 'angularx-flatpickr';
import flatpickr from 'flatpickr';
import { HelpCaseRequestComponent } from './help-case-request/help-case-request.component';
import { HelpFollowRequestsComponent } from './help-follow-requests/help-follow-requests.component';
import { HelpCaseItemComponent } from './help-case-item/help-case-item.component';
import { HelpMaintenanceRequestComponent } from './help-maintenance-request/help-maintenance-request.component';
import { HelpGenericComponent } from './help-generic/help-generic.component';
import { GtmModule } from 'app/shared/gtm/gtm.module';
import { HelpDamageDeclarationComponent } from './help-damage-declaration/help-damage-declaration.component';
import { MyAldUiModule } from 'app/shared/ui/myald-ui/myald-ui.module';
import { MaterialModule } from 'app/shared/ui/material/material.module';
import { OnRoadAssistanceService } from '../on-road-assistance/on-road-assistance.service';
import { HelpOrderDamageRepairComponent } from './help-assistance-for-driver/help-order-damage-repair/order-damage-repair.component';
import { HelpTakeAbroadComponent } from './help-assistance-for-driver/help-take-abroad/take-abroad.component';
import { HelpOrderMaintenanceComponent } from './help-assistance-for-driver/help-order-maintenance/order-maintenance.component';

export function flatpickrFactory() {
  return flatpickr;
}

/*

There are no routes defined in this module, unlike the other top level modules in the components directory.

This module is included exclusively in the feed section for the time being, but is complex enough to need it's own module

 */
@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    FlatpickrModule.forRoot(),
    GtmModule,
    MyAldUiModule,
    MaterialModule,
  ],
  declarations: [
    HelpComponent,
    HelpGenericComponent,
    HelpContactUsComponent,
    HelpCaseRequestComponent,
    HelpFollowRequestsComponent,
    HelpCaseItemComponent,
    HelpMaintenanceRequestComponent,
    HelpDamageDeclarationComponent,
    HelpOrderDamageRepairComponent,
    HelpTakeAbroadComponent,
    HelpOrderMaintenanceComponent,
  ],
  exports: [
    HelpComponent,
    HelpGenericComponent,
    HelpContactUsComponent,
    HelpCaseRequestComponent,
    HelpFollowRequestsComponent,
    HelpCaseItemComponent,
    HelpMaintenanceRequestComponent,
    HelpDamageDeclarationComponent,
    HelpOrderDamageRepairComponent,
    HelpTakeAbroadComponent,
    HelpOrderMaintenanceComponent,
  ],
  providers: [
    HelpService,
    OnRoadAssistanceService,
  ]
})
export class HelpModule {
}
