import { Injectable } from '@angular/core';
import { getPageSectionByUrl } from './gtm.helper';
import { GtmEventProperties, GtmPageViewProperties } from './gtm-properties.model';
import { GtmRef } from './gtm.ref';
import { AppStorageService } from '../storage/common/app-storage.service';
import { DriverService } from 'app/driver/shared/driver.service';
import { environment } from 'environments/environment';
import { TranslationService } from 'app/shared/translation/translation.service';
import { takeWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GtmService {

  constructor(private storageService: AppStorageService,
              private driverService: DriverService,
              private gtm: GtmRef,
              private translationService: TranslationService,
  ) {
  }

  public sendPageView(url: string): void {
    let isPageViewFired = false;
    this.driverService.driver.pipe(
      takeWhile(() => !isPageViewFired),
    ).subscribe((driver) => {
      const gtmProperties: GtmPageViewProperties = {
        event: 'dataLayerLoad',
        country: environment.production ? this.storageService.countrySelected : 'TEST',
        pageLanguage: this.translationService.language,
        userRole: this.storageService.currentUserRole,
        userCompany: driver.client && driver.client.name ? driver.client.name : null,
        plateforme: 'driver',
        pageSection: getPageSectionByUrl(url),
        userId: driver.id,
      };
      this.gtm.nativeDatalayer.push(gtmProperties);
      isPageViewFired = true;
    })
  }

  public sendEvent(event: GtmEventProperties, label?: string, details?: string): void {
    // Set eventLabel with existing user role data or take hardcoded label or take default
    if (Object.prototype.hasOwnProperty.call(event, 'eventLabel')) {
      event.eventLabel = label || event.eventLabel;
    }
    // Set eventDetail with existing user role data or take hardcoded details or take default
    if (Object.prototype.hasOwnProperty.call(event, 'eventDetails')) {
      event.eventDetails = details || event.eventDetails;
    }
    this.checkEventRequiredProperty(event);
    this.gtm.nativeDatalayer.push(event);
  }

  public sendAnonymousEvent(event: GtmEventProperties, label?: string, details?: string): void {
    this.checkEventRequiredProperty(event);
    this.gtm.nativeDatalayer.push(event);
  }

  private checkEventRequiredProperty(event: GtmEventProperties): void {
    for (const property in event) {
      if (property && Object.prototype.hasOwnProperty.call(event, property) && /<.*>/.test(event[property])) {
        console.error('GTM - Missing property in event : ', event.event, property, event[property]);
        return;
      }
    }
  }
}
