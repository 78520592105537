import { ActivatedRouteSnapshot, CanActivate, Data, RouterStateSnapshot } from '@angular/router';
import { DriverService } from '../../driver.service';
import { Injectable } from '@angular/core';
import {
  contractConsolidatedStatusFromString,
  contractsContainsStatusOfType
} from '../contract-status.helper';
import { Contract } from '../../models/contract.model';
import { ContractService } from '../contract.service';
import { ProspectAccessService } from '../../../../shared/authentication/prospect-access.service';
import { Observable } from 'rxjs';
import { of } from 'rxjs/observable/of';
import { mergeMap} from 'rxjs/operators';
import { Driver } from '../../models/driver.model';

@Injectable()
export class ContractStatusGuard implements CanActivate {

  constructor(protected driverService: DriverService,
    protected contractService: ContractService,
    protected prospectAccessService: ProspectAccessService) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (!route.data) {
      return of(false);
    }
    const authorizedStatus = route.data['status'];
    return this.driverService.driver.pipe(
      mergeMap((driver) => {
        if (!driver) {
          return of(false);
        }
        return this.prospectAccessService.canActivate(route, state).map((isProspectAllowed) => {
          if (isProspectAllowed !== null) {
            return isProspectAllowed;
          }
          if (!authorizedStatus) {
            return false;
          }

          const contracts = this.selectContract(route.data, driver);

          if (Array.isArray(authorizedStatus)) {
            let authorized = false;
            authorizedStatus.forEach(status => {
              const expectedStatus = contractConsolidatedStatusFromString(status);
              if (expectedStatus !== undefined) {
                authorized = authorized || contractsContainsStatusOfType(contracts, expectedStatus);
              }
            });
            return authorized;
          } else {
            const expectedStatus = contractConsolidatedStatusFromString(authorizedStatus);
            return expectedStatus !== undefined ? contractsContainsStatusOfType(contracts, expectedStatus) : false;
          }
        });
      }),
    );
  }

  protected selectContract(data: Data, driver: Driver): Contract[] {
    if (data['singleContract'] && data['singleContract'] === true) {
      const contract = driver.contracts.find(c => c.id === this.contractService.currentContractId);
      if (contract !== undefined) {
        return [contract];
      }
    }
    return driver.contracts;
  }
}
