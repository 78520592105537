import { animate, state, style, transition, trigger } from '@angular/animations';

export function generateTransitions() {

  return trigger('routeAnim', [

    // Define our 3 states
    state('void', style({position: 'absolute', width: '100%', height: '100%'})),
    state('forward', style({position: 'absolute', width: '100%', height: '100%'})),
    state('backward', style({position: 'absolute', width: '100%', height: '100%'})),

    // Forward transitions
    transition('void => forward', [  // before 2.1: transition('void => *', [
      style({transform: 'translateX(100%)', position: 'fixed'}),
      animate('0.5s ease-in-out', style({transform: 'translateX(0%)'}))
    ]),
    transition('forward => void', [  // before 2.1: transition('* => void', [
      style({transform: 'translateX(0%)'}),
      animate('0.5s ease-in-out', style({transform: 'translateX(-100%)'}))
    ]),

    // Backward transitions
    transition('void => backward', [  // before 2.1: transition('void => *', [
      style({transform: 'translateX(-100%)', position: 'fixed'}),
      animate('0.5s ease-in-out', style({transform: 'translateX(0%)'}))
    ]),
    transition('backward => void', [  // before 2.1: transition('* => void', [
      style({transform: 'translateX(0%)'}),
      animate('0.5s ease-in-out', style({transform: 'translateX(100%)'}))
    ])
  ]);


}
