import { Injectable } from '@angular/core';
import { AppConfigService } from '../config/app-config.service';
import { TranslateService } from '@ngx-translate/core';
import { COUNTRIES } from './translation.settings';
import { TranslationStorageService } from '../storage/translation/translation-storage.service';
import { AppStorageService } from '../storage/common/app-storage.service';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

@Injectable()
export class TranslationService {
  private _lang: string;
  private _country: string;
  private _country_code: string;
  private _language_country_separator = '-';

  constructor(private appConfig?: AppConfigService,
              public translateService?: TranslateService,
              private appStorageService?: AppStorageService,
              private storage?: TranslationStorageService) {

    const countrySelected = this.appStorageService.countrySelected;
    const selectedLanguage = this.appStorageService.get('lang');
    if (countrySelected) {
      this._country = _.findKey(COUNTRIES, {'country_code': countrySelected});
      selectedLanguage ? translateService.setDefaultLang(selectedLanguage) : translateService.setDefaultLang(COUNTRIES[this._country].default_lang);
    } else {
      this._country = this.appConfig.config.country;
      translateService.setDefaultLang(this.appConfig.config.defaultLang);
    }

    this._country_code = COUNTRIES[this._country].country_code;

    this.translateService.addLangs(COUNTRIES[this._country].langs || ['en-us']);
    // Setting de language from storage (if exists)
    this._lang = this.isAvailable(storage.lang.toString()) ? storage.lang : this.translateService.getDefaultLang();
    this.language = this._lang;
  }

  get language(): string {
    return this._lang;
  }

  set language(lang: string) {
    if (this.isAvailable(lang) &&
      (!this.storage.lang ||
        (this.storage.lang &&
          ((this.translateService.currentLang !== this.storage.lang) || (lang !== this.storage.lang))
        )
      )
    ) {
      this._lang = lang;
      this.storage.lang = this._lang;
      this.translateService.use(this._lang);

    } else if (!this.isAvailable(lang)) {
      this._lang = this.translateService.getDefaultLang();
      this.storage.lang = this._lang;
      this.translateService.use(this._lang);
    }
  }

  get languageCode(): string {
    return this._lang.substr(0, 2);
  }

  translate(key: string, value?: string): Observable<string> {
    return this.translateService.get(key, {value: value});
  }

  isAvailable(lang: string): boolean {
    const availableLangs = this.translateService.getLangs();
    return availableLangs.filter(language => {
      return (lang === language) || (lang === language + this._language_country_separator + this._country_code.toLowerCase());
    }).length > 0;

  }

}
