import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppStorageService } from '../../storage/common/app-storage.service';
import { AppConfigService } from '../../config/app-config.service';
import { combineLatest } from 'rxjs';
import { ConfigurationModel } from '../../config/configuration.model';
import { ConfigurationService } from '../../config/configuration.service';
import ModalCode from '../../modal-box/ModalCode';
import { ModalService } from '../../ui/modal.service';
import { filter, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnInit, OnDestroy {

  public showAppBanner = true;
  private androidAppUrl: string;
  private iosAppUrl: string;
  private componentActive = true;

  constructor(
    private appConfig: AppConfigService,
    private configurationService: ConfigurationService,
    private modalService: ModalService,
    private appStorage: AppStorageService,
  ) { }

  ngOnInit() {
    if (!this.appConfig.isMobile || this.appStorage.get('hideAppBanner', AppStorageService.STORES.LOCAL) || this.appStorage.get('hideAppBanner', AppStorageService.STORES.SESSION)) {
      this.showAppBanner = false;
    }

    this.getAppUrls();

    this.modalService.modalActionObservable
      .pipe(
        takeWhile(() => this.componentActive),
        filter((status) => status && this.modalService.modalCode === ModalCode.DOWNLOAD_APP),
      )
      .subscribe(() => this.hideAppBanner(false));
  }

  private getAppUrls(): void {
    combineLatest(
      this.configurationService.getConfItems([this.appStorage.countrySelected, 'AndroidAppUrl']),
      this.configurationService.getConfItems([this.appStorage.countrySelected, 'IosAppUrl'])
    ) .pipe(
      takeWhile(() => this.componentActive),
    )
      .subscribe((configurationItems: ConfigurationModel[]) => {
      if (configurationItems && configurationItems[0] && configurationItems[1]) {
        this.androidAppUrl = configurationItems[0].value;
        this.iosAppUrl = configurationItems[1].value;
      }
    });
  }

  public downloadApp() {
    if (this.appConfig.isIos) {
      window.open(this.iosAppUrl, '_blank');
    } else if (this.appConfig.isAndroid) {
      window.open(this.androidAppUrl, '_blank');
    } else {
      this.openModal();
    }
  }

  public openModal() {
    this.modalService.modalCode = ModalCode.DOWNLOAD_APP;
    this.modalService.openModal('feed_downloadpopin_legend', 'feed_downloadpopin_title', 'feed_downloadpopin_button1', 'feed_downloadpopin_button2', null, {
      type: 'email',
      formControlName: 'email',
      placeholder: 'feed_downloadpopin_formtitle'
    });
  }

  public hideAppBanner(temporarily = true) {
    this.showAppBanner = false;
    this.appStorage.set('hideAppBanner', true, temporarily ? AppStorageService.STORES.SESSION : AppStorageService.STORES.LOCAL);
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }

}
