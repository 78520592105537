import { Component, OnInit } from '@angular/core';
import { AppStorageService } from '../../storage/common/app-storage.service';
import { CookiesService } from '../cookies.service';
import { ConfigurationService } from 'app/shared/config/configuration.service';
import { ConfigurationModel } from 'app/shared/config/configuration.model';
import { TranslationService } from 'app/shared/translation/translation.service';

@Component({
  selector: 'app-cookies-consent',
  templateUrl: './cookies-consent-popin.component.html',
  styleUrls: ['./cookies-consent-popin.component.scss']
})
export class CookiesConsentPopinComponent implements OnInit {

  private cookiesConsentKey = 'cookies_popin_consent';
  private cookiesPolicyVersionConfKey = 'CookiesPolicyVersion';
  private currentCookieVersion = 0;
  public showPopin = false;

  constructor(private appStorageService: AppStorageService,
    private cookiesService: CookiesService,
    private configurationService: ConfigurationService,
    private translationService: TranslationService
  ) { }

  ngOnInit() {

    this.configurationService.getConfItems([this.appStorageService.get(this.appStorageService.SELECTED_COUNTRY, AppStorageService.STORES.SESSION, 0), this.cookiesPolicyVersionConfKey])
      .subscribe(
        (feedback: ConfigurationModel) => {
          if (feedback) {
            this.currentCookieVersion = Number(feedback.value);

            if (this.currentCookieVersion > 0) {
              // A version is defined in the configuration. We must check if the user has already validated the policy.
              // Otherwise, we don't display the consent popin.
              const cookieConsentCookieValue = Number(this.appStorageService.get(this.cookiesConsentKey, AppStorageService.STORES.LOCAL, 0));

              if ((cookieConsentCookieValue === 0) || (cookieConsentCookieValue !== this.currentCookieVersion)) {
                // The user has never accepted the conditions or the version of cookies policy has been updated. We display the popin
                this.showPopin = true;
              }
            }
          }
        },
        e => {
        });
  }

  cookiesPolicyUrl(): string {
    return this.cookiesService.getCookiesPolicyDocumentUrl(this.translationService.language);
  }

  onCookiesAccept() {
    this.appStorageService.set(this.cookiesConsentKey, this.currentCookieVersion, AppStorageService.STORES.LOCAL);
    this.showPopin = false;
  }
}
