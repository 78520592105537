import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomRouterLinkActiveDirective } from './custom-router-link-active.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    CustomRouterLinkActiveDirective
  ],
  exports: [
    CustomRouterLinkActiveDirective
  ]
})
export class CustomRouterLinkActiveModule {
}
