import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { DriverService } from '../../driver/shared/driver.service';
import { Driver } from '../../driver/shared/models/driver.model';
import { UserService } from '../user/user.service';
import { TranslationService } from '../translation/translation.service';
import { FeatureService } from '../feature/feature.service';
import { AppInsightsService } from 'ng2-appinsights';
import { AuthStorageService } from '../storage/auth/auth-storage.service';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { ConfigurationService } from '../config/configuration.service';
import { ConfigurationModel } from '../config/configuration.model';
import { DocumentRef } from '../helper/document.ref';
import { ProspectAccessService } from './prospect-access.service';
import { FeatureConst } from '../feature/feature.consts';
import { of } from 'rxjs/observable/of';
import { AppStorageService } from '../storage/common/app-storage.service';
import { UserRoles } from '../user/userRoles.const';
import { ModalService } from '../ui/modal.service';
import ModalCode from '../../shared/modal-box/ModalCode';


@Injectable()
export class AccessService implements CanActivate {

  private _isUserContextSetup = false;

  constructor(
    private router: Router,
    private driverService: DriverService,
    private userService: UserService,
    private translationService: TranslationService,
    private featureService: FeatureService,
    private appinsightsService: AppInsightsService,
    private prospectAccessService: ProspectAccessService,
    private authStorage: AuthStorageService,
    private configurationService: ConfigurationService,
    private documentRef: DocumentRef,
    private appStorageService: AppStorageService,
    private modalService: ModalService) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.giveAccess(route, state);
  }

  public giveAccess(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return combineLatest(
      this.driverService.driver,
      this.driverService.newDriver,
      this.prospectAccessService.canActivate(route, state))
      .switchMap(([user, newUser, isProspectAllowed]) => {

        const daysBeforePasswordExpires = newUser.passwordExpirationDate ? Math.ceil((new Date(newUser.passwordExpirationDate).getTime() - new Date().getTime()) / (1000 * 3600 * 24)) : null;
        if (daysBeforePasswordExpires && daysBeforePasswordExpires <= 15) {
          const lastShowDate = new Date(this.appStorageService.get('expirationPasswordPopinShown')) || null;
          const showExpirationPopin = !lastShowDate || Math.floor((new Date().getTime() - lastShowDate.getTime()) / (1000 * 3600 * 24)) >= 1;
          if (showExpirationPopin) {
            this.modalService.modalCode = ModalCode.PASSWORD_EXPIRATION;
            this.appStorageService.set('expirationPasswordPopinShown', new Date(), AppStorageService.STORES.LOCAL);
            this.modalService.openModal('password-expiration', 'global-expiration_password_popin-title', 'global-expiration_password_popin-close', 'global-expiration_password_popin-change_now', null, null, daysBeforePasswordExpires);
            this.modalService.modalActionObservable.subscribe(
              status => {
                if (status === true && this.modalService.modalCode === ModalCode.PASSWORD_EXPIRATION) {
                  this.modalService.reset();
                  this.router.navigate(['/feed/reset-password']);
                }
              }
            );
          }
        }

        if (!this._isUserContextSetup) {
          this.setUserContext(user);
          this.accessTracking(user);
        }

        const userRoles = this.userService.getRoles();

        // if user is a prospect, check prospect-access-guard to return true or false
        if (isProspectAllowed !== null) {
          return of(isProspectAllowed);
        }

        // if user is not a driver, a double profile (driver) or a prospect, redirect to manager and returns false
        this.doubleProfileStorage(route.queryParams['activedriver']);
        const isDriver = userRoles.includes('driver');
        const isProspect = userRoles.includes('prospect');
        const isPreferredRoleDriver = this.authStorage.preferredRole && this.authStorage.preferredRole === 'driver';

        if (!isDriver && !isProspect && !isPreferredRoleDriver) {
          return this.featureService.isFeatureActivated(FeatureConst.features.ContinueAsFleetManagerV2)
            .map((isManagerV2) => {
              isManagerV2 ? this.redirectToManagerV2() : this.redirectToSharepoint();
              return false;
            });
        }

        // finally if user is a driver or a double profile/driver, check if they have contracts
        if ((isDriver || isPreferredRoleDriver) && (!user.contracts || user.contracts.length === 0)) {
          this.authStorage.removeAuth();
          this.router.navigate(['/no-contract']);
          return of(false);
        } else {
          return of(true);
        }
      });
  }

  public redirectToSharepoint() {
    const driverRole = this.userService.getRoles().filter(role => {
      return role !== 'driver';
    })[0];

    this.configurationService.getConfItems([this.userService.getCountry(), (driverRole || 'default') + 'SharepointUrl'])
      .subscribe((feedback: ConfigurationModel) => {
        const { value } = feedback;
        this.documentRef.nativeDocument.location = value;
      });
  }

  public redirectToManagerV2() {
    this.configurationService.getConfItems([this.userService.getCountry(), 'ManagerWebsiteUrl'])
      .subscribe(
        (feedback: ConfigurationModel) => {
          this.authStorage.removeAuth();
          const { value } = feedback;
          this.documentRef.nativeDocument.location = value;
        });
  }

  private setUserContext(driver: Driver) {
    this.userService.userId = driver.userId;
    this.userService.culture = driver.culture;
    this.userService.roles = driver.roles;
    this.userService.roles = this.userService.roles.map(r => r.toLowerCase());

    if (this.userService.roles.find(r => r === UserRoles.Driver)) {
      this.appStorageService.currentUserRole = UserRoles.Driver;
    } else if (this.userService.roles.find(r => r === UserRoles.Prospect)) {
      this.appStorageService.currentUserRole = UserRoles.Prospect;
    }

    this.translationService.language = driver.culture;
    this.featureService.updateFeaturesList();
    this._isUserContextSetup = true;
  }

  private accessTracking(driver: Driver) {
    this.appinsightsService.setAuthenticatedUserContext(driver.userId);
  }

  private doubleProfileStorage(activeDriver: string) {
    if (activeDriver && activeDriver === 'true') {
      this.authStorage.preferredRole = 'driver';
    }
    if (this.userService.getRoles().includes('fleetmanager') && !this.authStorage.preferredRole) {
      this.authStorage.preferredRole = 'fleetmanager';
    }
  }
}
