import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class NavigationDirectionService {

  public changeObservable: BehaviorSubject<string> = new BehaviorSubject('forward');

  private _direction = 'forward';

  constructor() {
  }

  public get direction(): string {
    return this._direction;
  }

  public set direction(value) {
    this._direction = value;

    this.changeObservable.next(value);

  }

}
