import { DocumentsEffects } from '../shared/documents.effects';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { PopinService } from '../../shared/ui/popin.service';
import { DocumentsConst } from '../shared/documents.const';
import { InputChoice } from 'app/shared/models/input-choice.model';
import { DriverService } from 'app/driver/shared/driver.service';
import { tap, takeWhile, switchMap, filter } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { FleetManagerLight } from '../shared/models/fleet-manager-light.model';
import { TranslationService } from 'app/shared/translation/translation.service';
import { DocumentSharedWithUsersModelLight } from '../shared/models/document-shared-with-users-light.model';
import { UploadDocumentDto } from '../shared/models/upload-document-dto.model';
import { FeatureService } from 'app/shared/feature/feature.service';
import { FeatureConst } from 'app/shared/feature/feature.consts';
import { UserProfile } from 'app/driver/shared/models/user-profile.model';
import { UserRole } from 'app/driver/shared/models/user-role.model';
import { UserRoles } from 'app/shared/user/userRoles.const';

@Component({
  selector: 'app-add-document',
  templateUrl: './add-document.component.html',
  styleUrls: ['./add-document.component.scss']
})
export class AddDocumentComponent implements OnInit, OnDestroy {
  public isLoading: boolean;
  public form: FormGroup;
  public file: File;
  public availableFleetManagers: InputChoice[];
  public isSharedDocumentActivated = false;
  public fileFormats = DocumentsConst.fileFormats;
  private isComponentActive = true;
  private userRole: UserRole;

  constructor(
    public popinService: PopinService,
    private driverService: DriverService,
    private documentEffects: DocumentsEffects,
    private featureService: FeatureService,
    private translationService: TranslationService) {
  }

  ngOnInit() {
    this.form = new FormGroup({
      file: new FormControl(null, [Validators.required]),
      title: new FormControl('', [Validators.required]),
      comment: new FormControl('', []),
      fleetManagers: new FormControl([], []),
      legal: new FormControl(false, [])
    });

    this.form.get('fleetManagers').valueChanges.pipe(
      takeWhile(() => this.isComponentActive),
      tap(data => {
        data.length ? this.form.get('legal').setValidators([Validators.requiredTrue])
          : this.form.get('legal').setValidators([]);
        this.form.get('legal').updateValueAndValidity();
      })).subscribe();

    combineLatest([
      this.driverService.newDriverStream.pipe(
        tap((userProfile: UserProfile) => this.userRole = userProfile.userRoles.find((userRole: UserRole) => {
          return (userRole.role.toLocaleLowerCase() === UserRoles.Driver || userRole.role.toLocaleLowerCase() === UserRoles.Prospect)
        }))
      ),
      this.featureService.isFeatureActivated(FeatureConst.features.sharedDocuments).pipe(
        tap((isActivated: boolean) => this.isSharedDocumentActivated = isActivated),
        filter(isActivated => isActivated),
        switchMap(() => this.driverService.getFleetManagers()),
        tap((fleetManagers) => {
          fleetManagers ? this.availableFleetManagers = FleetManagerLight.toInputChoice([...fleetManagers])
            : this.availableFleetManagers = [];
        })
      ),
    ]).pipe(
      takeWhile(() => this.isComponentActive),
    ).subscribe();

    this.popinService.opened(true);
  }

  ngOnDestroy() {
    this.popinService.opened(false);
    this.isComponentActive = false;
  }

  public onBackClick(): void {
    this.popinService.opened(false);
  }

  public fileChange(event): void {
    this.file = event.target.files[0];
    this.form.controls.file.setValue(this.file ? this.file.name : null);
    const formatName = '.' + this.file.name.split('.').pop();
    if (!DocumentsConst.fileFormats.includes(formatName)) {
      this.form.controls.file.setErrors({ format: !DocumentsConst.fileFormats.includes(formatName) });
    }
    if (this.file.size > DocumentsConst.maximumFileSize) {
      this.form.controls.file.setErrors({ fileSize: this.file.size > DocumentsConst.maximumFileSize });
    }
  }

  public onSubmit(): void {
    if (this.form.valid) {
      this.isLoading = true;
      const uploadDocumentRequest: UploadDocumentDto = this.constructDataToSend();
      const documentSharedModelWith = DocumentSharedWithUsersModelLight.toDocumentSharedWithUsersModel(this.form.get('fleetManagers').value);
      const formData = new FormData();
      formData.append('fileData', JSON.stringify(uploadDocumentRequest));
      formData.append('file', this.file);
      this.documentEffects.uploadDocumentAction(formData, documentSharedModelWith)
        .subscribe(() => {
          this.isLoading = false;
          this.popinService.opened(false);
        },
          () => {
            this.isLoading = false;
          });
    }
  }

  private constructDataToSend(): UploadDocumentDto {
    const fleetManagers = this.form.get('fleetManagers').value;

    return {
      title: this.form.get('title').value,
      comment: this.form.get('comment').value ? this.form.get('comment').value : null,
      culture: this.translationService.language,
      roles: fleetManagers.length ? ['FleetManager'] : [],
      companyIds: [this.userRole.company.id],
      userIds: fleetManagers.length ? fleetManagers.map(fleetManager => fleetManager.value) : []
    } as UploadDocumentDto;
  }
}

