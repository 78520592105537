﻿import { TranslationService } from '../../translation/translation.service';
import { FormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import Timer = NodeJS.Timer;

@Component({
  selector: 'app-select-menu',
  templateUrl: './select-menu.component.html',
  styleUrls: ['./select-menu.component.scss']
})
export class SelectMenuComponent implements OnInit, OnDestroy {

  public showList: boolean;

  @Input()
  public placeholder: string;

  @Input()
  public datas;

  @Input()
  public name = 'select';

  @Input()
  public currentIndex = -1;

  @Input()
  public form: FormGroup;

  @Input()
  public selectId: string;

  @Output()
  public update = new EventEmitter();

  @Output()
  public selectClick = new EventEmitter();

  public currentLabel: string;
  public timer: Timer = null;

  constructor(private translationService: TranslationService) {
  }

  ngOnInit() {
    if (this.currentIndex >= 0) {
      this.updateSelect(this.datas[this.currentIndex], this.currentIndex);
    }
  }

  updateSelect(obj, index) {
    this.translationService.translate(obj.label).subscribe(
      translation => {
        this.currentLabel = translation;
        this.currentIndex = index;
        this.update.emit(obj);
      }
    );
  }

  ngOnDestroy() {
    this.currentLabel = null;
    this.currentIndex = -1;
  }

  resetSelect() {
    this.currentLabel = null;
    this.currentIndex = -1;
  }

  onFocusOut(time = 200) {
    this.clearTimer();

    this.timer = setTimeout(() => {
      this.showList = false;
      this.timer = null;

    }, time);
  }

  onClick() {
    this.showList = !this.showList;
    this.selectClick.emit(this.selectId);
  }

  clearTimer() {

    if (this.timer !== null) {
      clearTimeout(this.timer);
      this.timer = null;
    }

  }
}
