import { Contract } from '../models/contract.model';
import { ContractConsolidatedStatus, ContractConsolidatedStatusType } from './contract-status.const';
import { CarMake } from '../models/car-make.model';

export function contractsContainsStatusOfType(contracts: Contract[], status: ContractConsolidatedStatusType): boolean {
  return contracts.some((contract) => {
    return contractIsStatusOfType(contract, status);
  });
}

// TODO contract type is any for now as we test status or contractStatus. It should be UserRoleContract
export function contractIsStatusOfType(contract: any, status: ContractConsolidatedStatusType): boolean {
  const consStatus = ContractConsolidatedStatusType[status];
  return ContractConsolidatedStatus[consStatus].includes(contract.status) || ContractConsolidatedStatus[consStatus].includes(contract.contractStatus);
}

export function carMakeIsStatusOfType(carMake: CarMake, status: ContractConsolidatedStatusType): boolean {
  const consolidatedStatus = ContractConsolidatedStatusType[status];
  return ContractConsolidatedStatus[consolidatedStatus].includes(carMake.stateName);
}

export function contractConsolidatedStatusFromString(status: string) {
  if (status === null || status === undefined) {
    return undefined;
  }

  switch (status.toLowerCase().trim()) {
    case 'active':
      return ContractConsolidatedStatusType.Active;
    case 'order':
      return ContractConsolidatedStatusType.Order;
    case 'terminated':
      return ContractConsolidatedStatusType.Terminated;
    default:
      return undefined;
  }
}
