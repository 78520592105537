import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class StickyHeaderFormService {

  public hasCanceled: BehaviorSubject<boolean>;
  public hasSubmitted: BehaviorSubject<boolean>;

  constructor() {
    this.hasCanceled = new BehaviorSubject<boolean>(false);
    this.hasSubmitted = new BehaviorSubject<boolean>(false);
  }


  cancelChanges() {
    this.hasCanceled.next(true);
  }

  saveChanges() {
    this.hasSubmitted.next(true);
  }

}
