import { Injectable } from '@angular/core';
import 'js-marker-clusterer';

import { PointOfService } from '../../pos/shared/pos.model';
import { AppConfigService } from '../config/app-config.service';
import LatLngBounds = google.maps.LatLngBounds;

declare const MarkerClusterer;


@Injectable()
export class MapGoogleService {
  private _oldMarkers: Array<google.maps.Marker> = [];

  private _map: google.maps.Map;
  private _markers: Array<google.maps.Marker> = [];
  private _markerMyPosition: google.maps.Marker;
  private _myLatitude = 0;
  private _myLongitude = 0;
  private _markerClusterer: any = null;

  constructor(public appConfig: AppConfigService) {
  }

  get map(): google.maps.Map {
    return this._map;
  }

  get markers(): Array<google.maps.Marker> {
    return this._markers;
  }

  get markerMyPosition(): google.maps.Marker {
    return this._markerMyPosition;
  }

  get myLatitude(): number {
    return this._myLatitude;
  }

  set myLatitude(n: number) {
    this._myLatitude = n;
  }

  get myLongitude(): number {
    return this._myLongitude;
  }

  set myLongitude(n: number) {
    this._myLongitude = n;
  }

  get markerClusterer(): any {
    return this._markerClusterer;
  }

  initMap(el: HTMLElement, mapOptions: any) {
    this._map = new google.maps.Map(el, mapOptions);
  }

  destroy() {
    this.removeCluster();
    this.removeMarkers();
    google.maps.event.clearInstanceListeners(this._map);
  }

  resize() {
    google.maps.event.trigger(this._map, 'resize');
  }

  setCenter(latLng: any) {
    if (this._map != null) {
      this._map.panTo(latLng);
    }
  }

  getCenter() {
    return this._map.getCenter();
  }

  setBounds(bounds: LatLngBounds) {
    this._map.fitBounds(bounds);
  }

  setZoom(zoom: number) {
    if (this._map != null) {
      this._map.setZoom(zoom);
    }
  }

  setZoomMore() {
    this.setZoom(this.getZoom() + 1);
  }

  setZoomLess() {
    this.setZoom(this.getZoom() - 1);
  }

  getZoom() {
    return this._map.getZoom();
  }

  /*
   init / redraw / remove cluster
   */
  initCluster(mcOptions) {
    this._markerClusterer = new MarkerClusterer(this._map, this._markers, mcOptions);
  }

  redrawCluster() {
    this._markerClusterer.redraw();
  }

  removeCluster() {
    if (this._markerClusterer !== null) {
      google.maps.event.clearInstanceListeners(this._markerClusterer);
      this._markerClusterer.clearMarkers();
      this._markerClusterer = null;
    }
  }

  /*
   marker
   */
  addMarker(lat: number, lng: number, image: any, title?: string): google.maps.Marker {

    const position = new google.maps.LatLng(lat, lng, false);
    const marker = new google.maps.Marker({
      map: this._map,
      position: position,
      visible: true,
      icon: image,
      title: title
    });

    this._markers.push(marker);
    if (this._markerClusterer !== null) {
      this._markerClusterer.addMarker(marker, true);
    }

    return marker;
  }

  getMarker(lat: number, lng: number): google.maps.Marker {

    for (const marker of this._markers) {
      if (marker !== null) {
        if (marker.getPosition().lat().toFixed(5) === lat.toFixed(5) && marker.getPosition().lng().toFixed(5) === lng.toFixed(5)) {
          return marker;
        }
      }
    }

    return null;
  }

  removeMarkers(markersNew?: Array<PointOfService>) {

    if (this._markers.length) {

      const arr = [];

      for (const marker of this._markers) {

        let idem = false;

        if (markersNew) {
          for (const m of markersNew) {  // if marker exists, don't remove
            if (
              m.latitude.toFixed(5) === marker.getPosition().lat().toFixed(5) &&
              m.longitude.toFixed(5) === marker.getPosition().lng().toFixed(5)
            ) {
              idem = true;
            }
          }
        }

        if (!idem) {
          google.maps.event.clearInstanceListeners(marker);
          marker.setMap(null);
          if (this._markerClusterer !== null) {
            this._markerClusterer.removeMarker(marker);
          }
        } else {
          arr.push(marker);
        }
      }

      this._markers = arr;
    }

  }

  addMarkerMyPosition(lat: number, lng: number, image: any) {
    this._myLatitude = lat;
    this._myLongitude = lng;

    const position = new google.maps.LatLng(lat, lng, false);
    this._markerMyPosition = new google.maps.Marker({
      map: this._map,
      position: position,
      visible: true,
      icon: image,
      draggable: false
    });

    this._map.setCenter(position);

    return this._markerMyPosition;
  }

  updateMarkerMyPosition(lat: number, lng: number) {
    this._myLatitude = lat;
    this._myLongitude = lng;
    const position = new google.maps.LatLng(lat, lng, false);
    this._markerMyPosition.setPosition(position);
  }

}
