import { Contract } from '../../driver/shared/models/contract.model';
import { ActivatedRoute } from '@angular/router';
import { DriverService } from '../../driver/shared/driver.service';
import { AfterViewChecked, Component, ElementRef, EventEmitter, HostListener, Output, ViewChild } from '@angular/core';
import {MaskComponent} from '../mask/mask.component';

const MIN_SWIPE = 5;
const SPEED_CAROUSSEL = 250;

@Component({
  selector: 'app-header-cars-caroussel',
  templateUrl: './header-cars-caroussel.component.html',
  styleUrls: ['./header-cars-caroussel.component.scss']
})
export class HeaderCarsCarousselComponent implements AfterViewChecked {

  public currentIndex = 0;
  @ViewChild('caroussel')
  public caroussel;
  public driverContracts: Contract[];
  @Output()
  public updateRoute = new EventEmitter();
  private numItems = 0;
  private wItem = 0;
  private positionLeft = 0;
  private positionLeftPrev = 0;
  private delta = 0;

  constructor(private route: ActivatedRoute,
              private element: ElementRef,
              private driverService: DriverService) {
    const driverContractId = (this.route.snapshot.params as any).contractId;

    this.driverService.driverStream.subscribe(driver => {
      if (driver && driverContractId) {
        // Sort cars by "priority" of their status
        this.driverContracts = driver.contracts.sort((a, b) => {
          return this.getContractRank(a) - this.getContractRank(b);
        });
        this.numItems = this.driverContracts.length;

        this.driverContracts.forEach(
          (contract, index) => {

            if (contract.id === driverContractId) {
              this.currentIndex = index;
              setTimeout(() => {
                this.moveCaroussel(0);
              }, 0);
            }
          }
        );
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize($event) {
    this.resize();
    this.moveCaroussel(0);
  }

  ngAfterViewChecked() {
    this.resize();
  }

  onClickVehicle(index, currentContractId) {

    if (Math.abs(this.delta) <= MIN_SWIPE) {
      if (this.currentIndex !== index) {
        this.currentIndex = index;
        this.moveCaroussel(SPEED_CAROUSSEL);
      }
    }

    this.delta = 0;
  }

  /*
    gesture mouseup / touchend
  */
  public onUp(event) {

    if (this.numItems <= 1 || this.delta === 0) {
      return;
    }

    let speed = SPEED_CAROUSSEL;

    if (this.positionLeft < this.positionLeftPrev - MIN_SWIPE) {

      if (this.currentIndex < this.numItems - 1) {
        this.currentIndex = this.currentIndex + 1;
      }

    } else if (this.positionLeft > this.positionLeftPrev + MIN_SWIPE) {

      if (this.currentIndex > 0) {
        this.currentIndex = this.currentIndex - 1;
      }

    } else {

      speed = SPEED_CAROUSSEL / 2;

    }

    this.moveCaroussel(speed);
  }

  /*
    gesture pan
  */
  onMove(event) {

    if (this.numItems <= 1) {
      return;
    }

    this.positionLeft = event.deltaX + this.positionLeftPrev;
    this.delta = event.deltaX;

    this.caroussel.nativeElement.style.transitionDuration = '0ms';
    this.caroussel.nativeElement.style.WebkitTransitionDuration = '0ms';
    this.caroussel.nativeElement.style.MozTransitionDuration = '0ms';
    this.caroussel.nativeElement.style.OTransitionDuration = '0ms';

    this.caroussel.nativeElement.style.transform = 'translate3d(' + Math.floor(this.positionLeft) + 'px,0,0)';
    this.caroussel.nativeElement.style.WebkitTransform = 'translate3d(' + Math.floor(this.positionLeft) + 'px,0,0)';
    this.caroussel.nativeElement.style.MozTransform = 'translate3d(' + Math.floor(this.positionLeft) + 'px,0,0)';
    this.caroussel.nativeElement.style.OTransform = 'translate3d(' + Math.floor(this.positionLeft) + 'px,0,0)';

  }

  /*
    gesture endTransition
  */
  onTransitionEnd(event) {

    if (event.propertyName === 'transform') {
      this.updateRoute.emit(this.driverContracts[this.currentIndex].id);
    }
  }

  /**
   * Get the priority rank of a contract
   * Low ranks will be displayed first
   */
  private getContractRank(contract) {
    const status = contract.contractStatus;
    const priorityList = [
      'VehicleOnRoad',
      'VehicleDelivered',
      'VehicleAvailable',
      'VehicleRegistered',
      'VehicleFactored',
      'CommandConfirmed',
      'CommandSaved',
      'VehicleReturned',
      'VehicleSold'
    ];
    const rank = priorityList.indexOf(status) >= 0 ? priorityList.indexOf(status) : priorityList.length;
    return rank;
  }

  /*
    private methode move Caroussel
  */
  private moveCaroussel(speed) {

    const l = -(this.currentIndex * this.wItem);

    this.positionLeftPrev = l;
    this.caroussel.nativeElement.style.transitionDuration = speed + 'ms';
    this.caroussel.nativeElement.style.WebkitTransitionDuration = speed + 'ms';
    this.caroussel.nativeElement.style.MozTransitionDuration = speed + 'ms';
    this.caroussel.nativeElement.style.OTransitionDuration = speed + 'ms';

    this.caroussel.nativeElement.style.transform = 'translate3d(' + Math.floor(l) + 'px,0,0)';
    this.caroussel.nativeElement.style.WebkitTransform = 'translate3d(' + Math.floor(l) + 'px,0,0)';
    this.caroussel.nativeElement.style.MozTransform = 'translate3d(' + Math.floor(l) + 'px,0,0)';
    this.caroussel.nativeElement.style.OTransform = 'translate3d(' + Math.floor(l) + 'px,0,0)';

  }


  /*
    private methode resize
  */
  private resize() {
    if (this.caroussel.nativeElement.querySelector('li')) {
      this.wItem = this.caroussel.nativeElement.querySelector('li').offsetWidth + 1;
    }
  }


}
