import { NgModule } from '@angular/core';
import { AppStorageService } from './common/app-storage.service';
import { PosStorageService } from './pos/pos-storage.service';
import { TranslationStorageService } from './translation/translation-storage.service';

@NgModule({
  providers: [
    AppStorageService,
    PosStorageService,
    TranslationStorageService
  ]
})
export class StorageModule {
}
