import { ProgressbarSimpleComponent } from './progressbar-simple.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ProgressbarSimpleComponent],
  exports: [ProgressbarSimpleComponent]
})
export class ProgressbarSimpleModule {
}
