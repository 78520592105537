import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent {

  @Input()
  public rate: number;

  @Input()
  public ald: boolean;

  @Input()
  public big: boolean;

  @Input()
  public ratesCount: number;

  public nb: Array<number> = [0, 1, 2, 3, 4];

  constructor() {
  }

}
