import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { DriverService } from '../../driver/shared/driver.service';

@Injectable()
export class ProspectAccessService implements CanActivate {

  constructor(private driverService: DriverService) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | null> {
    return this.driverService.driver.map(user => {
      const acceptProspect: boolean = route.data['acceptProspect'];
      let canAccess = null;
      if (user && user.roles.every(role => role.toLowerCase() === 'prospect')) {
        if (acceptProspect !== undefined) {
          canAccess = acceptProspect;
        } else {
          canAccess = false;
        }
      }
      return canAccess;
    });
  }
}
