import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) {
      return 'no phone number';
    }
    const arr = value.split('');
    let str = '';

    arr.forEach((val, index) => {
      if (index % 2 === 0) {
        str += ' ';
      }
      str += val;
    });

    return str;
  }

}
