import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'max'
})
export class MaxPipe implements PipeTransform {

  transform(value: number, value2: number): any {
    let max = value;

    if (value < value2) {
      max = value2;
    }

    return max;
  }

}
