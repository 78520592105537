import ContractStatus from '../../../cars/shared/cars.contractstatus';

export const ContractConsolidatedStatus = {
  Active: [
    ContractStatus.VehicleOnRoad,
    ContractStatus.ContractTerminating,
  ],
  Order: [
    ContractStatus.ContractOrder,
    ContractStatus.CommandSaved,
    ContractStatus.CommandConfirmed,
    ContractStatus.VehicleDelivered,
    ContractStatus.VehicleFactored,
    ContractStatus.VehicleRegistered,
    ContractStatus.VehicleReturned
  ],
  Terminated: [
    ContractStatus.ContractTerminated,
    ContractStatus.VehicleSold,
  ]
};

export enum ContractConsolidatedStatusType {
  Active,
  Order,
  Terminated
}
