import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable, OnDestroy } from '@angular/core';
import { AppStorageService } from '../storage/common/app-storage.service';
import { ConfigurationService } from './configuration.service';
import { ConfigurationModel } from './configuration.model';
import { interval } from 'rxjs/observable/interval';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { of } from 'rxjs/observable/of';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class MaintenanceService implements CanActivate, OnDestroy {

  // five minutes
  private readonly intervalValue = 300000;
  private readonly intervalSubscription: Subscription;

  private isInMaintenance: boolean = undefined;
  private countryCode: string;

  public maintenanceSubject = new Subject<boolean>();

  constructor(private appStorageService: AppStorageService,
              private configurationService: ConfigurationService,
              private router: Router) {

    this.getMaintenanceStatusFromConfiguration().subscribe(() => {
    });

    interval(this.intervalValue)
      .subscribe(() => {
        this.getMaintenanceStatusFromConfiguration().subscribe(() => {
        });
      });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (this.isInMaintenance === undefined) {
      return this.getMaintenanceStatusFromConfiguration()
        .map(() => {
          return !this.isInMaintenance;
        });
    } else {
      return of(!this.isInMaintenance);
    }

  }

  private redirectToMaintenancePage() {
    if (this.isInMaintenance === true) {
      this.router.navigate(['/maintenance']);
    }
  }

  public getMaintenanceStatusFromConfiguration(): Observable<boolean> {
    this.countryCode = this.appStorageService.countrySelected;
    if (this.countryCode === null || this.countryCode === undefined) {
      return of(false);
    }

    return this.configurationService.getConfItems([this.countryCode, 'IsInMaintenance'])
      .map(configuration => {
        return this.setMaintenanceStatus(configuration);
      })
      .catch(() => {
        return of(false);
      });
  }

  private setMaintenanceStatus(configuration: ConfigurationModel): boolean {
    if (configuration) {
      const newMaintenanceStatus = JSON.parse(configuration.value);
      if (this.isInMaintenance !== newMaintenanceStatus) {
        this.isInMaintenance = newMaintenanceStatus;
        this.maintenanceSubject.next(this.isInMaintenance);
        this.redirectToMaintenancePage();
      }
    }
    return this.isInMaintenance;
  }

  ngOnDestroy(): void {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }
}
