import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PopinService } from '../../shared/ui/popin.service';
import { DriverService } from '../../driver/shared/driver.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { HelpService } from '../shared/help.service';
import { SelectMenuComponent } from '../../shared/app-forms/select-menu/select-menu.component';
import { TranslationService } from '../../shared/translation/translation.service';
import { contractIsStatusOfType } from '../../driver/shared/contract/contract-status.helper';
import { ContractConsolidatedStatusType } from '../../driver/shared/contract/contract-status.const';
import { GTM_EVENTS } from 'app/shared/gtm/gtm-events.const';
import { GtmService } from '../../shared/gtm/gtm.service';
import { UserProfile } from 'app/driver/shared/models/user-profile.model';
import { UserRoleContract } from 'app/driver/shared/models/user-contract.model';
import { ContactRequest } from '../shared/models/create-case-request.model';

@Component({
  selector: 'app-help-case-request',
  templateUrl: './help-case-request.component.html',
  styleUrls: ['./help-case-request.component.scss']
})
export class HelpCaseRequestComponent implements OnDestroy, OnInit {

  public newUserProfile: UserProfile;
  public form: FormGroup;
  public submitStatus = 'initial';
  public submitted = false;
  public error: number;

  public vehicleList = [];
  public vehicleSelected = 0;

  public serviceList = [];
  public categoryList = [];
  public subCategoryList = [];
  public readonly GTM_EVENTS = GTM_EVENTS;

  @ViewChild('service') serviceField: SelectMenuComponent;
  @ViewChild('category') categoryField: SelectMenuComponent;
  @ViewChild('subCategory') subCategoryField: SelectMenuComponent;

  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private driverService: DriverService,
    private helpService: HelpService,
    private popinService: PopinService,
    private gtmService: GtmService,
    private translationService: TranslationService) {
  }

  ngOnInit() {
    this.popinService.opened(true);
    this.getDriver();
    this.getServices();
  }

  ngOnDestroy() {
    this.popinService.opened(false);
  }

  public updateVehicleList(obj): void {
    this.form.get('vehicle').setValue(obj.id);
  }

  public updateServiceList(obj): void {
    this.form.get('service').setValue(obj.id);
    this.categoryList = [];
    this.subCategoryList = [];
    this.resetSelect(this.categoryField);
    this.resetSelect(this.subCategoryField);
    this.form.removeControl('category');
    this.form.removeControl('subCategory');

    if (obj.subjects && obj.subjects.length > 0) {
      this.form.addControl('category', new FormControl('', Validators.required));
      obj.subjects.forEach((category) => {
        this.categoryList.push(category);
      });
    }
  }

  public updateCategoryList(obj): void {
    this.form.get('category').setValue(obj.id);

    this.subCategoryList = [];
    this.resetSelect(this.subCategoryField);
    this.form.removeControl('subCategory');

    if (obj.subSubjects && obj.subSubjects.length > 0) {
      this.form.addControl('subCategory', new FormControl('', Validators.required));
      obj.subSubjects.forEach((subCategory) => {
        this.subCategoryList.push(subCategory);
      });
    }
  }

  public updateSubCategoryList(obj): void {
    this.form.get('subCategory').setValue(obj.id);
  }

  public selectClick(selectId: string): void {
    switch (selectId) {
      case 'service':
        this.hideSelectList(this.categoryField);
        this.hideSelectList(this.subCategoryField);
        break;
      case 'category':
        this.hideSelectList(this.serviceField);
        this.hideSelectList(this.subCategoryField);
        break;
      case 'subCategory':
        this.hideSelectList(this.serviceField);
        this.hideSelectList(this.categoryField);
        break;
    }
  }

  public onSubmit(): void {
    if (!this.form.valid) {
      return;
    }

    this.submitStatus = 'pending';

    const requestParams: ContactRequest = {
      contractId: this.form.get('vehicle').value,
      category: this.form.get('service').value,
      subject: this.form.get('category') ? this.form.get('category').value : null,
      subSubject: this.form.get('subCategory') ? this.form.get('subCategory').value : null,
      details: this.form.get('message').value,
      culture: '',
    }

    this.helpService.createCaseRequest(requestParams)
      .subscribe(
        response => {
          this.gtmService.sendEvent(GTM_EVENTS.assistanceCreateACase);
          this.submitStatus = 'success';
        },
        error => {
          this.error = error.status;
          this.submitStatus = 'initial';
        }
      );
  }

  public onBackClick(): void {
    this.location.back();
  }

  private getDriver(): void {
    this.driverService.newDriverStream
      .subscribe((newDriver: UserProfile) => {
        this.newUserProfile = newDriver;
        this.generateVehicleList();
        this.generateFormGroup();
      });
  }

  private getServices(): void {
    this.helpService.getCategories()
      .subscribe(categories => {
        this.serviceList = categories;

        if (this.translationService.language.toLowerCase() === 'en-us') {
          this.serviceList.forEach(service => {
            service.label = service.id;
            service.subjects = this.translateSubjectsLabels(service.subjects);
          });
        }
      });
  }

  private generateVehicleList(): void {
    this.vehicleList = this.driverService.getNewUserRole(this.newUserProfile).contracts.filter((contract: UserRoleContract) => {
      return contractIsStatusOfType(contract, ContractConsolidatedStatusType.Active);
    }).map((contract: UserRoleContract) => {
      return {
        id: contract.reference,
        label: `${contract.vehicle.brand} ${contract.vehicle.plate}`
      };
    });

    if (this.vehicleList.length === 1) {
      this.vehicleSelected = 0;
    }
  }

  private generateFormGroup(): void {
    this.form = this.formBuilder.group({
      vehicle: ['', Validators.required],
      service: ['', Validators.required],
      category: ['', Validators.required],
      message: ['', Validators.required],
    });
  }

  private translateSubjectsLabels(subjects: any[]): any[] {
    if (!subjects || subjects.length === 0) {
      return [];
    }

    return subjects.map(subject => {
      subject.label = subject.id;
      subject.subSubjects = this.translateSubSubjectsLabels(subject.subSubjects);
      return subject;
    });
  }

  private translateSubSubjectsLabels(subSubjects: any[]): any[] {
    if (!subSubjects || subSubjects.length === 0) {
      return [];
    }

    return subSubjects.map(subSubject => {
      subSubject.label = subSubject.id;
      return subSubject;
    });
  }

  private hideSelectList(selectField: SelectMenuComponent): void {
    if (selectField) {
      selectField.showList = false;
    }
  }

  private resetSelect(selectField: SelectMenuComponent): void {
    if (selectField) {
      selectField.resetSelect();
    }
  }
}
