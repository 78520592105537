import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationDirectionService } from '../../shared/router-transitions/navigation-direction.service';

@Component({
  selector: 'app-allow-notification',
  templateUrl: './allow-notification.component.html',
  styleUrls: ['./allow-notification.component.scss']
})
export class AllowNotificationComponent {

  constructor(private router: Router,
              private navigationDirection: NavigationDirectionService) {
  }

  allowNotification() {
    this.navigationDirection.direction = 'forward';
    this.router.navigate(['/feed']);
  }

  denyNotification() {
    this.navigationDirection.direction = 'forward';
    this.router.navigate(['/feed']);
  }

}
