import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ViewBox } from '../models/view-box.model';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss']
})
export class SvgIconComponent implements OnInit {

  public baseHref: string;

  /**
   * Name of the symbol in the SVG sprite
   */
  @Input()
  public name: string;


  @Input()
  public module: string;

  /**
   * Desired display width
   */
  @Input()
  @HostBinding('style.width')
  public width: string;

  /**
   * Desired display height
   */
  @Input()
  @HostBinding('style.height')
  public height: string;

  /**
   * Viewbox as defined in the symbol/original SVG file. required
   * @type {{top: number; left: number; width: number; height: number}}
   */
  @Input()
  public viewBox: ViewBox = {
    top: 0,
    left: 0,
    width: 20,
    height: 20
  };

  @Input()
  public extraClasses: string[] = [];

  public svgPath: string;

  constructor() {
    this.baseHref = window.location.href.toString();
  }
  ngOnInit(): void {
    this.svgPath = this.module ?  `${window.location.origin}/${this.module}#${this.name}` : `${this.baseHref}#${this.name}`;
  }

}
