import { AuthConfig } from '../../common/api.settings';
import { Injectable } from '@angular/core';
import { AppStorageService } from '../common/app-storage.service';

@Injectable()
export class AuthStorageService {

  constructor(private storage: AppStorageService) {
  }

  get auth() {
    return this.rememberAuth;
  }

  get rememberAuth() {
    return this.storage.get(AuthConfig.REMEMBER_AUTH_KEY_STORAGE);
  }

  set rememberAuth(authDatas) {
    this.storage.set(AuthConfig.REMEMBER_AUTH_KEY_STORAGE, authDatas);
  }

  get accessToken(): string {
    return this.auth[AuthConfig.ACCESS_TOKEN_KEY_STORAGE] || false;
  }

  get refreshToken(): string | boolean {
    return this.auth[AuthConfig.REFRESH_TOKEN_KEY_STORAGE] || false;
  }

  get tokenExpires(): Date | boolean {
    return new Date(this.auth[AuthConfig.TOKEN_EXPIRES_KEY_STORAGE]) || false;
  }

  get userName(): string {
    return this.storage.get('username');
  }

  set userName(userName: string) {
    this.storage.set('username', userName);
  }

  get preferredRole(): string {
    return this.storage.get('preferredRole');
  }

  set preferredRole(role: string) {
    this.storage.set('preferredRole', role);
  }

  removeAuth() {
    this.storage.remove(AuthConfig.REMEMBER_AUTH_KEY_STORAGE);
    this.storage.remove('preferredRole');
  }

}
