import { NgModule } from '@angular/core';
import { AldIconModule, AldMatButtonLoadingModule } from '@ald/ui';

@NgModule({
  exports: [
    AldIconModule,
    AldMatButtonLoadingModule,
  ]
})
export class MyAldUiModule { }
