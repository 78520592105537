import { ActivatedRouteSnapshot } from '@angular/router';

export function flattenRouteData(snapshot: ActivatedRouteSnapshot, targetObj = {}): any {

  // Copy this level's data to the target object
  if (snapshot.data) {
    for (const key in snapshot.data) {
      if (snapshot.data.hasOwnProperty(key)) {
        targetObj[key] = snapshot.data[key];
      }
    }
  }

  // Recursion for all sub-routes
  if (snapshot.children) {
    snapshot.children.forEach((childRoute) => {
      flattenRouteData(childRoute, targetObj);
    });
  }

  return targetObj;

}
