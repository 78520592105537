import { InputChoice } from 'app/shared/models/input-choice.model';
import { FleetManagerLight } from './fleet-manager-light.model';

export class DocumentSharedWithUsersModelLight {
    userId: string;
    name: string;

    static toDocumentSharedWithUsersModel(inputChoices: InputChoice[]): DocumentSharedWithUsersModelLight[] {
      if (!inputChoices) {
        return [];
      }
      return inputChoices.map((inputChoice) => {
        return {
          name: inputChoice.label,
          userId: inputChoice.value
        };
      });
    }

    static toFleetManager(documents: DocumentSharedWithUsersModelLight[]): FleetManagerLight[] {
      if (!documents) {
        return [];
      }
      return documents.map((doc) => {
        return {
         id: doc.userId,
         name: doc.name,
         email: ''
        };
      });
    }
}
