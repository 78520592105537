import { Component, Input } from '@angular/core';
import { AccordionItemComponent } from '../accordion-item/accordion-item.component';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent {

  @Input() multiple: boolean;

  public tabs: AccordionItemComponent[] = [];

  constructor() {
  }

  addTab(tab: AccordionItemComponent) {
    this.tabs.push(tab);
  }

}
