import { Component, EventEmitter, Output } from '@angular/core';
import { animMaskAnimation } from '../animations/anim-mask-animations';

@Component({
  selector: 'app-mask',
  templateUrl: './mask.component.html',
  styleUrls: ['./mask.component.scss'],
  animations: [
    animMaskAnimation
  ]
})
export class MaskComponent {

  public showMask: boolean;
  @Output()
  public close = new EventEmitter();

  constructor() {
  }

  set show(b: boolean) {
    this.showMask = b;
  }

  toggleMask() {
    this.showMask = !this.showMask;
  }

  onClose(event) {
    this.close.emit(null);
  }

}
