export const environment = {
  production: false,
  envName: 'INT',
  gmapApiKey: 'AIzaSyBJg2-x5tHyxl7z-Cq8Na_kUl3GsZwGNXM',
  apigatewayUrl: 'https://apidriver.integ.aldautomotive.com',
  adfsUrl: 'https://integ.authenticationportal.com/adfs/oauth2/authorize?response_type=code&client_id=801e529f-0798-4e00-9a57-75098c115578&resource=https://driver-pt.integ.aldautomotive.com&redirect_Uri=https://driver-pt.integ.aldautomotive.com/onlogin',
  translateLoaderPrefix: '/apigateway/Translation/api/Translations/myalddriver/',
  appInsightInstrumentationKey: '9ce87323-ee2b-4b64-a568-76cbccf4ddab',
  assetsPath: '/assets/ald',
  siteKey: '6LcvoMUUAAAAAEMoR7cW-WbTWjyEPyXqlgqkYrOx',
  publicDocumentsBaseUrl: 'https://myalddocumentsint.blob.core.windows.net/public',
  gtmKey: 'GTM-56DPNWF',
};
