import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-available-soon',
  templateUrl: './available-soon.component.html',
  styleUrls: ['./available-soon.component.scss']
})
export class AvailableSoonComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
