import { Component } from '@angular/core';
import { AuthService } from 'app/shared/authentication/auth.service';
import { environment } from '../../../../environments/environment';
import { AppStorageService } from 'app/shared/storage/common/app-storage.service';

@Component({
  selector: 'app-user-error-page',
  templateUrl: './user-error-page.component.html',
  styleUrls: ['../page-not-found/page-not-found.component.scss']
})
export class UserErrorPageComponent {

  public logoutIframe;
  public isLoadedInsideIFrame: boolean = window.self !== window.top;

  constructor(
    private storage: AppStorageService,
    private authService: AuthService) {
  }

  public logOut(): void {
    window.addEventListener('message', (e: any) => {
      const { data: eventData } = e;
      if (eventData.waitingForLogin) {
        this.storage.remove(this.storage.CURRENT_ROLE, AppStorageService.STORES.SESSION);
        this.authService.clearAuthentication(true);
      }
    }, false);
    this.logoutIframe = document.getElementById('logout_ifm') as HTMLIFrameElement;
    this.logoutIframe.src = (environment.envName === 'DEV') ?
      'https://driver.integ.aldautomotive.com/logout' :
      '/logout';
  }
}
