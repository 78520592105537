﻿import { MapService } from '../shared/map/map.service';
import { DriverService } from '../driver/shared/driver.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PopinService } from '../shared/ui/popin.service';
import { GmapComponent } from '../shared/gmap/gmap/gmap.component';
import { AppConfigService } from '../shared/config/app-config.service';
import { OnRoadAssistanceService } from '../on-road-assistance/on-road-assistance.service';
import { FeedService } from '../feed/shared/feed.service';
import { ConfigurationModel } from '../shared/config/configuration.model';
import { ConfigurationService } from '../shared/config/configuration.service';
import { AppStorageService } from '../shared/storage/common/app-storage.service';
import { FeatureService } from '../shared/feature/feature.service';
import { FeatureConst } from '../shared/feature/feature.consts';
import { combineLatest } from 'rxjs';
import { GTM_EVENTS } from 'app/shared/gtm/gtm-events.const';
import { UserProfile } from 'app/driver/shared/models/user-profile.model';
import { UserRole } from 'app/driver/shared/models/user-role.model';
import { UserRoles } from 'app/shared/user/userRoles.const';
import { UserRoleContract } from 'app/driver/shared/models/user-contract.model';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit, OnDestroy {
  dropDownTranslationKeys = [];
  public datasContactSelectMenu = [
    { id: 'assistance_1', label: 'help-main_panel-road_assistance' },
    { id: 'assistance_2', label: 'help-main_panel-customer_care' }
  ];

  public currentContactSelected: string;
  public userRole: UserRole;
  public driverCompanyName: string = null;
  public assistancePhonePrimary: string;
  public assistancePhoneSecondary: string;
  public assistancePhoneThird: string;

  public isDeclareDamageActivated: boolean;
  public isSalesForceActivated: boolean;
  public isSmartCareActivated: boolean;
  public isAssistanceForDriverActivated: boolean;

  @ViewChild(GmapComponent)
  public map: GmapComponent;
  public currentUserAddress: string = null;
  private currentUserMarker;
  public readonly GTM_EVENTS = GTM_EVENTS;

  constructor(
    private driverService: DriverService,
    private oraService: OnRoadAssistanceService,
    private popinService: PopinService,
    private router: Router,
    private appConfig: AppConfigService,
    private mapService: MapService,
    private feedService: FeedService,
    private configurationService: ConfigurationService,
    private featureService: FeatureService,
    private appStorageService: AppStorageService) {
    this.driverService.newDriverStream.subscribe(
      (newDriver: UserProfile) => {
        if (newDriver) {
          this.userRole = this.driverService.getNewUserRole(newDriver);
          this.driverCompanyName = this.userRole.company.name;
        }
      });
  }

  ngOnInit() {
    this.popinService.opened(true);

    setTimeout(() => {
      // end animation transition
      if (this.map) {
        this.map.mapGoogleService.resize();
      }
    }, 300);

    combineLatest(
      this.configurationService.getConfItems([this.appStorageService.countrySelected, 'AssistancePhonePrimary']),
      this.configurationService.getConfItems([this.appStorageService.countrySelected, 'AssistancePhoneSecondary'])
    ).subscribe((configurationItems: ConfigurationModel[]) => {
      if (configurationItems[0]) {
        this.assistancePhonePrimary = configurationItems[0].value;
      }
      if (configurationItems[1]) {
        this.assistancePhoneSecondary = configurationItems[1].value;
      }
    }, errors => {
      this.assistancePhonePrimary = 'error occured';
      this.assistancePhoneSecondary = 'error occured';
    });

    combineLatest(
      this.featureService.isFeatureActivated(FeatureConst.features.DeclareDamage),
      this.featureService.isFeatureActivated(FeatureConst.features.SalesForce),
      this.featureService.isFeatureActivated(FeatureConst.features.SmartCare),
      this.featureService.isFeatureActivated(FeatureConst.features.AssistanceThirdNumber),
      this.featureService.isFeatureActivated(FeatureConst.features.AssistanceForDriver),
    ).subscribe(features => {
      this.isDeclareDamageActivated = features[0];
      this.isSalesForceActivated = features[1];
      this.isSmartCareActivated = features[2];
      this.isAssistanceForDriverActivated = features[4];
      if (features[3]) {
        this.addThirdNumber();
      }
    });
  }

  private addThirdNumber(): void {
    this.configurationService.getConfItems([this.appStorageService.countrySelected, 'AssistancePhoneThird'])
      .subscribe((number) => {
        this.datasContactSelectMenu.push({ id: 'assistance_3', label: 'help-main_panel-ald_automotive' });
        this.assistancePhoneThird = number.value;
      });
  }

  showMyPosition() {
    if (this.map) {
      this.map.getCurrentMyPosition();
    }
  }

  updateSelect(obj) {
    this.currentContactSelected = obj.id;
  }

  navigateTo(path) {
    this.popinService.opened(false);
    this.router.navigate([path]);
  }

  ngOnDestroy() {
    // Back navigator
    this.popinService.opened(false);
  }

  onBackClick() {
    this.popinService.opened(false);
  }

  updateUserPosition($event) {

    if (this.currentUserMarker) {
      this.currentUserMarker.setMap(null);
    }

    // create new marker and save reference
    this.currentUserMarker = this.map.mapGoogleService.addMarkerMyPosition($event.lat, $event.lng, {
      url: this.appConfig.config.assetsPath + '/images/gmap/me.png',
      scaledSize: new google.maps.Size(20, 20)
    });

    this.getAddressByLatLng($event.lat, $event.lng);
  }

  /* get Addess by Lat/lng  */
  getAddressByLatLng(lat: number, lng: number) {

    this.mapService.getAddressByLatLng(lat, lng).forEach(
      results => {
        this.currentUserAddress = results;
      }, null
    ).then(() => {
    }).catch(
      (error: string) => {
      }
    );
  }

  initiateOnRoadAssistance() {
    this.featureService.isFeatureActivated(FeatureConst.features.OnRoadAssistance)
      .subscribe(value => {
        if (value) {
          const licencePlates = this.userRole.contracts.map((contract: UserRoleContract) => {
            return contract.vehicle.plate;
          });
          const location = this.mapService.getStoredPosition();
          this.oraService.putUserInfo(licencePlates, location.lng, location.lat, 'tel:' + this.assistancePhonePrimary);
        } else {
          window.location.href = 'tel:' + this.assistancePhonePrimary;
        }
      });
  }
}
