import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import * as bowser from 'bowser';
import { ConfigurationModel } from './configuration.model';
import { ConfigurationService } from './configuration.service';
import { AppStorageService } from '../storage/common/app-storage.service';
import { Injectable } from '@angular/core';

@Injectable()
export class RedirectSafari implements CanActivate {

  private authorizedPathsIos = ['/start/create-password', '/start/end-forgot-password', '/start/success'];

  constructor(private configurationService: ConfigurationService,
              private appStorageService: AppStorageService) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if ((bowser.mac || bowser.ios) && !bowser.chrome && !this.startsWithAnyInArray(state.url, this.authorizedPathsIos)) {
      this.redirectToStore();
    } else {
      return true;
    }
  }

  private redirectToStore() {
    this.configurationService.getConfItems([this.appStorageService.countrySelected, 'IosAppUrl'])
      .subscribe((feedback: ConfigurationModel) => {
        if (feedback) {
          window.location.replace(feedback.value);
        }
      }, e => {
        console.error('An error has occured: ', e);
      });
  }

  private startsWithAnyInArray(inputString: string, comparedArray: Array<string>): boolean {
    let res = false;

    comparedArray.forEach(value => {
      if (inputString.includes(value)) {
        res = true;
        return;
      }
    });

    return res;
  }
}
