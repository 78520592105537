import { Component, Host, Optional } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { enterAnimation } from 'app/shared/animations/enter-animations';

/**
 * Global error message component. place above forms
 */
@Component({
  selector: 'app-form-errors',
  animations: [
    enterAnimation
  ],
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.scss']
})
export class FormErrorsComponent {


  constructor(@Host() @Optional() private ngForm: NgForm, // get form from parent component
              @Host() @Optional() private formGroupDir: FormGroupDirective // get form from parent component
  ) {
  }

  public get form(): NgForm | FormGroupDirective {
    return this.ngForm || this.formGroupDir;
  }

  public get formControls(): any {

    if (this.form instanceof FormGroupDirective) {
      return this.form.control.controls;
    } else {
      return this.form.controls;
    }

  }

  hasEmptyField(): boolean {
    // start optimistic (no fields are empty).
    let empty = false;

    // loop over all controls in forms
    for (const name of Object.keys(this.formControls)) {
      const control: FormControl = this.formControls[name];

      // if an empty field has been found, exit loop and declare that, sadly, yes we have an empty field.
      if (control.hasError('required')) {
        empty = true;
        break;
      }
    }

    return empty;
  }


}
