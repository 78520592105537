import { AbstractControl } from '@angular/forms';


export class PhoneValidator {

  static simple = function (control: AbstractControl) {
    return /^((\+[0-9]+)|(\([0-9]+\))|([0-9]+))([0-9]*([\- ][0-9])?)*$/i.test(control.value) ? null : {tel: 'global-generic_terms-error_invalid_phone'};
  };

  static optionalSimple = function (control: AbstractControl) {
    if (!control.value || control.value === '') {
      return null;
    }
    return /^((\+[0-9]+)|(\([0-9]+\))|([0-9]+))([0-9]*([\- ][0-9])?)*$/i.test(control.value) ? null : {tel: 'global-generic_terms-error_invalid_phone'};
  };

}
