import { Component, Host, Input, Optional } from '@angular/core';
import { FormGroupDirective, NgControl, NgForm, NgModel } from '@angular/forms';
import { enterAnimation } from 'app/shared/animations/enter-animations';


@Component({
  selector: 'app-input-errors',
  templateUrl: './input-errors.component.html',
  styleUrls: ['./input-errors.component.scss'],
  animations: [
    enterAnimation
  ],
})
export class InputErrorsComponent {

  @Input()
  public input: NgModel | NgControl;

  @Input()
  public form: NgForm | FormGroupDirective;

  /**
   * By default, the component doesn't display required errors, these are left to the app-form-errors componenet
   * @type {boolean}
   */
  @Input()
  public displayRequired = false;

  /**
   * Error message to display when displayRequired is true
   */
  @Input()
  public requiredErrorMessage: string;

  constructor(@Host() @Optional() formGroupDir: FormGroupDirective) {

    // if we can inject a formGroupDir instead of relying on the input form then use this value.
    if (formGroupDir) {
      this.form = formGroupDir;
    }

  }

}
