import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ToastMessageComponent} from './toast-message.component';
import { ToastDirective } from './toast.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [
        ToastMessageComponent,
        ToastDirective
    ],
    exports: [
        ToastMessageComponent
    ]
})
export class ToastMessageModule {
}
