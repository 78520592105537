import { TranslateModule } from '@ngx-translate/core';
import { RatingComponent } from './rating.component';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    SvgIconModule,
    TranslateModule
  ],
  declarations: [
    RatingComponent
  ],
  exports: [
    RatingComponent
  ]
})
export class RatingModule {
}
