import { NgModule } from '@angular/core';
import { MatButtonModule, MatSelectModule } from '@angular/material';

@NgModule({
  exports: [
    MatButtonModule,
    MatSelectModule,
  ]
})
export class MaterialModule { }
