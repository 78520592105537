import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EmailValidator } from '../../shared/app-forms/validators/email.validator';
import { DateValidator } from '../../shared/app-forms/validators/date.validator';
import { DriverService } from '../../driver/shared/driver.service';
import { PopinService } from '../../shared/ui/popin.service';
import { AppConfigService } from '../../shared/config/app-config.service';
import { HelpService } from '../shared/help.service';
import { TranslationService } from '../../shared/translation/translation.service';
import { ConfigurationModel } from '../../shared/config/configuration.model';
import { ConfigurationService } from '../../shared/config/configuration.service';
import { AppStorageService } from '../../shared/storage/common/app-storage.service';
import * as moment from 'moment';
import { contractIsStatusOfType } from '../../driver/shared/contract/contract-status.helper';
import { ContractConsolidatedStatusType } from '../../driver/shared/contract/contract-status.const';
import { MaskComponent } from '../../shared/mask/mask.component';
import { DeclareDamageRequest } from '../shared/models/declare-damage-request.model';
import { UserProfile } from 'app/driver/shared/models/user-profile.model';
import { UserRole } from 'app/driver/shared/models/user-role.model';
import { UserRoleContract } from 'app/driver/shared/models/user-contract.model';

@Component({
  selector: 'app-help-damage-declaration',
  templateUrl: './help-damage-declaration.component.html',
  styleUrls: ['./help-damage-declaration.component.scss']
})
export class HelpDamageDeclarationComponent implements OnInit, OnDestroy {

  public newUserProfile: UserProfile;
  public newUserRole: UserRole;
  public form: FormGroup;
  public submitStatus = 'initial';
  public datasSelectMenu = [];
  public indexSelectMenu = -1;

  public addressSelected: string;
  public mobile: boolean;
  public selectedContractId: string;
  public selectedVehicle: string;
  public error = null;

  public countryPhoneCode: string;
  @ViewChild('selectAutocomplete')
  public selectAutocomplete;
  private isTimeSelected: boolean;

  constructor(
    private driverService: DriverService,
    private helpService: HelpService,
    private router: Router,
    private formBuilder: FormBuilder,
    private popinService: PopinService,
    private appConfig: AppConfigService,
    private element: ElementRef,
    private translationService: TranslationService,
    private configurationService: ConfigurationService,
    private appStorageService: AppStorageService) {

    this.isTimeSelected = false;
    this.setFormData();
  }

  private setFormData(): void {

    this.driverService.newDriverStream
      .subscribe((newDriver: UserProfile) => {

        this.newUserProfile = newDriver;
        this.newUserRole = this.driverService.getNewUserRole(newDriver);
        // Parse driver's vehicles and push in datasSelectMenu
        this.datasSelectMenu = this.newUserRole.contracts.filter((contract: UserRoleContract) => {
          return contractIsStatusOfType(contract, ContractConsolidatedStatusType.Active);
        }).map((contract: UserRoleContract) => {
          return {
            id: contract.reference,
            label: `${contract.vehicle.brand} ${contract.vehicle.plate}`
          };
        });

        if (this.datasSelectMenu.length === 1) {
          this.indexSelectMenu = 0;
        }
        this.generateFormGroup();
      });
  }

  ngOnInit() {
    this.popinService.opened(true);
    this.mobile = this.appConfig.isMobileTablet;
    this.configurationService.getConfItems([this.appStorageService.countrySelected, 'CountryPhoneCode'])
      .subscribe(
        (feedback: ConfigurationModel) => {
          this.countryPhoneCode = feedback.value;
        }, e => {
          this.countryPhoneCode = 'error occured';
        });
  }

  onSubmit() {
    if (this.form.valid) {
      this.submitStatus = 'pending';

      const damageDeclarationParams: DeclareDamageRequest = {
        repairCity: this.form.get('address').value,
        carDamage: this.form.get('carDamage').value,
        date: this.getDateTime(this.form.get('date').value, this.form.get('time').value),
        wasSomeoneInjured: !!this.form.get('injuredPeople').value,
        hasOtherDamage: !!this.form.get('otherDamage').value,
        otherDamageDescription: this.form.get('otherDamageDescription') ? this.form.get('otherDamageDescription').value : null,
        vehicle: this.selectedVehicle,
        wasVehicleMoving: !!this.form.get('vehicleBehavior').value,
        culture: this.translationService.language,
        contractReference: this.selectedContractId,
      };

      this.helpService.declareDamage(damageDeclarationParams)
        .subscribe(
          response => {
            this.submitStatus = 'success';
          },
          error => {
            if (error.status && error.status.toString().substring(0, 1) === '4') {
              this.error = 400;
            } else {
              this.error = true;
            }
            this.backToTop();
            this.submitStatus = 'initial';
          });

    } else {
      this.backToTop();
    }
  }

  generateFormGroup() {
    this.form = this.formBuilder.group({
      vehicle: ['', Validators.required],
      date: ['', DateValidator.isPast],
      time: ['', Validators.required],
      address: ['', Validators.required],
      vehicleBehavior: ['', Validators.required],
      injuredPeople: ['', Validators.required],
      carDamage: ['', Validators.required],
      otherDamage: ['', Validators.required],
    });

    this.formValuesChangesListener();
  }

  formValuesChangesListener() {
    this.form.get('otherDamage').valueChanges.subscribe(data => {
      if (!!data) {
        this.form.addControl('otherDamageDescription', new FormControl('', Validators.required));
      } else {
        this.form.removeControl('otherDamageDescription');
      }
    });
  }

  updateSelectVehicle(e) {
    this.form.get('vehicle').setValue(e.label);
    this.selectedContractId = e.id;
    this.selectedVehicle = e.label;
  }

  updateSelectAddress(label) {
    this.form.get('address').setValue(label);
  }

  ngOnDestroy() {
    this.popinService.opened(false);
  }

  backToTop() {
    const el = this.element.nativeElement.querySelector('.sticky-content');
    const scrollInterval = setInterval(function () {
      el.scrollTop = el.scrollTop - 15;
      if (el.scrollTop <= 0) {
        clearInterval(scrollInterval);
      }
    }, 1);

  }

  onBackClick() {
    this.popinService.opened(false);
    this.router.navigate(['feed/help']);
  }

  getDateTime(strDate: string, strTime: string) {
    const dateTime = moment(strDate + ' ' + strTime);
    return dateTime.parseZone().toJSON();
  }

  onInputChange() {
    this.submitStatus = 'initial';
  }

  // The 3 following methods are used as a hack to fix an issue with the time picker
  onflatpickrOpen(event: any) {
    event.instance._input.parentElement.parentElement.classList.add('has-value');
  }

  onflatpickrClose(event: any) {
    if (!this.isTimeSelected) {
      event.instance._input.parentElement.parentElement.classList.remove('has-value');
      event.instance._input.value = '';
    }
  }

  onflatpickrChange(event: any) {
    this.isTimeSelected = true;
  }

}
