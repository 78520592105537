import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'cultureName' })
export class CultureNamePipe implements PipeTransform {

  transform(value: string) {
    if (value) {
      return `global-generic_terms-culture_${value.toLocaleLowerCase().replace('-', '_')}`;
    }
    return value;
  }
}

